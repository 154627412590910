@mixin card-theme($theme) {
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);
  $primary: map-get($theme, primary);

  .card {
    background:   $white;
    border-color: map-get($mono, tint15);

    &__break-ratings-table-container {
      .grid__row_active {
        border: solid 0.1rem map-get($mono, tint25);
        background-color: map-get($primary, tint50);
      }
    }
  }
}

@mixin card-typography($config) {

}

@mixin card-shadow($shadows) {

  .card {
    box-shadow: map-get($shadows, modal-light);
  }
}
