@import '../../core/utils/breakpoints';

.content {
  padding: 2.357142857142857rem 2.14285714rem;
  min-height: 100%;
}

//TODO rename to 'mobile' when all tablet version will be done
@include mobile {
  .content {
    padding: 2.357142857142857rem 1.3rem 2.14285714rem 1.3rem;
  }
}
