@import '../../../../styles/blocks/icon/icon-sizes';
@import '../../../../styles/core/utils/breakpoints';

@mixin included-topic-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $custom-topic: map-get($custom, topic);

  .included-topic {
    &__grid {
      // todo заменить tr на подходящий класс to Pavel Ivannikov
      tr:nth-child(odd) {
        background: map-get($custom-topic, background);
      }

      .grid__row:hover {
        overflow: hidden;
        transition: all .1s ease-in;
        transform: translateY(-1%);
      }
      
    }


    &__element-section {
      border-top-color: map-get($mono, tint20);
    }

    &__new-element-counter {
      background: map-get($success, primary);
    }

    &__new-element-counter-label {
      color: $white;
    }

    &__button-container {
      &:hover {
        background-color: map-get($primary, tint30);
      }
    }

    &__multi-zet {
      background-color:  map-get($accent, primary);
      color: $white;
    }

    &__button {
      color: map-get($primary, primary);
    }
  }

  @include accessible {
    .included-topic {
      &__button {
        &:focus {
          background: map-get($theme, black);

          .figure {
            &:after, &:before {
              background: map-get($theme, white);
            }
          }
        }
      }
    }
  }
}

@mixin included-topic-shadow($shadows) {
  .included-topic {

    &__grid {
      .grid__row:hover {
        box-shadow: map-get($shadows, included-topic);
      }
    }
  }
}

@mixin included-topic-typography($config) {
  $font-size: map-get($config, font-size);
  .included-topic {

    &__grid-column{
      font-size: map-get($font-size, base-small);

      &_edu-dates {
        font-size: map-get($font-size, small);
      }

      &_title {
        font-size: map-get($font-size, base);
      }
    }

    &__icon {
      @include icon_18();
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .included-topic {
      &__icon {
        @include icon_14();
      }
    }
  }
}
