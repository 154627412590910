@import '../../core/utils/breakpoints';
@mixin iam-screen-theme($theme, $custom) {
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .iam-screen-wrapper {
    background-color: map-get($custom, root-bg);
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .iam-screen-wrapper {
      background: $white;
    }
  }
}


@mixin iam-screen-typography($config) {
  $font-size: map-get($config, font-size);

  .iam-screen {
    &__error {
      font-size: map-get($font-size, small);
    }
  }

  /*@include tablet {
    .iam-screen {
      &__error {
        font-size: map-get($font-size, small);
      }
    }
  }*/

  @include mobile {
    .iam-screen {
      &__error {
        font-size: map-get($font-size, base);
      }
    }
  }
}
