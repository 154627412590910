@mixin multiselect-theme($theme) {
  $mono: map-get($theme, mono);

  .select-list {
    &__item {
      &_disabled {
        background: map-get($mono, tint05);
        border-color: map-get($mono, tint05);
        color: map-get($mono, tint40);
      }

      &_bordered-bottom:not(:last-of-type) {
        border-bottom: 0.05rem solid map-get($mono, tint10);
      }
    }
  }
}
