@mixin notifications-list-theme($theme) {
  $white: map-get($theme, white);
  $mono: map_get($theme, mono);

  .notifications-list {
    &__body {
      background-color: $white;
    }

    &__element {
      border-bottom-color: map-get($mono, tint30);
    }
  }
}

@mixin notifications-list-shadow($shadows) {

  .notifications-list {
    &__body {
      box-shadow: map-get($shadows, popover-tight);
    }
  }
}

