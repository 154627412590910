@import "../../../styles/core/utils/breakpoints";

@mixin access-doc-loader-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $success:  map-get($theme, success);
  $alert: map_get($theme, alert);
  $mono: map_get($theme, mono);
  $white: map_get($theme, white);
  $black: map_get($theme, black);

  .adl {
    &._expired {
      border-top-color: $black;
    }

    &_certificate {
      border-top-color: map-get($success, primary);
    }

    &_licence {
      border-top-color: map-get($primary, primary);
    }

    &__confirmation {

      &:before {
        border-left-color: map-get($success, primary);
        border-bottom-color: map-get($success, primary);
      }
    }

    &__remove-icon {
      &:before, &:after {
        background: map-get($mono, tint50);
      }
    }

    &__not-confirmed {

      &:before, &:after {
        background: map-get($alert, primary);
      }
    }

    &__cross {
      &:before, &:after {
        background: map-get($primary, primary);
      }
    }

    &__approved-msg-container {
      color: map-get($primary, primary);

      &:before {
        border-left-color: map-get($primary, primary);
        border-bottom-color: map-get($primary, primary);
      }
    }

    &__header-doc-number {
      color: map-get($mono, tint50);
    }

    &__overlay {
      background: map-get($custom, overlay);
    }

    &__svg-icon {
      color: map-get($mono, tint08);
    }

    &-info {
      &__autocomplete {
        margin: 0.5rem 0 0;
      }
    }
  }

  @include mobile {
    .adl {
      background: $white;

      &__container {
        background-color: transparent;
      }
    }
  }
}

@mixin access-doc-loader-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .adl {
      &__header-doc-number {
        font-size: map-get($font-size, big);
      }
      &-info {
        &__label {
          font-size: map-get($font-size, small);
          b, strong {
            font-weight: map-get($font-weight, bold);
          }
        }
        &__value {
          font-size: map-get($font-size, small);
        }
        &__autocomplete {
          margin: 0.5rem 0 0;
        }
      }
    }
  }
}

@mixin access-doc-loader-shadow($shadows) {

  .adl {
    box-shadow: map-get($shadows, popover-tight);
  }
}
