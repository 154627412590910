@import "../edu-element-view/edu-element-view-base";

@mixin edu-element-view-om-theme($theme) {
  $white: map-get($theme, white);

  .edu-element-om-page {
    &__main-info {
      background: $white;
    }
  }
}

@mixin edu-element-view-om-shadow($shadows) {
  .om {
    &__view-container {
      box-shadow: map-get($shadows, activity-selection);
    }
  }
}
