@mixin toggle-button-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .toggle-button {
    &__slider {
      &_primary {
        background-color: map-get($primary, primary);
      }

      &_mono {
        background-color: map-get($mono, tint40);
      }
    }
  }
}
