@mixin checkbox-group-theme($theme) {
  $mono: map-get($theme, mono);

  .checkbox-group {
    &_border-bottom {
      .checkbox-group__item {
        border-color: map-get($mono, tint20);
      }
    }
  }

}
