@import '../../../../styles/core/utils/breakpoints';

@mixin iom-main-info-theme($theme) {
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);

  .iom-main-info {
    &__container {
      background: $white;
    }
  }
}

@mixin iom-main-info-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  @include mobile {
    .iom-main-info {
      &__subtitle {
        font-weight: map-get($font-weight, regular);
      }
    }
  }

  @include tablet-resize {
    .iom-main-info {

      &__start-btn {
        font-size: map-get($font-size, small);
      }
    }
  }
}

@mixin iom-main-info-shadow($shadows) {
  .iom-main-info {

    &__container {
      box-shadow: map-get($shadows, activity-selection);
    }
  }
}
