@mixin range-slider-theme ($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .range-slider {
    & > .ngx-slider {
      .ngx-slider-bar {
        background: map-get($mono, tint16);
      }
      .ngx-slider-selection {
        background: map-get($primary, primary);
      }
      & > .ngx-slider-pointer {
        background: map-get($primary, shade10);
      }
    }
  }
}

@mixin range-slider-typography ($config) {
  $font-weight: map-get($config, font-weight);
  .range-slider__value {
    font-weight: map-get($font-weight, bold);
  }
}
