@import "../../styles/core/utils/breakpoints";

@mixin password-recovery-result-theme($theme, $custom) {
  $white: map-get($theme, white);

  .password-recovery-result {
    &_result {
      .iam-screen {
        background-color: $white;
      }
    }
  }

  @include mobile {
    .password-recovery-result {
      &_result {
        .iam-screen {
          background: none;
        }
      }
    }
  }
}

@mixin password-recovery-result-shadow($shadows) {
  .password-recovery-result {
    &_result {
      .iam-screen {
        box-shadow: map-get($shadows, modal-light);
      }
    }
  }

  @include mobile {
    .password-recovery-result {
      &_result {
        .iam-screen {
          box-shadow: none;
        }
      }
    }
  }
}
