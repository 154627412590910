@import '../../core/utils/clearfix';
@import '../../core/utils/breakpoints';

.edu-header {
  width: 100%;

  &__backward {
    margin: 0 0 2.14285714rem;
  }

  &__content {
    @include clearfix();
    position: relative;
  }

  &__text {
    float: left;
    width: 60%;

    &_short {
      width: 25%;
    }
  }

  &__title {
    text-transform: uppercase;
    margin: 0 0 0.8571rem;
    line-height: 1.4;
  }

  &__heading {
    margin: 0;
    font-size: 1.6rem;
    line-height: normal;
  }

  &__actions {
    float: right;
    width: 40%;
    text-align: right;

    &_long {
      width: 75%;
    }

    &_bottom {
      display: none;
      padding-top: 0;
    }
  }

  &__next {
    padding-left: 3.57142857rem;
    padding-right: 3.57142857rem;
  }

  &__link {
    margin-right: 2rem;
  }

  &__button {
    padding-left: 2rem;
    margin-right: 1rem;
    display: flex;
  }

  &__name {
    text-transform: uppercase;
    margin: 0 0 0.8571rem;
  }

  &__section {
    &_flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}

@mixin edu-header-tablet-mobile {

}


//TODO rename to 'mobile' when all tablet version will be done
@include mobile {
  .edu-header {
    @include edu-header-tablet-mobile();

    &__label {
      display: none;
    }

    &__section_hide-mobile,
    &__name {
      display: none;
    }

    &__button {
      padding: 1rem 0 0 0;
    }

    &__text {
      width: 100%;

      &_short {
        width: 50%;
      }
    }

    &__heading {
      line-height: 1.2;
    }

    &__actions {
      text-align: left;
      padding-top: 1.93rem;
      width: 50%;

      &_top {
        display: none;
      }

      &_bottom {
        display: block;
        width: 100%;
        padding-top: 2.857142857142857rem;
      }
    }

    &__next {
      display: block;
      width: 100%;

      &_bottom {
        display: block;
        width: 100%;
        margin-top: 0;
      }
    }

    &__link {
      &_bottom {
        display: none;
      }
    }
  }
}

/*@include tablet {
  .edu-header {
    @include edu-header-tablet-mobile();

    &__actions {
      padding-top: 1.93rem;
      width: 50%;
    }

    &__text {
      width: 50%;
    }

    &__next {

      &_bottom {
        width: 10.857rem;
      }
    }
  }
}*/

