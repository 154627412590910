@import '../../../../styles/core/utils/breakpoints';

@mixin om-main-info-theme($theme) {
  $white: map-get($theme, white);
  .om-main-info {
    &__main-info {
      background: $white;
    }
  }
}

@mixin om-main-info-typography($config) {
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .om-main-info {
      &__subtitle {
        font-weight: map-get($font-weight, regular);
      }
    }
  }
}

@mixin om-main-info-shadow($shadows) {
  .om-main-info {

    &__main-info {
      box-shadow: map-get($shadows, activity-selection);
    }
  }
}
