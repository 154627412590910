@import "../../../styles/core/utils/breakpoints";

@mixin autocomplete-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $alert: map-get($theme, alert);

  .autocomplete-host {

    &.ng-invalid.ng-dirty {

      .text-field {
        background-color: map-get($alert, tint10);

        &::placeholder {
          color: map-get($mono, tint30);
        }
      }
    }
  }

  .autocomplete {

    &__dropdown-menu,
    &__button {
      background: $white;
      color: map-get($mono, primary);
      text-align: left;

      &:hover {
        background: map-get($primary, tint90);
        color: $white;
      }

      &_active {
        background: map-get($primary, primary);
        color: $white;
      }

      &_empty {
        color: map-get($mono, tint30);
      }
    }

    &__group-name {
      background: $white;
      color: map-get($mono, tint40);;
      &:hover {
        background: $white;
        color: map-get($mono, tint40);;
      }
    }

    &__group-separator {
      background-color: map-get($mono, tint20);;
    }

    // theme for cycles select (like button)
    &_theme_cycles {
      .text-field {
        //important is added to prevent mobile Safari from messing with this styles
        background: map-get($primary, primary)!important;
        color: $white!important;
        -webkit-text-fill-color: $white!important;
        opacity: 1!important;
        &::placeholder,
        &_placeholder {
          color: $white!important;
        }
      }
      .toggle__chevron {
        &:before, &:after {
          background: $white;
        }
      }
    }

    &_theme_statistics {
      .form-control {
        color: map-get($mono, tint60);
      }
    }
  }

  @include accessible {
    .autocomplete {
      &__dropdown-menu {
        border: 0.1rem solid map-get($theme, black);
        border-radius: 0.14814814814rem;
      }

      &__input {
        &_contrast {
          text-decoration: underline;
        }
      }
    }
  }

  @include mobile {
    .autocomplete {
      &__overlay {
        background: map-get($theme, white);
      }

      &__dropdown-item {
        border-bottom: 0.1rem solid map-get($mono, tint10);
      }

      &__filter-clear-button {
        &:focus {
          background: map-get($theme, black);

          .figure {
            color: map-get($theme, white);
          }
        }
      }
    }
  }
}

@mixin autocomplete-typography($config) {
  $font-size: map-get($config, font-size);

  .autocomplete {
    // theme for cycles select (like button)
    &_theme_cycles {
      .text-field {
        font-size: map-get($font-size, base);
      }
    }

    &_theme_statistics {
      .text-field {
        font-size: map-get($font-size, bigger);
      }

      .form-control {
        font-size: map-get($font-size, small);
      }
    }

    &_theme_edu-program-info {
      .text-field {
        font-size: map-get($font-size, small);
      }
    }

    //with small text in mobile
    &_theme_text-sm-mobile {
      @include mobile {
        .form-control__label {
          font-size: map-get($font-size, small);;
        }
      }
    }
  }
}

@mixin autocomplete-shadow($shadows) {
  .autocomplete {

    &__dropdown-menu {
      box-shadow: map-get($shadows, dropdown);
    }
  }
}
