@import '../../../styles/core/utils/breakpoints';

@mixin activity-selection-theme($theme) {
  $white: map-get($theme, white);

  .activity-selection {

    &__form {
      background-color: $white;
    }
  }
}

@mixin activity-selection-shadow($shadows) {

  .activity-selection {
    &__form {
      box-shadow: map-get($shadows, activity-selection);
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .activity-selection {

      &__form {
        box-shadow: map-get($shadows, modal-light);
      }
    }
  }
}



