@import '../../core/utils/breakpoints';
@import './button-base';

.button {
  background-image: none;
  border: $b-border-width solid transparent;
  border-radius: $border-radius;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
  outline: none;
  line-height: 1;
  margin: 0;
  padding: $v-padding $h-padding;
  transition: background-color .1s ease;
  position: relative;

  &:disabled {
    cursor: default;
    pointer-events: none;
  }

  &_white-space-normal {
    white-space: normal;
  }

  &_text {
    border: none;
    padding: 0;
    white-space: normal;

    &:hover {
      text-decoration: underline;
    }

    &_no-underline {
      &:hover {
        text-decoration: none;
      }
    }

    &:disabled {
      &:hover {
        text-decoration: none;
      }
    }
  }

  &_text-inline {
    vertical-align: baseline;
  }

  &_size-sm {
    padding: 0.571428571428571rem 0.714285714285714rem;
  }

  &_loading {
    color: transparent !important;
    // padding: 0.9286rem;
    text-align: center;
    background: transparent !important;

    &:before {
      content: '';
      display: inline-block;
      width: 1.2857rem;
      height: 1.2857rem;
      border: 0.1429rem solid #fff !important;
      border-bottom: 0.1429rem solid #2945b4 !important;
      border-left: 0.1429rem solid #2945b4 !important;
      border-radius: 50%;
      background: transparent !important;
      animation-name: rotateAnimation;
      animation-duration: 1s;
      animation-delay: .2s;
      animation-iteration-count: infinite;
      position: absolute;
      left: 50%;
      margin: -0.2143rem 0 0 -0.6429rem;
      transition: all .3s ease;
    }

    &__text {
      opacity: 0;
    }
  }

  &_larger-radius {
    border-radius: 0.2rem;
  }

  &_hide {
    &:before {
      opacity: 0;
      transform: rotate(0deg);
      transform: scale(0);
    }
  }

  &_raised {

    &:disabled {
      box-shadow: none;
    }
  }

  &_success {
    &:after {
      content: '';
      display: block;
      height: 0.5714rem;
      width: 1rem;
      transform: rotate(-50deg);
      border-left-color: #2945b4;
      border-left-style: solid;
      border-left-width: 0.1429rem;
      border-bottom-style: solid;
      border-bottom-width: 0.1429rem;
      border-bottom-color: #2945b4;
      position: absolute;
      left: 50%;
      top: 50%;
      margin: -0.3571rem 0 0 -0.5rem;
      transition: all .3s ease;

      transform: scaleX(0);
    }
  }

  &_show-result {
    transform:scaleX(1) !important;
  }

  &__figure-left {
    margin: -0.214285714285714rem 0.214285714285714rem -0.214285714285714rem 0;
  }

  &__figure-right {
    margin: -0.214285714285714rem 0 -0.214285714285714rem 0.214285714285714rem;
  }

  &_arrow {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:after {
      content: "";
      position: absolute;
      border-bottom: ($v-padding + 0.5rem) solid transparent;
      border-top: ($v-padding + 0.5rem) solid transparent;
      border-left-style: solid;
      border-left-width: 0.6429rem;
      margin-right: -0.6429rem;
      margin-top: -$v-padding;
      right: 0;
      transition: all .3s ease;
    }
  }
  &_right {
    float: right;
  }
  &_rounded {
    border-radius: $border-radius-lg;
  }
  &_narrow {
    //specifics font view in button
    padding-top: $v-padding-sm - 0.07rem;
    padding-bottom: $v-padding-sm + 0.07rem;
  }
  &_block {
    display: block;
    width: 100%;
  }

  &_next {
    &:after {
      content: ">";
      margin-left: 0.714285714285714rem;
    }
  }

  &_margin {
    margin: 0 0 0.7rem 0;
  }

  &_big {
    width: 70%;
  }

  &_small {
    width: 20%;
  }

  &_high {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  &_wide {
    padding-left: 4rem;
    padding-right: 4rem;
  }

  &_flat {
    background: none;
  }

  &_base-size {
    min-width: 10rem;
    display: flex;
    justify-content: center;
  }
}

a.button {
  text-decoration: none;
}

//TODO rename to 'mobile' when all tablet version will be done
@include mobile {
  .button {
    &_text:hover {
      text-decoration: none;
    }

    &_size-sm {
      padding: 0.928571428571429rem 1rem;
    }

    &_mobile-large {
      padding: 1.5 * $v-padding 1.5 * $h-padding 1.5 * $v-padding;
    }

    &_mobile-wide {
      width: 100%;
    }

    &_mobile-normal-height {
      padding-top: $v-padding;
      padding-bottom: $v-padding;
    }

    &_display-desktop {
      display: none;
    }
  }
}

@keyframes rotateAnimation {
  0%   {transform: rotate(0deg);}
  100% {transform: rotate(360deg);}
}

@-webkit-keyframes wk-rotateAnimation {
  0%   {-webkit-transform: rotate(0deg);}
  100% {-webkit-transform: rotate(360deg);}
}
