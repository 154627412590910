@import '../../core/utils/breakpoints';
@import '../../core/utils/browsers';
@import '../../core/utils/truncate';
@import '../../core/utils/z-indices';

.datepicker {
  position: relative;

  &__button-icon {
    top: -0.2rem;
  }

  &__dropdown-toggle {
    position: relative;
  }

  &__input-box {
    position: relative;
    display: block;
  }

  &__label-text {
    display: block;
  }

  &__input {
    padding-right: 2.285714285714286rem;
    @include truncate();

    &_with-close {
      padding-right: 3.714285714285714rem;
    }
  }

  &__reset-button {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 2.3rem;
    padding: 0.5429rem 0.2rem;
    background: none;
    z-index: $layer2-z-index;
}

  &__toggle {
    padding: 0 0.6429rem;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  ngb-datepicker.dropdown-menu,
  &__dropdown-menu {
    border-radius: 0.1429rem;
    background: #fff;
  }

  ngb-datepicker,
  &__picker {
    display: table-cell;
    border: 0;
    padding: 1.9286rem 1.6429rem;

    @include mobile {
      display: block;
    }
    @media(max-width: 360px) {
      padding: 1.071428571428571rem;
    }

    .ngb-dp-day {
      &:hover,
      .bg-primary {
        border-radius: 0.5714rem;
      }
    }

    .ngb-dp-header {
      margin-bottom: 1.7857rem;
    }

    .ngb-dp-month>ngb-datepicker-month-view>.ngb-dp-week:last-child {
      border-radius: 0.1429rem;
    }

    .ngb-dp-month {
      .ngb-dp-weekdays {
        border-bottom: 0;
      }
    }

    .ngb-dp-week {
      .ngb-dp-weekday {
        width: 2.285rem;
        height: 1.930rem;
        margin: 0 0.3571rem 0 0.3571rem;
        font-style: normal;
        @media(max-width: 360px) {
          width: 1.8rem;
        }
      }
    }

    .ngb-dp-header,
    .ngb-dp-week {
      border-radius: 0.1429rem;
      border: none;
    }

    .ngb-dp-header {
      .ngb-dp-arrow {
        display: none;
      }
    }

    .ngb-dp-navigation-select {
      .custom-select {
        border-radius: 0.285rem;
        font-size: 1.2rem;
        font-weight: 300;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        opacity: 0.9;
      }

      .custom-select:first-child {
        width: 13.5rem;
        height: 2.5rem;
        margin: 0 1.4286rem 0 0;
        @media(max-width: 360px) {
          width: 9rem;
        }
      }

      .custom-select:last-child {
        width: 6.5rem;
        height: 2.5rem;
        @media(max-width: 360px) {
          width: 5rem;
        }
      }
    }


    .ngb-dp-week {
      .ngb-dp-day,
      .btn-light {
        width: 2.285rem;
        height: 1.930rem;
        outline: 0;
        border-radius: 0.5714rem;
        @media(max-width: 360px) {
          width: 1.8rem;
        }
      }

      .ngb-dp-day {
        border-radius: 0.57rem;
        font-size: 1.2rem;
        margin: 0.3571rem;
      }
    }

  }

  &__input_invalid:focus,
  &__input_invalid {
    border-width: 1px;
    border-style: solid;
  }

  //with transparent input
  &_theme_field-transparent {
    .text-field {
      &, &:focus, &__active, &.ng-invalid.ng-dirty {
        background: none;
      }
    }
  }
  //screen adl - align form control label by control (without default shift)
  &_theme_adl {
    .form-control__label {
      margin-left: 0;
    }
  }
}

@include safari {
  .datepicker {

    &__input {

      &::-webkit-input-placeholder {
        position: relative;
        top: 0.28571428571rem;
      }
    }

    &__button-icon {
      top: -0.1rem;
    }
  }
}

@include mobile {
  .datepicker {
    //screen search - datepicker in modal
    &_theme_search-modal {

      .text-field {
        height: 2.857142857142857rem;
      }

      .datepicker {

        &__dropdown-menu {
          margin-left: 1.428571428571429rem;
        }
      }
    }
  }
}
