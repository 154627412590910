@mixin tac-confirmation-theme($theme) {
  $white: map-get($theme, white);

  .tac-confirmation {
    background: $white;
  }
}

@mixin tac-confirmation-shadow($shadows) {
  $container: map-get($shadows, container);

  .tac-confirmation {
    box-shadow: $container;
  }
}
