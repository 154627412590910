@import "../../../styles/core/utils/breakpoints";

@mixin psychological-test-screen-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .psychological-tests-list {
      &__title {
        font-size: map-get($font-size, h2-mobile);
        font-weight: map-get($font-weight, bold);
      }
    }
  }

}
