@import '../../../../styles/core/utils/breakpoints';

@mixin iom-extra-info-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .iom-extra-info {
      &__subtitle {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, small);
      }
    }
  }
}
