@mixin cycle-management-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $border-color: map-get($mono, tint10);

  .sidebar-menu {
    &__item {
      padding-left: 2rem;
      &:not(:last-child) {
        border-bottom-color: $border-color;
      }
      &_add {
        padding-left: 0;
        border-top-color: $border-color;
      }
    }
    .sidebar-menu__link-text {
      &_active {
        border-left-color: map-get($primary, primary);
      }
    }
  }
}
