@mixin edu-trajectory-topic-theme($theme, $custom) {
  $custom-topic: map-get($custom, topic);
  $mono: map-get($theme, mono);

  .edu-trajectory-topic {
    tr:nth-child(odd) {
      background: map-get($custom-topic, background);
    }

    &__item-title {
      color: map-get($mono, tint30);
    }
  }
}
