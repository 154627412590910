@import "../../styles/core/utils/breakpoints";

@mixin password-recovery-theme($theme, $custom) {
  $white: map-get($theme, white);
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .password-recovery {
    &__form {
      background-color: $white;
    }
    &_result {
      .iam-screen {
        background-color: $white;
      }
    }
  }

  .tab-group {
    &__tab{
      color: map-get($mono, tint30);
      background: $white;
      &_active{
        color: map-get($primary, primary);
        border-bottom-color: map-get($primary, primary);
      }
    }
  }

  /*@include tablet {
    .password-recovery {
      .iam-screen {
        background: $white;
      }
      &__form {
        background: none;
      }
    }
  }*/

  @include mobile {
    .password-recovery {
      &__form {
        background: none;
      }
      &_result {
        .iam-screen {
          background: none;
        }
      }
    }
  }
}

@mixin password-recovery-shadow($shadows) {
  .password-recovery {
    &__form {
      box-shadow: map-get($shadows, modal-light);
    }
    &_result {
      .iam-screen {
        box-shadow: map-get($shadows, modal-light);
      }
    }
  }

 /* @include tablet {
    .password-recovery {

      .iam-screen {
        box-shadow: map-get($shadows, modal-light);
      }

      &__form {
        box-shadow: none;
      }
    }
  }*/

  @include mobile {
    .password-recovery {

      &__form {
        box-shadow: none;
      }

      &_result {
        .iam-screen {
          box-shadow: none;
        }
      }
    }
  }
}
