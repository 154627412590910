@import '../../core/utils/breakpoints';

@mixin edu-section-theme($theme) {
  $white: map-get($theme, white);

  @include mobile {
    .edu-section {
      &__card {
        &_wide {
          background: $white;
        }
      }
    }
  }
}

@mixin edu-section-typography($config) {
  $font-size: map-get($config, font-size);

  // TODO tablet version
  /*
  @include tablet {
    .edu-section {
      &__heading {
        font-size: map-get($font-size, h1-mobile);
      }
    }
  }
  */

  /*@include tablet {
    .edu-header {
      &__heading {
        font-size: map-get($font-size, h3);
      }
    }
  }*/

  @include mobile {
    .edu-section {
      &__heading {
        font-size: map-get($font-size, big);
      }
    }
  }
}

@mixin edu-section-shadow($shadows) {
  .edu-section {
    &__card {
      &_wide {
        box-shadow: map-get($shadows, edu-section-card-wide);
        /*@include tablet {
          box-shadow: map-get($shadows, edu-section-card-wide-tablet);
        }*/
      }
    }
  }

  @include mobile {
    .edu-section {

      &__card {
        &_wide {
          box-shadow: map-get($shadows, edu-section-card-wide-mobile);
        }
      }
    }
  }

}
