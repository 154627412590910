@use '@angular/material' as mat;
@include mat.core();
@import '@angular/material/theming';
@import "rnimu-material-palette";
@import "../core/utils/breakpoints";
@import "../theme/typography";

$rnimu-quiz-primary: mat.define-palette($light-colors-blue, 500, 200, 800);
$rnimu-quiz-accent: mat.define-palette($light-colors-orange, 500, 300, 800);
$rnimu-quiz-warn: mat.define-palette($light-colors-pink, 500, 300, 800);
$rnimu-quiz-success: mat.define-palette($light-colors-green, 500);

$rnimu-typography: mat.define-typography-config(
    $theme-font-family,
    $theme-font-size,
    $theme-font-weight,
    $theme-font-style
);

$rnimu-nmifo-theme: mat.define-light-theme((
        color: (
                primary: $rnimu-quiz-primary,
                accent: $rnimu-quiz-accent,
                warn: $rnimu-quiz-warn),
        typography: $rnimu-typography,
        density: 0),
);

@include mat.all-component-themes($rnimu-nmifo-theme);

app-multi-select-dialog {
  display: flex;
  width: 100%;
  height: 100%;
}

.mat-mdc-chip {
  height: fit-content !important;
}

.mdc-list-item {
  height: fit-content !important;
}

.mat-form-field-wrapper {
  padding: 0;
}

.mdc-evolution-chip-set__chips {
  max-width: 100% !important;
}

.mat-mdc-standard-chip .mdc-evolution-chip__cell--primary,
.mat-mdc-standard-chip .mdc-evolution-chip__action--primary,
.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  overflow: hidden !important;
}