@import '../../styles/core/utils/breakpoints';
@import 'header-base';

@mixin header-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $black: map-get($theme, black);

  .header {
    background-color: map-get($custom, customaziable-bg);

    &__nav {
      &_top {
        background-color: map-get($custom, customaziable-bg);
        border-bottom-color: map-get($mono, tint20);
      }
    }

    &__item {
      color: map-get($mono, tint80);

      &_active {
        color: $black;
      }
      &_permanently-active {
        color: map-get($primary, primary);
      }
    }

    &__link {
      color: inherit;
      &_with-nested {
        &:after {
          border-color: map-get($mono, tint20);
        }

        &.header__link_drop-opened {
          &:after {
            border-color: map-get($primary, primary);
          }
        }
      }
      &_active,
      &_permanently-active,
      &_dependent-route {
        color: map-get($primary, primary);

        &:before {
          color: map-get($primary, primary);
        }
      }
    }

    &__sandwich-tablet-mobile {
      color: map-get($primary, primary);
    }

    &__back-mobile {
      color: map-get($primary, primary);
      outline: none;
    }

    &__section-tablet-mobile_line {
      &:before {
        background: map-get($mono, tint10);
      }
    }

    &__tablet-mobile-submenu-sidebar {
      border-top-color: map-get($mono, tint10);
      border-bottom-color: map-get($mono, tint10);
    }

  }

  /*@include tablet {
    .header {

      &__section-tablet-mobile {
        &_profile {
          background-color: map-get($mono, tint02);
        }
      }

    }
  }*/

  @include mobile {
    .header {
      &__section-tablet-mobile {
        &_profile {
          background-color: map-get($mono, tint02);
        }
      }
      &__mobile-submenu-back {
        color: map-get($primary, primary);
      }

      &__tablet-mobile-submenu {
        background-color: map-get($custom, customaziable-bg);
      }
    }
  }

  @include accessible {
    .header {

      &__section-tablet-mobile {
        &_profile {
          background-color: map-get($theme, white);
        }
      }

      &__back-mobile {
        &:focus {
          outline: 0.1rem solid currentColor;
        }
      }

      &__sandwich-mobile {
        margin-left: auto;

        &:focus {
          background-color: map-get($theme, black);

          .figure {
            color: map-get($theme, white);
          }
        }
      }

      &__link {
        &_with-nested {
          &:after {
            border-color: map-get($theme, black);
          }
        }
      }
    }
  }
}

@mixin header-typography ($config) {
  $font-family: map-get($config, font-family);
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .header__link, .submenu__toggle {
    font-weight: map-get($font-weight, medium);
  }

  /*@include tablet {

    .header__nav_drop {

      .header__link, .submenu__toggle {
        font-weight: map-get($font-weight, bold);
        text-transform: uppercase;
        letter-spacing: 0.05rem;

        &_small-tablet {
          text-transform: none;
          font-size: map-get($font-size, small);
          letter-spacing: 0;
        }
      }
    }
  }*/

  @include mobile {
    .header__link, .submenu__toggle {
      font-size: map-get($font-size, small);
      font-weight: map-get($font-weight, bold);
      text-transform: uppercase;
      letter-spacing: 0.05rem;
    }
    .header {
      &__mobile-submenu-back {
        font-size: map-get($font-size, small);
      }
    }
  }

}


@mixin header-shadow($shadows) {
  .header {
    &__nav {
      &_top {
        box-shadow: map-get($shadows, header-shadow-top);
      }
    }
  }

  /*@include tablet {
    .header {
      &__tablet-mobile-mainmenu {
        box-shadow: map-get($shadows, header-shadow);
      }
    }
  }*/

  @include accessible {
    .header {
      &__nav {
        &_top {
          box-shadow: none;
        }
      }
      &__menus {
        box-shadow: none;
      }
    }
  }
}

