@mixin confirm-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .confirm-popup {
    &__title {

      &_minimal {
        font-size: map-get($font-size, big);
        font-weight: map-get($font-weight, medium);
      }
    }
  }
}
