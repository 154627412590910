@import "src/styles/core/utils/breakpoints";

@mixin profile-title-item-theme($theme){
  $mono: map-get($theme, mono);

  .profile-title-item {
    border-color: map-get($mono, tint20);
  }

  @include accessible {
    .profile-title-item {
      border-color: inherit;
    }
  }
}

@mixin profile-title-item-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .profile-title-item {

    &__subtitle {
      font-size: map-get($font-size, base);
      font-weight: map-get($font-weight, bold);
    }

    &__position {
      font-weight: map-get($font-weight, bold);
    }
  }

  @include mobile {
    .profile-title-item {

      &__value {
        font-weight: map-get($font-weight, light);
      }
    }
  }
}
