@import "../../../styles/core/utils/breakpoints";

@mixin om-addresses($theme) {
  $white: map-get($theme, white);

  .om-addresses {
    background: $white;
  }
}

@mixin om-addresses-shadow($shadows) {
  .om-addresses {
    box-shadow: map-get($shadows, activity-selection);
  }
}

@mixin om-addresses-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  @include mobile {
    .om-addresses {
      &__td:first-of-type, &__notes-title {
        font-weight: map-get($font-weight, bold);
      }

      &__td, &__notes-text, &__notes-title {
        font-size: map-get($font-size, small);
      }
    }
  }
}
