@import '../../core/utils/breakpoints';
@import '../../core/utils/z-indices';

.tooltip {
  position: absolute;
  left: 100%;
  border-radius: 0.285714285714286rem;
  padding: 0.714285714285714rem 1.428571428571429rem;
  max-width: 16rem;
  margin-bottom: -1.428571428571429rem;
  margin-left: -1.428571428571429rem;
  cursor: pointer;
  z-index: $dropdown-z-index;
  transition: 0.3s opacity, 0.3s visibility;

  &:after {
    content: '';
    position: absolute;
    left: 1.428571428571429rem;
    top: 100%;
    border-style: solid;
    border-width: 0.571428571428571rem 0.642857142857143rem 0 0.214285714285714rem;
    border-color: transparent;
  }

  &-text {
    max-width: 16rem;
    border-radius: 0.285714285714286rem;
    padding: 0.714285714285714rem 1.428571428571429rem;

    &_big {
      @extend .tooltip_big;
    }
  }

  &_top {
    bottom: 100%;
  }

  &_big {
    max-width: calc(100vw - 2rem);
    width: 24rem;
    padding: 1.2rem 1.428571428571429rem;
  }

  &_fix-width {
    width: 16rem;
  }

  &_flat {
    &:after {
      display: none;
    }
  }

  @include mobile {
    &_mobile-margin-left {
      margin-left: 2rem;
      max-width: calc(100vw - 6rem);
    }
  }
}

@include accessible {
  .tooltip {
    border: 0.1rem solid;
    border-radius: 0.14814814814rem;
  }
}
