@import '../../styles/blocks/loader/loader_mixin';
@import '../../styles/core/utils/breakpoints';

@mixin search-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  $success: map-get($theme, success);
  $accent: map-get($theme, accent);
  $mono: map-get($theme, mono);

  .search {
    &__common-filters {
      background: $white;
    }

    &__common-programs-filter {
      .button:disabled {
        @extend .button_warn-shade;
      }
    }

    &__loader {
      @include loader-color(map-get($primary, shade20));
    }

    &__results {
      background: $white;
    }

    &__content {
      background: map-get($custom, search);
    }

    &__remove-tag-selection {
      .figure {
        background: map-get($primary, primary);
        &::before,
        &::after {
          background: $white;
        }
      }
    }

    &__tags {
      background: map-get($custom, search);
    }

    &__partition {
      &_underline {
        border-bottom-color: map-get($primary, primary);
        color: map-get($primary, primary);
      }
    }

    //@include tablet {
    //  &__results {
    //    background: transparent;
    //  }
    //  &__content {
    //    background: $white;
    //  }
    //  &__tags {
    //    background: $white;
    //  }
    //}

    @include mobile {
      &__results {
        background: map-get($custom, search);
      }
      &__content {
        background: $white;
      }
      &__tags {
        background: $white;
      }
      &__notification-wrapper {
        background: map-get($custom, search);
      }
    }

  }

  @include mobile {
    .search {

      &__results {
        background: transparent;
      }

      &__content {
        background: $white;
      }
    }
  }

  // TODO tablet version
  /*
  @include tablet {
    .search {

      &__results {
        background: transparent;
      }

      &__content {
        background: $white;
      }
    }
  }
  */

  .search-sidebar {
    &__toggle-filter-icon {
      color: map-get($primary, shade40);
    }
    &__toggle-filter-count {
      color: $white;
      background: map-get($success, primary);
    }

    &__item {
      &_border {
        border-top-color: map-get($mono, tint20);
      }
    }
    &__rating-text {
      color: map-get($mono, tint80);
    }
  }

  .search-item {
    &__division {
      color: map-get($mono, tint40);
    }
    &__organization {
      color: map-get($mono, tint40);
    }
    &__region {
      color: map-get($primary, primary);
    }
    &__type {
      color: map-get($mono, tint40);
    }
    &__iom-type {
      color: map-get($mono, tint40);
    }
    &__iom-kind {
      color: map-get($mono, tint40);
    }
    &__form {
      color: map-get($mono, tint40);
    }
    &__zet {
      color: map-get($mono, tint60);
    }
    @include mobile {
      &__name {
        color: map-get($mono, tint80);
      }

      &__organization {
        color: map-get($mono, tint50);
      }

      &__speciality {
        color: map-get($mono, tint60);
      }

      &__date-range {
        color: map-get($mono, tint50);
      }

      &__date-range-title {
        color: map-get($mono, tint80);
      }
    }
  }
}



@mixin search-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .search {
    &__heading {
      font-weight: map-get($font-weight, regular);
    }

    &-sidebar {
      &__rating-text {
        font-size: map-get($font-size, tiny);
      }
    }

    &-item {
      &__name {
        font-size: map-get($font-size, big);
        font-weight: map-get($font-weight, bold);
        line-height: 1.4;
      }
      &__division {
        font-size: map-get($font-size, tiny);
      }

      &__region {
        font-size: map-get($font-size, small);
      }
      &__type {
        font-size: map-get($font-size, small);
      }
      &__iom-type {
        font-size: map-get($font-size, tiny);
        text-transform: uppercase;
      }
      &__iom-kind {
        font-size: map-get($font-size, small);
      }
      &__form {
        font-size: map-get($font-size, small);
      }
      &__price {
        font-size: map-get($font-size, big);
        font-weight: map-get($font-weight, bold);
        span {
          font-weight: map-get($font-weight, regular);
        }
      }
      &__dates {
        font-size: map-get($font-size, big);
        font-weight: map-get($font-weight, bold);
      }
      @include mobile {
        &__name {
          font-size: map-get($font-size, base);
        }

        &__organization {
          font-size: map-get($font-size, small);
          line-height: 1.3;
        }

        &__date-range {
          font-size: map-get($font-size, base);
        }

        &__date-range-title {
          font-weight: map-get($font-weight, bold);
        }

        &__price {
          font-weight: map-get($font-weight, medium);
        }
      }
    }
  }
}

@mixin search-shadow($config) {
  .search {
    &__results {
      box-shadow: map-get($config, portfolio-shadow);
    }
  }

  .search-sidebar  {
    box-shadow: map-get($config, portfolio-shadow);
  }

  @include mobile {
    .search {
      &__results {
        box-shadow: none;
      }
    }
    .search-sidebar {
      box-shadow: none;
    }
  }

  // TODO tablet version

  /*@include tablet {
    .search {
      &__results {
        box-shadow: none;
      }
    }
  }*/



}
