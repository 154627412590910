@mixin questionary-progress-bar-theme($theme){
  $mono: map-get($theme, mono);
  $primary: map-get($theme, primary);

  .questionary-progress-bar-container {
    background-color: map-get($mono, tint16);
  
    &__finished {
      background-color: map-get($primary, primary);
    }
  }
}