@import "../../../styles/core/utils/breakpoints";
@import "../../../styles/blocks/loader/loader_mixin";

@mixin questionary-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);

  .questionary {
    &__loader {
      @include loader-color(map-get($primary, shade20));
    }

    &__error {
      color: map-get($alert, primary);
    }
  }

  /*@include tablet {
    .questionary {
      background-color: map-get($theme, white);
    }
  }*/
}
