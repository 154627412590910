@import '../../../../styles/core/utils/breakpoints';

@mixin om-extra-info-theme($theme) {
  $mono: map-get($theme, mono);

  .om-extra-info {
    &__item {
      border-top-color: map-get($mono, tint20);
    }
  }

  @include mobile {
    .om-extra-info {
      &__item {
        border-top-color: map-get($mono, tint20);
      }
    }
  }
}

@mixin om-extra-info-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  @include mobile {
    .om-extra-info {
      &__subtitle {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, small);
      }
    }
  }
}
