@import "../../../styles/core/utils/breakpoints";

@mixin zet-count-typography($config, $custom) {
  $font-size: map-get($config, font-size);
  $custom-size: map-get($custom, zet-count);
  $font-weight: map-get($config, font-weight);

  .zet-count {
    &__number {
      font-size: map-get($custom-size, desktop);
      font-weight: map-get($font-weight, light);
    }

    &__unit {
      font-size: map-get($font-size, base);
    }
  }

  /*@include tablet {
    .zet-count {
      &__number {
        font-size: map-get($custom-size, tablet);
        font-weight: map-get($font-weight, regular);
      }
    }
  }*/

  @include mobile {
    .zet-count {

      &__number {
        font-size: map-get($custom-size, mobile);
      }
    }
  }
}

@mixin zet-count-theme($theme) {
  $accent: map-get($theme, accent);

  .zet-count {
    &__multi-zet {
      background-color: map-get($accent, primary);
    }
  }
}
