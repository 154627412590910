@import '../../core/utils/breakpoints';

@mixin sidebar-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $white: map-get($theme, white);
  $text-color: map-get($mono, primary);

  .sidebar {
    background: map-get($custom, customaziable-bg);
    color: $text-color;

    &__item_bordered {
      border-top-color: map-get($mono, tint10);
    }

    &__item {
      &_separator-top {
        border-top: 0.071428571428571rem solid map-get($mono, tint10);
      }
    }

    &__custom-link {
      color: $text-color;

      &_active {
        color: map-get($primary, primary);
        border-left-color: map-get($primary, primary);
      }
    }

    &__toggle-text {
      color: map-get($primary, primary);
    }
  }

  // TODO tablet version
  //@include tablet{
  //  .sidebar {
  //    &_over-tb {
  //      background: $white;
  //    }
  //  }
  //}

  @include mobile {
    .sidebar {
      &_popup-mobile {
        background: map-get($custom, root-bg);
      }

    }
  }
}

@mixin sidebar-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  /*@include tablet{
    .sidebar {
      &__title {
        font-size: map-get($font-size, base);
      }
      &__custom-link {
        font-size: map-get($font-size, base);
      }
    }
  }*/

  @include mobile {
    .sidebar {
      &__toggle-text {
        font-size: map-get($font-size, small);
      }
    }
  }
}

@mixin sidebar-shadow($shadows) {
  @include mobile {
    .sidebar {
      &_popup-mobile {
        .sidebar__content {
          box-shadow: none;
        }
      }
    }
  }
}
