@import "../../../styles/core/utils/breakpoints";

.collapse-toggle {
  display: inline-block;
  position: relative;
  min-height: 1.214285714285714rem;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  padding: 0 0 0 2.142857142857143rem;

  &__icon {
    position: absolute;
    width: 1.214285714285714rem;
    height: 1.214285714285714rem;
    left: 0;
    top: 0.9rem;
    margin-top: -0.642857142857143rem;
    border: 0.071428571428571rem solid currentColor;
    border-radius: 0.14285714rem;

    .figure {
      position: absolute;
      top: -0.071428571428571rem;
      left: -0.071428571428571rem;

      &:before, &:after {
        background: currentColor;
      }

      &_plus {
        display: block;
      }

      &_minus {
        display: none;
      }
    }

    &_opened {

      .figure {

        &_minus {
          display: block;
        }

        &_plus {
          display: none;
        }
      }
    }
  }
}

//TODO rename to 'mobile' when all tablet version will be done
@include mobile {
  .collapse-toggle {
    padding-left: 2.5rem;

    &__icon {
      top: 0.6rem;
    }
  }
}

@include accessible {
  .collapse-toggle {
    &:focus {
      outline: 0.1rem solid;
      outline-offset: 0.15rem;
    }
  }
}
