@import "../edu-element-view/edu-element-view-base";

@mixin dpp-pk-my-cycles-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);

  $edu-element-status: map-get($custom, edu-element);
  $no-access-status: map-get($edu-element-status, no-access-status);

  .dpp-pk-my-cycles {
    &__grid {
      border-bottom: 0.055rem solid map-get($mono, tint15);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__status {
      color: map_get($mono, tint50);

      &_primary {
        color: map-get($primary, primary);
      }

      &_success {
        color: map-get($success, primary);
      }

      &_accent {
        color: map-get($accent, primary);
      }

      &_no-access,
      &_canceled {
        color: $no-access-status;
      }
    }

    &__status-badge {
      color: inherit;
    }

    &__status-text {
      color: map-get($theme, black);
    }
  }

  @include mobile {
    .dpp-pk-my-cycles {
      &__status-text {
        color: inherit;
      }
    }
  }
}

@mixin dpp-pk-my-cycles-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .dpp-pk-my-cycles {
    &__grid-value {
      font-size: map-get($font-size, big);
      &_dates {
        font-weight: map-get($font-weight, bold);
      }
    }

    &__item-popover-content {
      font-size: map-get($font-size, small);
    }
  }

  /*@include tablet {
    .dpp-pk-my-cycles {
      &__grid-value {
        font-size: map-get($font-size, base);
      }
    }
  }*/

  @include mobile {
    .dpp-pk-my-cycles {
      &__grid-value {
        font-size: map-get($font-size, base);
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
