@import "../../../styles/core/utils/breakpoints";

@mixin edu-element-view-title-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);

  $edu-element-status: map-get($custom, edu-element);
  $no-access-status: map-get($edu-element-status, no-access-status);

  .edu-element-view-title {
    &__competence {
      color: map-get($accent, primary);
    }

    &__sub-title {
      color: map_get($mono, tint30);
    }

    &__feedback-title {
      color: map-get($primary, primary);
    }

    &_leave-feedback {
      color: map_get($primary, primary);
    }

    &__status {
      color: map_get($mono, tint50);

      &_primary {
        color: map-get($primary, primary);
      }

      &_success {
        color: map-get($success, primary);
      }

      &_accent {
        color: map-get($accent, primary);
      }

      &_no-access,
      &_canceled {
        color: $no-access-status;
      }
    }

    &__notification {
      color: map-get($mono, tint60);
      background-color: map-get($theme, white);
      border: 0.25rem solid map-get($mono, tint40);
    }

    &__status-badge {
      color: inherit;
    }

    &__status-text {
      color: map-get($theme, black);
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-element-view-title {
      &__status-text {
        color: inherit;
      }
    }
  }
}

@mixin edu-element-view-title-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .edu-element-view-title {

    &__sub-title {
      font-weight: map_get($font-weight, regular);
      font-size: map_get($font-size, base);
    }

    &__notification {
      border-radius: 0.3rem;
      font-size: map_get($font-size, big);
    }

  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-element-view-title {

      &__sub-title {
        font-size: map_get($font-size, small);
      }

      &__main-title {
        font-size: map_get($font-size, h1-mobile-smaller);
      }

    }
  }
}
