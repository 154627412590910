@import '../../../styles/core/utils/breakpoints';

@mixin markers-zet-theme($theme) {
  $success: map-get($theme, success);
  $white: map-get($theme, white);
  .markers-zet {
    &__marker {
      background: map-get($success, primary);
      color: $white;
    }
    &_theme_search-mobile {
      @include mobile {
        .markers-zet__text, .markers-zet__label {
          color: inherit;
        }
      }
    }
  }
}


@mixin markers-zet-typography($config) {
  $font-weight: map-get($config, font-weight);

  .markers-zet {
    &_theme_search-mobile {
      @include mobile {
        .markers-zet__text, .markers-zet__label {
          font-weight: map-get($font-weight, regular);
        }
      }
    }
  }

}
