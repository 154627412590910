@import "../../core/utils/browsers";

@mixin normalize-theme($theme) {
  $mono: map-get($theme, mono);

  // In safari input disabled have style webkit-text-fill-color, which is more important than color
  // but if we override it, break color in placeholder
  @include safari {
    input, input:disabled {
      -webkit-text-fill-color: currentColor;

      &::-webkit-input-placeholder {
        -webkit-text-fill-color: map-get($mono, tint30);
      }
    }
  }
}
