@import '../../core/utils/breakpoints';

@mixin checkbox-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .checkbox {
    color: map-get($mono, primary);

    &:focus {
      outline-color: map-get($primary, tint50);
    }

    &__multiply {
      background-color: map-get($success, primary);
      color: $white;
    }

    &_accent {
      .checkbox__styled {
        &:after {
          border-color: map-get($accent, primary);
        }
      }
    }
    &_primary {
      .checkbox__styled {
        &:after {
          border-color: map-get($primary, primary);
        }
      }
    }
    &_success {
      .checkbox__styled {
        &:after {
          border-color: map-get($success, primary);
        }
      }
    }

    &_theme_disabled-select {
      .checkbox__input {
        &:disabled {
          & + .checkbox__styled {
            background: map-get($custom, checkbox);
            border-color: map-get($mono, tint10);
            &:after {
              border-color: map-get($accent, primary);
            }
          }
        }
      }
    }
  }
  .checkbox__styled {
    background: map-get($custom, checkbox);
    border-color: map-get($mono, tint30);
    &:after {
      border-color: map-get($mono, primary);
    }
  }
  .checkbox__input {
    &:disabled {
      & + .checkbox__styled {
        background: map-get($custom, checkbox);
        border-color: map-get($mono, tint10);
        &:after {
          border-color: map-get($mono, tint30);
        }
      }
    }
  }
}

@mixin checkbox-typography($config) {
  $font-size: map-get($config, font-size);
  .checkbox {
    &__multiply {
      font-size: map-get($font-size, small);
    }

    @include mobile {
      &_text-tiny-sm {
        .checkbox__label {
          font-size: map-get($font-size, tiny);
        }
      }
      &_text-base-sm {
        .checkbox__label {
          font-size: map-get($font-size, base);
        }
      }
    }
  }
}
