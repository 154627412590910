@import "../../core/utils/breakpoints";

.radio {
  display: flex;
  cursor: pointer;
  outline: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &_disabled {
    cursor: default;
  }

  &__input {
    display: none;

    &:checked + .radio__icon:after {
      visibility: visible;
    }
  }

  &__icon {
    align-self: center;
    height: 1.428571428571429rem;
    min-width: 1.428571428571429rem;
    position: relative;
    width: 1.428571428571429rem;

    &:before {
      border-radius: 50%;
      border-style: solid;
      border-width: 0.071428571428571rem;
      content: '';
      height: 1.428571428571429rem;
      left: 0;
      position: absolute;
      top: 0;
      width: 1.428571428571429rem;
    }

    &:after {
      border-radius: 50%;
      content: '';
      height: 0.857142857142857rem;
      left: 0.285714285714286rem;
      position: absolute;
      top: 0.26rem;
      visibility: hidden;
      width: 0.857142857142857rem;
    }
  }

  &__text {
    max-width: 100%;
    padding: 0 0 0 1rem;
    position: relative;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
  }

  &__inner-html {
    position: relative;
    display: flex;
    align-items: center;
  }
}

@include accessible {
  .radio {
    &:focus {
      outline: 0.1rem solid;
    }
  }
}

@include mobile {
  .radio {
    &_selfcontrol {
      height: auto;
      align-items: center;
    }
  }
}
