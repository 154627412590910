@import '../../core/utils/breakpoints';

@mixin datepicker-theme($theme) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);
  $alert: map-get($theme, alert);

  .datepicker-host {
    &.ng-invalid.ng-dirty .text-field {
      background-color: map-get($alert, tint10);

      &::placeholder {
        color: map-get($mono, tint30);
      }
    }
  }

  .datepicker {
    ngb-datepicker,
    &__dropdown-menu {
      background: $white;
    }
    ngb-datepicker,
    &__picker {
      .ngb-dp-navigation-chevron {
        border-color: map-get($primary, primary);
      }
      .ngb-dp-day {
        .btn-light {
          &:hover {
            background: map-get($primary, tint90);
            color: $white;
          }
        }
        .bg-primary {
          background: map-get($primary, primary);
          color: $white;
        }

      }

      .ngb-dp-week {
        .ngb-dp-weekday {
          color: map-get($mono, tint25);
        }
      }

      .custom-day {

        &.focused {
          background-color: $white;
        }

        &.range, &:hover {
          background: map-get($primary, primary);
          color: $white;
        }

        &.faded {
          background: map-get($primary, tint60);
        }

      }

      .ngb-dp-navigation-select {
        .custom-select {
          border: 0.07143rem solid map-get($mono, tint25);

          &:disabled {
            background: $white;
          }
        }
      }
    }

    &__input:disabled {
      background: map-get($mono, tint05);
    }

    &__input_invalid:focus,
    &__input_invalid {
      background-color: map-get($alert, tint40);

      &::placeholder {
        color: map-get($mono, tint30);
      }
    }
  }

  @include accessible {
    .datepicker {
      &__toggle {
        &:focus {
          background: map-get($theme, black);

          .icon {
            color: map-get($theme, white);
          }
        }
      }

      &__reset-button {
        &:focus {
          background: map-get($theme, black);

          .figure {
            color: map-get($theme, white);
          }
        }
      }
    }

    .ngb-dp-day {
      &:focus {
        background: map-get($theme, black);
        color: map-get($theme, white);
        text-decoration: underline;
      }
    }

    .ngb-dp-navigation-select {
      .custom-select {
        &:focus {
          background: map-get($theme, black);
          color: map-get($theme, white);
        }
      }
    }
  }
}

@mixin datepicker-typography($config) {
  $font-size: map-get($config, font-size);
  .datepicker {
    &__input {
      &::placeholder {
        font-size: map-get($font-size, small);
      }

      &:-ms-input-placeholder {
        font-size: map-get($font-size, big);
      }
    }
    //with small text in mobile
    &_theme_text-sm-mobile {
      @include mobile {
        .form-control__label {
          font-size: map-get($font-size, small);;
        }
      }
    }
  }
}

@mixin datepicker-shadow($shadows) {
  .datepicker {
    ngb-datepicker.dropdown-menu,
    &__dropdown-menu {
      box-shadow: map-get($shadows, dropdown);
    }
  }
}
