@import "./icon-theme";
@import "./icon-sizes";
@import '../../core/utils/breakpoints';

.icon {
  display: inline-block;
  position: relative;
  fill: currentColor;

  svg {
    vertical-align: middle;
  }

  &_fit-width {
    svg {
      width: 100%;
      height: 100%;
    }
  }
  &_fit-height {
    height: 100%;
    svg {
      height: 100%;
      width: 2rem;
    }
  }

  &_4_w {
    svg {
      width: 0.285714285714286rem;
      height: auto;
    }
  }

  &_6_h-limit {
    svg {
      max-height: 0.428571428571429rem;
    }
  }

  &_9_w {
    svg {
      width: 0.642857142857143rem;
      height: auto;
    }
  }

  &_10_w {
    svg {
      width: 0.714285714285714rem;
      height: auto;
    }
  }

  &_12 {
    svg {
      width: 0.8rem;
      height: 0.8rem;
    }
  }

  &_12_w {
    svg {
      width: 0.8rem;
      height: auto;
    }
  }

  &_14 {
    @include icon_14();
  }

  &_15 {
    svg {
      width: 1.07142857143rem;
      height: 1.07142857143rem;
    }
  }

  &_16 {
   @include icon_16();
  }

  &_16_w {
    svg {
      width: 1.2rem;
    }
  }

  &_16_h-limit {
    svg {
      max-height: 1.142857142857143rem;
    }
  }

  &_17 {
    svg {
      width: 1.21428571429rem;
      height: 1.21428571429rem;
    }
  }

  &_18 {
    @include icon_18();
  }

  &_19 {
    svg {
      width: 1.4rem;
      height: 1.4rem;
    }
  }

  &_20_w {
    svg {
      width: 1.428571428571429rem;
      height: auto;
    }
  }

  &_21_w {
    svg {
      width: 1.5rem;
      min-height: 100%;
    }
  }

  &_21_h {
    svg {
      width: auto;
      height: 1.5rem;
    }
  }

  &_22 {
    svg {
      width: 1.6rem;
      height: 1.6rem;
    }
  }

  &_22_h {
    svg {
      width: auto;
      height: 1.6rem;
    }
  }

  &_23_w {
    svg {
      width: 1.666rem;
      height: auto;
    }
  }

  &_24 {
    svg {
      width: 1.714285714285714rem;
      height: 1.714285714285714rem;
    }
  }

  &_24_w {
    svg {
      width: 1.714285714285714rem;
      height: auto;
      max-height: 2rem;
    }
  }

  &_24_h {
    svg {
      width: auto;
      height: 1.714285714285714rem;
    }
  }

  &_27_h-limit {
    svg {
      max-height: 1.928571428571429rem;
    }
  }

  &_28 {
    svg {
      width: 2rem;
      height: 2rem;
    }
  }

  &_30 {
    svg {
      width: 2.1429rem;
      height: 2.1429rem;
    }
  }

  &_30_h {
    svg {
      width: auto;
      height: 2.1429rem;
    }
  }

  &_32 {
    svg {
      width: 2.3rem;
      height: 2.3rem;
    }
  }

  &_32_w {
    svg {
      width: 2.3rem;
      height: auto;
    }
  }

  &_32_h {
    svg {
      width: auto;
      height: 2.3rem;
    }
  }

  &_40_w {
    svg {
      width: 2.857142857142857rem;
      height: auto;
    }
  }

  &_42_h {
    svg {
      width: auto;
      height: 3rem;
    }
  }

  &_46 {
    svg {
      width: 3.3rem;
      height: 3.3rem;
    }
  }

  &_46_h-limit {
    svg {
      max-height: 3.3rem;
    }
  }

  &_48_h-limit {
    svg {
      max-height: 3.428571428571429rem;
    }
  }

  &_50_h {
    svg {
      width: auto;
      height: 3.5rem;
    }
  }

  &_54_h-limit {
    svg {
      max-height: 3.857142857142857rem;
    }
  }

  &_56 {
    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  &_57 {
    svg {
      width: 1.5rem;
      height: auto;
    }
  }

  &_150_w {
    svg {
      width: 10.7143rem;
      height: auto;
    }
  }

  &_188_w {
    svg {
      width: 13.428571428571429rem;
      height: auto;
    }
  }

  &_baseline {
    svg {
      vertical-align: baseline;
    }
  }

  &_top {
    svg {
      vertical-align: top;
    }
  }

  /*@include tablet {
    &_22_tb {
      svg {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }*/

  @include mobile {
    &_16_sm {
      svg {
        width: 1.2rem;
        height: 1.2rem;
      }
    }

    &_fit-sizes {
      svg {
        height: 100%;
        width:  100%;
      }
    }
  }

}
