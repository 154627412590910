@import "../../../styles/core/utils/breakpoints";

.topics-title {
  padding-right: 2rem;
  position: relative;

  &__icon-toggle {
    padding: 0;
    background: none;
    border: 0;
    position: absolute;
    right: 0;
    top: 0;
    outline: none;

    .topics-title__icon {
      display: flex;
    }
  }

  &__icon {
    margin-left: 0;
  }
}

@include accessible {
  .topics-title {
    &__icon-toggle {
      &:focus {
        outline: 0.1rem solid currentColor;
      }
    }
  }
}
