@import '../../blocks/base/base-theme';
@import '../../blocks/virtual-tour-override/virtual-tour-override-theme';
@import '../../blocks/heading/heading-theme';
@import '../../blocks/text/text-theme';
@import '../../blocks/button/button-theme';
@import '../../blocks/form-control/form-control-theme';
@import '../../blocks/text-field/text-field-theme';
@import '../../../app/app-commons/autocomplete/autocomplete-theme';
@import '../../blocks/checkbox/checkbox-theme';
@import '../../blocks/sidebar/sidebar-theme';
@import '../../blocks/loader/loader-typography';
@import '../../blocks/tags/tag-theme';
@import '../../blocks/grid/grid-theme';
@import '../../blocks/edu-header/edu-header-theme';
@import '../../blocks/dpp-pk-cycles/dpp-pk-cycles-theme';
@import '../../blocks/edu-section/edu-section-theme';
@import '../../blocks/add-document/add-document-theme';
@import '../../blocks/checkbox/checkbox-theme';
@import '../../blocks/topics/topics-theme';
@import '../../blocks/topics-title/topics-title-theme.scss';
@import '../../blocks/radio/radio-theme';
@import '../../blocks/toast/toast-theme';
@import '../../blocks/iam-screen/iam-screen-theme';

@import '../../../app/user-account/zet/zet-count-theme';
@import '../../../app/app-commons/like-dislike/like-dislike-theme';
@import '../../../app/app-commons/rating/rating-theme';
@import '../../../app/app-commons/dialog/confirm/confirm-theme';
@import '../../../app/app-commons/show-more-component/show-more-theme';
@import '../../../app/app-commons/multi-zet/multi-zet-theme';
@import '../../../app/app-commons/markers-zet/markers-zet-theme';
@import '../../../app/user-account/profile/profile-titles/profile-titles-theme';
@import '../../../app/user-account/profile/profile-documents/documents-theme.scss';
@import '../../../app/app-commons/dropdown-tree/dropdown-tree-theme';
@import '../../../app/search/search-theme';
@import '../../../app/search/search-wizard/search-wizard-theme';
@import '../../../app/search/rule-attached-element-info/rule-attached-element-info-theme';
@import '../../../app/user-account/schedule/schedule-theme';
@import '../../../app/user-account/profile/profile-documents/documents-theme';
@import '../../../app/user-account/profile/profile-documents/choose-document/choose-document.component';
@import '../../../app/user-account/assessment-card/assessment-card-theme';
@import '../../../app/user-account/psy-test-card/psy-test-card-theme';
@import '../../../app/user-account/psychological-tests-screen/psychological-tests-screen-theme';
@import '../../../app/app-commons/questionary/prof-questionary/prof-questionary-container/prof-questionary-container-theme';
@import '../../../app/app-commons/questionary/psy-questionary/psy-questionary-questions-container/psy-questionary-questions-container-theme';
@import '../../../app/app-commons/questionary/psy-questionary/result/questionary-results-item/questionary-results-item-theme';
@import '../../../app/app-commons/questionary/psy-questionary/result/questionary-results/questionary-results-theme';
@import '../../../app/user-account/psychological-tests-screen/psychologicals-tests-screen-theme';
@import '../../../app/app-commons/questionary/questionary-rewards/questionary-rewards-theme';
@import '../../../app/user-account/profile/profile-documents/profile-document-certificate/profile-document-certificate-theme';
@import '../../../app/user-account/profile/profile-theme';
@import '../../../app/app-commons/questionary/prof-questionary/prof-questionary-questions-container/prof-questionary-questions-container-theme';
@import '../../../app/user-account/profile/profile-settings/profile-settings-theme';
@import '../../../app/user-account/profile/profile-titles/profile-title-item/profile-title-item-theme';
@import '../../../app/user-account/edu-element-view/edu-element-view-theme';
@import '../../../app/user-account/edu-trajectory-helper/edu-trajectory-helper-theme';
@import '../../../app/user-account/edu-element-view-rating/edu-element-view-rating-theme';
@import '../../../app/user-account/edu-element-view-title/edu-element-view-title-theme';
@import '../../../app/user-account/edu-specifics/edu-specifics-theme';
@import '../../../app/user-account/see-annotation/see-annotation-theme';
@import '../../../app/user-account/portfolio/portfolio-theme';
@import '../../../app/user-account/dpp-pk-cycles/dpp-pk-cycles-theme';
@import '../../../app/user-account/dpp-pk-my-cycles/dpp-pk-my-cycles-theme';
@import '../../../app/user-init/access-doc-loader/access-doc-loader-theme';
@import '../../../app/header/header-theme';
@import '../../../app/submenu/submenu-theme';
@import '../../../app/login/login-theme';
@import '../../../app/card/card-theme';
@import '../../../app/tabs/tab-group/tab-group-theme';
@import '../../../styles/blocks/slider-button/slider-button-theme';
@import '../../../styles/blocks/datepicker/datepicker-theme';
@import '../../../app/user-account/edu-element-view-iom/iom-main-info/iom-main-info-theme';
@import '../../../app/user-account/edu-element-view-iom/iom-extra-info/iom-extra-info-theme';
@import '../../../app/user-account/edu-element-view-om/om-main-info/om-main-info-theme';
@import '../../../app/user-account/edu-element-view-om/om-extra-info/om-extra-info-theme';
@import '../../../app/user-account/dpp-pk-addresses/dpp-pk-addresses-theme';
@import '../../../app/user-account/edu-element-view/selected-cycle/selected-cycle-theme';
@import "../../../app/user-account/dpp-pk-popup/dpp-pk-popup-theme";
@import "../../../app/user-account/read-more/read-more-theme";
@import "../../../app/user-account/topics/included-topic/included-topic-theme";
@import "../../blocks/range-slider/range-slider-theme";
@import "../../../app/app-commons/multi-line-text/multi-line-text-theme";
@import '../../../app/user-account/select-edu-rule/select-edu-rule-theme';
@import '../../../app/user-account/profile-awards/profile-awards-theme';
@import '../../../app/user-account/om-addresses/om-addresses-theme';
@import '../../../app/user-init/frmr-certificates-confirmation/_frmr-certificates-confirmation-theme';
@import '../../../app/app-commons/outside-cycles/outside-cycles-theme';


@mixin angular-portal-typography ($config, $custom) {
  /* --- BASE ELEMENTS --- */
  @include base-typography($config);
  @include virtual-tour-override-typography($config);
  @include button-typography($config);
  @include form-control-typography($config);
  @include range-slider-typography($config);
  @include text-field-typography($config);
  @include autocomplete-typography($config);
  @include header-typography($config);
  @include heading-typography($config);
  @include text-typography($config);
  @include loader-typography($config);
  @include tag-typography($config);
  @include tab-group-typography($config);
  @include card-typography($config);
  @include multi-zet-typography($config);
  @include included-topic-typography($config);
  @include markers-zet-typography($config);
  @include like-dislike-typography($config);
  @include show-more-typography($config);
  @include rating-typography($config);
  @include search-wizard-typography($config);
  @include slider-button-typography($config);
  @include search-typography($config);
  @include datepicker-typography($config);
  @include topics-typography($config);
  @include radio-typography($config);
  @include dropdown-tree-typography($config);
  @include toast-typography($config);

  /* --- SCREEN ELEMENTS --- */
  @include edu-header-typography($config);
  @include edu-section-typography($config);
  @include iam-screen-typography($config);
  @include submenu-typography($config);
  @include login-typography($config);
  @include access-doc-loader-typography($config);
  @include add-document-typography($config);
  @include profile-typography($config);
  @include zet-count-typography($config, $custom);
  @include schedule-typography($config);
  @include documents-typography($config);
  @include documents-certificate-typography($config);
  @include profile-settings-typography($config);
  @include profile-title-item-typography($config);
  @include edu-element-view-typography($config);
  @include edu-element-view-rating-typography($config);
  @include edu-element-view-title-typography($config);
  @include edu-specifics-typography($config);
  @include see-annotation-typography($config);
  @include portfolio-typography($config);
  @include grid-typography($config);
  @include checkbox-typography($config);
  @include dpp-pk-cycles-typography($config);
  @include dpp-pk-my-cycles-typography($config);
  @include assessment-card-typography($config);
  @include edu-trajectory-helper-typography($config);
  @include confirm-typography($config);
  @include psychological-test-screen-typography($config);
  @include iom-main-info-typography($config);
  @include iom-extra-info-typography($config);
  @include om-main-info-typography($config);
  @include om-extra-info-typography($config);
  @include prof-questionary-container-typography($config);
  @include psy-questionary-questions-container-typography($config);
  @include psy-test-card-typography($config);
  @include psychological-test-screen-typography($config);
  @include questionary-results-item-typography($config);
  @include questionary-results-typography($config);
  @include prof-questionary-questions-container-typography($config);
  @include questionary-rewards-typography($config);
  @include dpp-pk-popup-typography($config);
  @include dpp-pk-typography($config);
  @include rule-attached-element-info-typography($config);
  @include edu-selected-cycle-typography($config);
  @include read-more-typography($config);
  @include multi-line-text-typography($config);
  @include select-edu-rule-typography($config);
  @include topics-title-typography($config);
  @include profile-awards-typography($config);
  @include frmr-certificates-confirmation-typography($config);
  @include sidebar-typography($config);
  @include om-addresses-typography($config);
  @include choose-document-typography($config);
  @include outside-cycles-typography($config);
}
