@import "src/styles/core/utils/breakpoints";

@mixin profile-settings-theme($theme, $custom) {
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);

  .profile-settings {

    &__successful-sync {
      color: map-get($mono, tint50);
    }

    &__access-control {

      .checkbox__styled:after {
        border-color: map-get($success, primary);
      }
    }

    &__section {
      border-bottom: 0.055rem solid map-get($mono, tint15);

      &:last-of-type {
        border-bottom: none;
      }
    }

    &__loader-box {
      background: map-get($custom, loader) ;
    }
  }
}


@mixin profile-settings-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .profile-settings {

    &__access-title {
      font-size: map-get($font-size, big);
      font-weight: map-get($font-weight, bold);
    }
  }

  @include mobile {
    .profile-settings {

      &__access-control {

        .checkbox__label {
          font-size: map-get($font-size, base);
        }
      }

      &__name {
        font-size: map-get($font-size, small);
      }

      &__access-control-testing-link {
        font-size: map-get($font-size, small);
        font-weight: map-get($font-weight, normal);
      }
    }
  }
}
