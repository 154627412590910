@import '../../../styles/core/utils/breakpoints';

@mixin multi-zet-theme($theme) {
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $white: map-get($theme, white);

  .multi-zet {

    &__text-multiplier {
      background: map-get($success, primary);
      color: $white;
    }
  }

  @include mobile {
    .multi-zet {

      &_theme_search-mobile {

        .multi-zet__text, .multi-zet__label {
          color: inherit;
        }
      }
    }
  }
}

@mixin multi-zet-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .multi-zet {

    &__text-multiplier {
      font-size: map-get($font-size, small);
    }
  }

  @include mobile {
    .multi-zet {

      &_theme_search-mobile {

        .multi-zet__text, .multi-zet__label {
          font-weight: map-get($font-weight, regular);
        }
      }
    }
  }
}
