@import "../../../styles/core/utils/breakpoints";

@mixin edu-element-view-about-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .edu-element-view-about {
    &__all-specialties {
      color: map-get($primary, primary);
    }
    &__line {
      &:after {
        background: map-get($mono, tint20);
      }
    }
    /*@include tablet {
      &__row-cell {
        &:first-child {
          border-right-color: map-get($mono, tint20);
        }
      }
    }*/
  }
}
