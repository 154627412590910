.chart-card {
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 1rem;

  &_my-plan {
    margin-bottom: 2rem;
    margin-top: 0;
    padding-top: 0;
  }
}

.customized-chart-container {
  display: grid;
}
