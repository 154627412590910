@import "../../styles/core/utils/breakpoints";

@mixin login-theme($theme, $custom) {
  $white: map-get($theme, white);
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .login {
    &__auth-extra-link {
      color: map-get($primary, primary);
    }
    &__esia-link {
      color: map-get($mono, tint80);
      border-color: map-get($primary, primary);
    }
  }

  .tab-group {
    &__tab{
      color: map-get($mono, tint30);
      &_active{
        color: map-get($primary, primary);
        border-bottom-color: map-get($primary, primary);
      }
    }
  }

  .iam-screen {
    &_login {
      background-color: $white;
    }
  }
  //mobile version
  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .iam-screen {
      &_login {
        background: none;
      }
    }
  }

  @include accessible {
    .login {
      &__esia-link {
        &:focus {
          outline: 0.1rem solid map-get($theme, black);
        }
      }
    }
  }
}


@mixin login-typography($config) {
  $font-size: map-get($config, font-size);
  .login {
    &__esia-link {
      font-size: map-get($font-size, h3);
    }
  }
}

@mixin login-shadow($shadows) {
  .iam-screen {
    &_login {
      box-shadow: map-get($shadows, modal-light);
    }
  }
  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .iam-screen {
      &_login {
        box-shadow: none;
      }
    }
  }
}
