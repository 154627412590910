@import "src/styles/core/utils/breakpoints";

@mixin wrap-navigation-item-theme($theme) {
  $mono: map-get($theme, mono);

  .wrap-navigation-item {
    background: map-get($theme, white);

    &__link {
      color: map-get($theme, black);
    }

    &__link-pic {
      border-color: map-get($mono, tint50);
      color: map-get($mono, tint50);
    }
  }

  @include accessible {
    .wrap-navigation-item {
      border: 0.1rem solid;
      border-radius: 0.14814814814rem;

      &__link {
        &:focus {
          background: map-get($theme, black);
          color: map-get($theme, white);

          .wrap-navigation-item__text {
            color: map-get($theme, white);
          }

          .wrap-navigation-item__link-pic {
            border-color:  map-get($theme, white);
            color:  map-get($theme, white);
          }
        }
      }
    }
  }
}

@mixin wrap-navigation-item-shadow($shadows) {

  .wrap-navigation-item {
    box-shadow: map-get($shadows, modal-light);
  }

}
