@mixin error-message-theme($theme, $custom) {
  $alert: map-get($theme, alert);
  $white: map-get($theme, white);
  $field: map-get($custom, field);

  .error-message {
    background-color: map-get($field, error);

    &_color_alert {
      color: $white
    }
  }
}
