@import "src/styles/core/utils/breakpoints";

@mixin rating-typography($config) {
  $font-size: map-get($config, font-size);

  .rating-container {
    &__text {
      font-size: map-get($font-size, tiny);
    }

    &__inner-text {
      font-size: map-get($font-size, tiny-little);
    }
  }

  @include mobile {
    .rating-container {
      &__inner-text {
        font-size: map-get($font-size, small);
      }
    }
  }
}

@mixin rating-theme($theme) {
  $mono: map-get($theme, mono);

  .rating-container {

    &__total {
      color: map-get($mono, tint40);
    }

    &__rating-not-pic {
      &:hover {
        color: map-get($mono, tint40);
      }
    }
  }
}
