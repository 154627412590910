@import "../../../../../../styles/core/utils/breakpoints";

@mixin questionary-results-item-theme($theme) {
  $alert: map-get($theme, alert);
  $success: map-get($theme, success);
  $accent: map-get($theme, accent);

  .questionary-result-item {
    &_success {
      color: map-get($success, primary);
    }

    &_alert {
      color: map-get($alert, primary);
    }

    &_accent {
      color: map-get($accent, primary);
    }
  }
}

@mixin questionary-results-item-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .questionary-result-item {

    &__number-of-points {
      font-size: map-get($font-size, points);
    }

  }

  @include mobile {
    .questionary-result-item {

      &__number-of-points {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, points-mobile);
      }

      &__category {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, base);
      }

      &__interpretation {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, base);
      }
    }
  }
}
