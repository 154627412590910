@mixin border-separator-theme($config) {
  $mono: map_get($config, mono);

  .border-separator-light {
    border-color: map_get($mono, tint05);
  }

  .border-separator-primary {
    border-color: map_get($mono, tint16);
  }
}
