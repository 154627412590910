@import '../../../styles/core/utils/breakpoints';

@mixin portfolio-theme($theme) {
  $white: map-get($theme, white);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);
  $primary: map-get($theme, primary);

  .portfolio {
    &__feedback-button {
      color: map-get($primary, tint50);

      &_has-feedback {
        color: map-get($primary, primary);
      }
    }

    &__like-icon {
      color: map-get($success, primary);
    }

    &__grid {
      background: $white;
    }
  }

  @include mobile {
    .portfolio {

      &__grid {
        background: none;
      }

      &__grid-row {
        background: none;
      }
    }
  }

  // TODO tablet version
  /*
  @include tablet {
    .portfolio {

      &__grid {
        background: none;
      }
    }
  }*/

  .portfolio-imob {
    background-color: $white;

    &__feedback-button {
      color: map-get($primary, tint50);

      &_has-feedback {
        color: map-get($primary, primary);
      }
    }

    &__type-icon {
      color: map-get($primary, primary);
    }

    &__footer-icons {
      &-charge {
        color: map-get($mono, tint70);
      }
    }
  }
}

@mixin portfolio-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .portfolio {

    &__filters {
      &-button,
      &-button-mobile,
      &-download-button,
      &-download-button-mobile,
      &-download-button-portfolio {
        font-weight: map-get($font-weight, bold);
      }

      &-download-button,
      &-button-mobile,
      &-download-button-portfolio,
      &-download-button-mobile {
        font-size: map-get($font-size, small);
      }

    }

    &__grid {
      .grid {
        &__column {
          font-size: .9rem;
        }
      }
    }

    &__title {
      font-weight: map-get($font-weight, regular);
    }
  }

  @include mobile {
    .portfolio {

      &__title {
        font-weight: map-get($font-weight, bold);
      }

      &__filters {

        &-button,
        &-download-button {
          font-size: map-get($font-size, small);
        }
      }
    }
  }

  @include tablet-resize {
    .portfolio {

      &__date-cell_value {
        font-size: map-get($font-size, base-small);
      }
    }
  }

  /*
  @include tablet {
    .portfolio {
      &__title {
        font-weight: map-get($font-weight, bold);
      }
    }
  }*/
}

@mixin portfolio-shadow($shadows) {

  .portfolio {
    &__grid {
      box-shadow: map-get($shadows, portfolio-shadow);
    }
  }

  @include mobile {
    .portfolio {
      &__grid {
        box-shadow: none;
      }
    }
  }

// TODO tablet-version
  /*
  @include tablet {
    .portfolio {
      &__grid {
        box-shadow: none;
      }
    }
  }*/

}
