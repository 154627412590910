@import "../../core/utils/breakpoints";

.link {
  text-decoration: none;
  font-size: 1.15rem;
  font-weight: bold;
  &_normal {
    font-size: 1rem;
    font-weight: normal;
  }
}

@include accessible {
  .link {
    &:focus {
      outline: 0.1rem solid currentColor;
    }
  }
}
