@mixin notifications-color-theme($theme) {
  $accent: map_get($theme, accent);
  $primary: map_get($theme, primary);

  .notification-color {

    &_important {
      border-left-color: map_get($accent, shade45);
    }

    &_mediumImportance {
      border-left-color: map_get($accent, tint70);
    }

    &_lowImportance {
      border-left-color: map_get($primary, shade20);
    }
  }
}
