@mixin icon_18 {
  svg {
    width: 1.3rem;
    height: 1.3rem;
  }
}

@mixin icon_16 {
  svg {
    width: 1.2rem;
    height: 1.2rem;
  }
}

@mixin icon_14 {
  svg {
    width: 1rem;
    height: 1rem;
  }
}

