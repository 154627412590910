@mixin progress-bar-theme($theme, $custom) {
  $progress-bar-completed: map-get($theme, success);
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .progress-bar {
    &__line {
      background: map-get($primary, tint25);
    }
  
    &__planned {
      background: map-get($custom, progress-bar-planned);
    }
  
    &__completed {
      background: map-get($progress-bar-completed, primary);
    }
  }
}
