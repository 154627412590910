@import "../../../styles/blocks/loader/loader_mixin";
@import "src/styles/core/utils/breakpoints";

@mixin schedule-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $mono: map-get($theme, mono);

  .schedule {
    &__card {
      background-color: map-get($theme, white);
    }

    &__loader {
      @include loader-color(map-get($primary, shade20));
    }

    &__error {
      color: map-get($theme, white);
      background-color: map-get($alert, primary);
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .schedule {
      &__post-title {
        color: map-get($mono, tint50);
      }
    }
  }

  @include accessible {
    .schedule {
      &__card {
        border: 0.1rem solid;
        border-radius: 0.14814814814rem;
      }
    }
  }
}

@mixin schedule-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .schedule {
      &__title {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, h1-mobile);
      }

      &__post-title {
        font-size: map-get($font-size, small);
      }
    }
  }

}
