@mixin select-theme($config) {
  $white: map-get($config, white);
  $primary: map-get($config, primary);

  .select {
    &-list {

      &__item {

        &-button {
          background: $white;

          &:hover,
          &_active {
            background: map-get($primary, primary);
            color: $white;
          }

          &:hover {
            background: map-get($primary, tint90);
          }
        }
      }
    }

    &__dropdown-menu {
      background: $white;
    }
  }
}

@mixin select-shadow($shadows) {
  .select {
    &__dropdown-menu {
      box-shadow: map-get($shadows, popover);
    }
  }
}
