@import '../header/header-base';
@import '../../styles/core/utils/breakpoints';

@mixin submenu-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $black: map-get($theme, black);
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $notification-importance-width: 0.3rem;

  .submenu {
    &__notifications-separator {
      border-bottom: 0.05rem solid map-get($mono, tint10);
    }

    &-list {
      &__link {
        color: $black;

        &_active, &:hover {
          background: map-get($custom, submenu);
        }
      }
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .submenu {

      &-list {
        &__link {
          color: map-get($mono, tint50);

          &_active, &:hover {
            background: none;
          }
        }
      }

      &_accordion {
        .submenu__toggle {
          &:after {
            border-color: map-get($mono, tint20);
          }
        }
      }
    }
  }

  /*@include tablet {
    .submenu {
      &-list {
        &__link {
          &_active, &:hover {
            background: none;
            color: map-get($primary, primary);
          }
        }
      }

      &_accordion {
        .submenu__toggle {
          &:after {
            border-color: map-get($mono, tint20);
          }
        }
      }

    }
  }*/

  @include accessible {
    .submenu {
      &_accordion {

        .submenu__toggle {
          &:after {
            border-color: map-get($theme, black);
          }
        }
      }
    }

  }
}

@mixin submenu-typography($config) {
  $font-family: map-get($config, font-family);
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .submenu {
    font-family: $font-family;

    &__notifications-header {
      &._flex {
        & .text {
          font-weight: map-get($font-weight, regular);
        }
      }
    }

    &-list__link {
      font-size: 1rem;
      font-weight: map-get($font-weight, regular);

      &_notification {
        font-size: map-get($font-size, small);
      }
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .submenu {

      &-list__link {
        font-size: map-get($font-size, small);
      }
    }
  }
}
