@mixin collapse-theme($theme) {
  $mono: map-get($theme, mono);

  .collapse-toggle {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;
    &__icon {
      color: map-get($mono, tint50);
    }
  }
}
