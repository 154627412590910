@import "../../core/utils/breakpoints";
@import "breakpoints-vars";

$bp-width-full-hd: 1820px;
$bp-width-qhd: 2500px;
$bp-height-qhd: 1400px;

@mixin base-typography($config) {
  $font-size: map-get($config, font-size);

  html {
    font-family: map-get($config, font-family);
    font-size: map-get($font-size, breakpoint-default);

    @media (min-width: $bp-width-full-hd) {
      font-size: map-get($font-size, breakpoint-1820);
    }

    @media (min-width: $bp-width-full-hd) {
      font-size: map-get($font-size, breakpoint-1820);
    }

    @media (min-width: $bp-width-qhd) and (min-height: $bp-height-qhd) {
      font-size: map-get($font-size, breakpoint-2500);
    }

    @include tablet-resize {

      @if $bp-web {
        font-size: calc( (100vw - 960px) / 186 + 11px);
      } @else {
        font-size: 16px;
      }
    }

    @media (min-width: $bp-min-tablet) and (max-width: 960px) {
      @if $bp-web {
        font-size: calc( (100vw - 768px) / 96 + 8px);
      } @else {
        font-size: 14px;
      }
    }
  }
}
