@import "../../core/utils/breakpoints";
@import "../../core/utils/positions";

$offset: 0.6666rem;

.toast-top-left {
  @include top($offset);
  left: $offset;
}
.toast-top-right {
  @include top($offset);
  right: $offset;
}
.toast-bottom-right {
  @include bottom($offset);
  right: $offset;
}
.toast-bottom-left {
  @include bottom($offset);
  left: $offset;
}

.toast-container .toast {
  width: 17rem;
  padding: 0.8333rem 0.8333rem 0.8333rem 2.777rem;
  background-size: 1.33rem;
}

@include accessible {
  .toast {
    border: 0.1rem solid;
    border-radius: 0.214rem;
  }
}

@media all and (max-width: 480px) {
  .toast-container .toast.div {
    padding: 0.57rem 0.57rem 0.57rem 3.57rem;
    width: 18rem;
  }

  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .toast.div {
    padding: 1.07rem 1.07rem 1.07rem 3.57rem;
    width: 25rem;
  }
}
