@import "../../../styles/core/utils/breakpoints";

@mixin read-more-theme($theme, $custom) {
  $white: map-get($theme, white);
  $white_transparent: rgba($white, 0);
  $lavender_tint25: map-get($custom, root-bg);
  $lavender_transparent: rgba($lavender_tint25, 0);

  .read-more {
    &:after {
      background-image: linear-gradient(to right, $lavender_transparent, $lavender_tint25 70%, $lavender_tint25);
    }
  }

  @include mobile {
    .read-more {
      &_theme_specifics {
        &:after {
          background-image: linear-gradient(to right, $white_transparent, $white 70%, $white);
        }
      }
    }
  }
}

@mixin read-more-typography($config) {
  $font-size: map-get($config, font-size);

  .read-more {

    &__button {
      font-size: map-get($font-size, small);
    }
  }
}
