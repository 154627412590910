@import '../../core/utils/clearfix';
@import '../../core/utils/breakpoints';

.edu-section {
  margin: 0.714285714285714rem 0 0;

  &:first-of-type {
    margin: 2.142857142857143rem 0 0;
  }

  &__header {
    margin: 0 0 1.071428571428571rem;
  }

  &__content {
    @include clearfix();
  }

  &__card {
    float: left;
    margin: 0 1.428571428571429rem 1.428571428571429rem 0;
    width: 22.286rem;
  }

  &__loader {
    display: block;
    text-align: center;
  }

  &_mobile {
    display: none;
  }
}

@include mobile {
  .edu-section {
    margin: 1.428571428571429rem 0 0;
    &:first-of-type {
      margin: 1.7rem 0 0;
    }
    &__header {
      margin: 0 0 1.142857142857143rem;
    }
    &__card {
      float: none;
      width: 100%;
      margin: 0 auto;
      & + .edu-section__card {
        margin-top: 1.428571428571429rem;
      }
      &_wide {
        border-radius: 0.285714285714286rem;
      }
    }

    &_mobile {
      display: block;
      width: 100%;
      margin: 0 auto;

      &:first-of-type {
        padding: 0;
      }
    }
  }
}
