// Overrides default virtual tour styles defined in Hopscotch library
// Therefore selectors intentionally do not conform to BEM

@import "../../core/utils/breakpoints";

@mixin virtual-tour-override-theme($theme, $custom) {
  $primary: map-get(map-get($theme, primary), primary);
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);
  $tour: map-get($custom, tour);

  div.hopscotch-bubble {
    background-color: $primary;
    border-color: $primary;

    @if $tour {
      color: map-get($tour, text);
    }

    & .hopscotch-bubble-arrow-container.right .hopscotch-bubble-arrow-border {
      border-left-color: $primary;
    }

    & .hopscotch-bubble-arrow-container.down .hopscotch-bubble-arrow-border {
      border-top-color: $primary;
    }

    & .hopscotch-bubble-arrow-container.left .hopscotch-bubble-arrow-border {
      border-right-color: $primary;
    }

    & .hopscotch-bubble-arrow-container.up .hopscotch-bubble-arrow-border {
      border-bottom-color: $primary;
    }

    & .hopscotch-bubble-number {
      background-color: $primary;
    }

    & .hopscotch-nav-button.prev {
      background-color: $white;
      border-color: $primary;
      color: $primary;
    }

    & .hopscotch-nav-button.next {
      background-color: $primary;
      @if $tour {
        color: map-get($tour, next);
      }

      &:hover {
        background-color: darken($primary, 10%);
      }
    }

    & .hopscotch-bubble-container {
      background-color: $white;
    }
    & .hopscotch-bubble-close {
      &:before, &:after {
        background: map-get($mono, tint50);
      }

    }
  }

  @include mobile {
    div.hopscotch-bubble {
      & .hopscotch-bubble-close {
        background: map-get($mono, tint10);

        &:before, &:after {
          background: map-get($mono, tint80);
        }

      }
    }
  }

}

@mixin virtual-tour-override-typography($config) {
  $font-family: map-get($config, font-family);
  $font-size: map-get($config, font-size);

  div.hopscotch-bubble {
    font-family: $font-family;
    font-size: map-get($font-size, big);

    & .hopscotch-bubble-number {
      font-size: map-get($font-size, big);
    }

    & .hopscotch-content {
      font-family: $font-family;
      font-size: map-get($font-size, big);
    }
    & .hopscotch-nav-button {
      font-size: map-get($font-size, small);
    }
  }
}
