//TODO rename this var name (and all its includings) to '$bp-max-mobile' when all tablet version will be done
//$bp-max-mobile-true: 767px;
// $bp-max-mobile: 767px;
// $bp-min-tablet: 768px;
// $bp-max-tablet: 1333px;
// $bp-min-desktop: 1334px;
// $bp-max-desktop: 1919px;
// $bp-min-desktop-large: 1920px;

@import "breakpoints-vars";

//TODO rename this mixin (and all its includings) to 'mobile' when all tablet version will be done
/*@mixin mobile-true {
  @media (max-width: $bp-max-mobile-true) {
    @content;
  }
  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}*/

//TODO delete this mixin when all tablet version will be done
@mixin mobile {
  @media (max-width: $bp-max-mobile) {
    @content;
  }

  .mobile-version {
    @content;
  }

  ::ng-deep .mobile-version {
    @content;
  }
}

// use to resize desktop style to tablet size
@mixin tablet-resize {
  @media (min-width: $bp-min-tablet) and (max-width: $bp-max-tablet) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $bp-min-tablet) and (max-width: $bp-max-tablet) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $bp-min-desktop) and (max-width: $bp-max-desktop) {
    @content;
  }
}

@mixin desktop-large {
  @media (min-width: $bp-min-desktop-large) {
    @content;
  }
}

@mixin accessible {
  ::ng-deep .accessible-version {
    @content;
  }

  .accessible-version {
    @content;
  }
}

@mixin full-height() {
  height: 100vh;
  @include mobile {
    height: calc(var(--vh, 1vh) * 100);
  }
}
