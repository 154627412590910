@import '../../../../styles/core/utils/breakpoints';

@mixin error-notification-theme($theme) {
  $alert: map_get($theme, alert);

  .error-notification {
    background-color: map_get($alert, primary);
  }

  @include accessible {
    .error-notification {
      color: map-get($theme, white);
    }
  }
}
