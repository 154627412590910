@mixin cycle-select-theme($theme) {
  $primary: map-get($theme, primary);

  .cycle-select {
    &__psy {
      &_active {
        &.button_stroked {
          background: map-get($primary, primary);
        }
      }
    }
  }
}
