@import "src/styles/core/utils/breakpoints";
@import "src/styles/blocks/icon/icon-sizes";
@import 'src/styles/core/utils/z-indices';

@mixin profile-theme($theme, $custom) {
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);

  .profile-main {
    background-color: map-get($custom, root-bg);

    &__overlay-camera-icon {
      stroke: map-get($mono, tint02);
    }

    &__overlay {
      color: map-get($mono, tint02);
      background-color: map-get($mono, tint85);
    }

    &__photo-container {
      background-color: map-get($mono, tint10);
    }

    &__info-field {
      &_snils {
        color: map-get($mono, tint40);
      }

      &_age {
        color: map-get($mono, tint40);
      }

      &_registrationDate {
        color: map-get($mono, tint40);
      }
    }

    &__details-container {
      background-color: map-get($theme, white);
    }

    &__navigation {
      background-color: map-get($custom, root-bg);
    }

    &__navigation-link {
      color: map-get($mono, tint85);

      &_active {
        background: $white;
        color: map-get($primary, primary);
      }
    }

    &__navigation-mobile-wrap {
      background: $white;
    }

    &__label {
      outline: none;
    }
  }

  @include mobile {
    .profile-main {
      &__photo-container {
        background-color: map-get($mono, tint02);
      }

      &__label {

        &_mobile {
          &:before {
            color: $white;
            background: map-get($primary, primary);
            z-index: $layer2-z-index + 1;
          }
        }
      }

      &__photo-container {
        border-color: map-get($primary, primary);
      }

      &__camera-icon {
        color: map-get($primary, primary);
      }

      &__photo-edit-icon {
        background: map-get($primary, primary);
        color: map-get($theme, white);
      }
    }
  }

  @include accessible {
    .profile-main {
      &__label {
        &:focus {
          outline: 0.1rem dashed currentColor;
          outline-offset: 0.15rem;
        }
      }
    }
  }
}

@mixin profile-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .profile-main {

    &__overlay {
      font-size: map_get($font-size, small);
    }

    &__info-field {
      &_name {
        font-weight: map_get($font-weight, bold);
        font-size: map_get($font-size, h3);
      }
    }

    &__navigation-link {
      font-size: map_get($font-size, big);
      font-weight: map_get($font-weight, regular);

      &_active {
        font-weight: map_get($font-weight, medium);
      }
    }
  }

  @include mobile {
    .profile-main {

      &__title {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, h1-mobile);
      }

      &__info-field {
        font-size: map-get($font-size, base);

        &_snils {
          font-size: map-get($font-size, small);
        }
      }

      &__navigation-link {

        &_mobile {
          font-weight: map-get($font-weight, bold);
        }
      }

      &__navigation-link-pic {
        font-weight: map-get($font-weight, light);
      }

      &__photo-edit-icon {
        font-size: map-get($font-size, big);
      }

      &__field-icon {
        @include icon_16();

        &_mail {
          @include icon_14();
        }
      }
    }
  }
}
