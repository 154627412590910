@import "../../core/utils/breakpoints";

@mixin topics-theme($theme) {
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $alert: map-get($theme, alert);

  .topics {

    &__icon {

      &_dark-grey {
        svg path {
          fill: map-get($mono, tint80);

        }

      }

      &_success {
        svg path {
          fill: map-get($success, primary);

        }

        svg circle {
          stroke: map-get($success, primary);
        }
      }

      &_alert {
        svg path {
          fill: map-get($alert, shade10);

        }

        svg circle {
          stroke: map-get($alert, shade10);
        }
      }
    }
  }
}

@mixin topics-typography($config) {
  $font-size: map-get($config, font-size);

  .topics {

    &__show-more {
      font-size: map-get($font-size, small);
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .topics {

      &__show-more {
        font-size: map-get($font-size, base);
      }
    }
  }
}
