@import "../../../../../styles/core/utils/breakpoints";

@mixin questionary-instructions-theme($theme) {
  $white: map-get($theme, white);

  @include mobile {
    .instructions {
      background-color: $white;
    }
  }
}


@mixin questionary-instructions-shadow($shadows) {
  @include mobile {
    .instructions {
      box-shadow: map-get($shadows, modal-light);
    }
  }
}
