@import '../../core/utils/positions';
@import '../../core/utils/z-indices';

.cdk-global-overlay-wrapper,
.cdk-overlay-container {
  height: 100%;
  left: 0;
  pointer-events: none;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  @include top(0px);
  z-index: $popover-z-index !important;
}

.cdk-global-overlay-wrapper {
  top: 0;
  display: flex;
  position: absolute;
  z-index: $popover-z-index;
}

.cdk-overlay-pane {
  pointer-events: auto;
  position: absolute;
  z-index: $popover-z-index;
}

.cdk-overlay-backdrop {
  bottom: 0;
  left: 0;
  opacity: 0;
  pointer-events: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: $popover-z-index;
  -webkit-tap-highlight-color: transparent;
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}

.cdk-overlay-transparent-backdrop,
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
}

.cdk-global-scrollblock {
  overflow-y: auto;
  position: fixed;
  width: 100%;
}
