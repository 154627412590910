@import "../../../styles/core/utils/breakpoints";

@mixin notification-bubble-theme($theme, $custom) {
  $white: map-get($theme, white);
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .notification-bubble {

    &__close-button {
      background: none;
    }

    &_theme {

      &_white {
        color: map-get($primary, primary);
        background: $white;
      }

      &_search {
        color: map-get($accent, primary);
        background: map-get($custom, root-bg);
      }

      &_secondary {
        color: map-get($primary, primary);
        background: map-get($custom, root-bg);
      }
    }

    &__bubble {
      background: inherit;

      &_theme {
        &_white {
          &:after {
            border-bottom-color: $white;
          }
        }

        &_search {
          &:after {
            border-bottom-color: map-get($custom, root-bg);
          }
        }

        &_secondary {
          &:after {
            border-bottom-color: map-get($custom, root-bg);
          }
        }
      }

    }
  }

  @include mobile {
    .notification-bubble__close-button {
      background: map-get($primary, tint15);

      .figure {
        color: map-get($primary, primary);
      }
    }
  }
}
