@import "../../../styles/core/utils/breakpoints";

$bp-tb: $bp-max-tablet;
$bp-sm: $bp-max-mobile;

@mixin like-dislike-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .like-dislike {
    &__icon {
      color: map-get($mono, tint25);
      &:hover {
        color: map-get($mono, tint40);
      }
      &_active {
        &, &:hover {
          color: map-get($primary, primary);
        }
      }

      &_active-dislike {
        color: map-get($mono, tint50);

        &:hover {
          color: map-get($primary, primary);
        }
      }
    }

    &__text {
      color: map-get($mono, tint50);
    }
  }

  @include accessible {
    .like-dislike {
      &__button {
        &:focus {
          outline: 0.1rem solid currentColor;
          outline-offset: 0.15rem;
        }
      }
    }
  }
}

@mixin like-dislike-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .like-dislike {
    &__text {
      font-size: map-get($font-size, tiny);
    }
  }

  @include mobile {
    .like-dislike {

      &__text {

        &_large-tb {
          font-size: map-get($font-size, base);
        }
      }
    }
  }

  /*@include tablet {
    .like-dislike {

      &__text {

        &_large-tb {
          font-size: map-get($font-size, base);
        }
      }
    }
  }*/

 }
