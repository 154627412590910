@import "src/styles/core/utils/breakpoints";

@mixin documents-theme($theme) {
  $mono: map-get($theme, mono);
  $success: map-get($theme, success);
  $alert: map-get($theme, alert);

  .user-account-profile-document {
    &__list {
      color: map-get($mono, tint85);
    }
  }

  .status-box {
    &__icon {
      background:  map-get($alert, primary);

      &_progress {
        background:  map-get($success, primary);
      }
    }
  }
}

@mixin documents-shadow($shadows) {

  .user-account-profile-document {
    &__list {
      box-shadow: map-get($shadows, popover-tight);
    }
  }
}

@mixin documents-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .user-account-profile-document {

    &__item-subtitle,
    &__item-subtitle-value {
      font-size: map-get($font-size, big);
    }
  }

  @include mobile {
    .user-account-profile-document {
      &__item-title {
        font-weight: map-get($font-weight, bold);
        font-size: map-get($font-size, bigger);
      }

      &__item-subtitle,
      &__item-subtitle-value {
        font-size: map-get($font-size, base);
      }
    }

    .add-new-document {
      &__text {
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
