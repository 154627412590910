@import '../accordion/accordion-theme';

@mixin full-trajectory-helper-theme($theme) {
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .full-trajectory-helper {

    &__beyond-topics-container {
      @include colorRibbon(map-get($mono, tint55), map-get($mono, tint08));
      background-color: $white;
    }
  }
}

@mixin full-trajectory-helper-shadow($shadows) {
  .full-trajectory-helper {
    &__beyond-topics-container {
      box-shadow: map-get($shadows, accordion);
    }
  }
}
