@import '../../core/utils/center-block';

.centered {
  @include centered-block;

  &_absolute {
    position: absolute;
  }

  &_top {
    top: 4rem;
  }
}
