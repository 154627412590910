@mixin dropdown-tree-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $alert: map-get($theme, alert);

  .dropdown-tree {
    &__toggle {
      &-text {
        color: map-get($primary, shade25);
      }
    }

    &__list {
      border-color: map-get($mono, tint20);
      background: $white;

      &-element {
        color: map-get($primary, shade25);

        &-plus {
          color: #0a4d8d;
        }

        &_active {
          background: map-get($mono, tint15);
        }

        &_add:not(.dropdown-tree__list-element_active) {
          background: map-get($mono, tint02);
        }

        &_add-icon {
          color: map-get($primary, tint50);
        }
      }
    }

    &__popup {
      &-under {
        &_error {
          color: map-get($alert, bright);
        }
      }
    }
  }
}


@mixin dropdown-tree-typography($config) {
  $font-size: map-get($config, font-size);

  .dropdown-tree {
    &__toggle {
      &-text {
        font-size: map-get($font-size, medium);
      }
    }

    &__popup {
      &-text {
        font-size: map-get($font-size, medium);
      }
    }
  }
}

@mixin dropdown-tree-shadow($shadows) {

  .dropdown-tree {

    &__list {
      box-shadow: map-get($shadows, popover-tight);
    }
  }
}


