@import "../../../styles/core/utils/breakpoints";

@mixin pagination-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $active-option-color: map-get($accent, shade45);

  .page-indices {
    &__arrow {
      color: map-get($primary, primary);
    }

    &__arrow, &__option {
      &:hover,
      &:active {
        color: $active-option-color;
      }
    }

    &__option {
      &_selected {
        color: $active-option-color;
      }
    }
  }

  .page-size-options {
    &__option {
      color: map-get($primary, primary);

      &:hover,
      &:active {
        color: $active-option-color;
      }

      &_selected {
        color: $active-option-color;
      }
    }
  }

  @include accessible {
    .page-size-options {
      &__option {
        &_selected {
          color: map-get($theme, white);
          background: map-get($theme, black);

          &:focus {
            outline: 0.1rem dashed map-get($theme, black) !important;
          }
        }
      }
    }
  }
}
