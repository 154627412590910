@mixin multiselect2-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $custom-icons: map-get($custom, icons);
  $accent: map-get($theme, accent);
  $contrast: map-get($theme, white);
  .multiselect__button__raised {
    background-color: map-get($primary, primary) !important;
    color: $contrast !important;
  }
  .multiselect__button__stroked {
    color: map-get($primary, primary) !important;
    border: solid map-get($primary, primary) 1px !important;
  }
  .multiselect__button__stroked__disabled {
    color: rgba(0, 0, 0, 0.26) !important;
    border-color: rgba(0, 0, 0, 0.26) !important;
  }
  .multiselect__button__fixed {
    background-color: map-get($custom-icons, logo-accent-light) !important;
    color: $contrast !important;
  }
}
