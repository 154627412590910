@import '../../core/utils/breakpoints';

@mixin add-document-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .add-doc {
    &__heading {
      font-weight: map-get($font-weight, medium);
    }
    @include mobile {
      &__heading {
        font-weight: map-get($font-weight, bold);
        line-height: 1.2;
      }
      &__add-edu-org-cont {
        font-size: map-get($font-size, small);
      }
    }
  }
}
