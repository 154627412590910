@mixin icon-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $alert: map-get($theme, alert);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $custom-icons: map-get($custom, icons);

  .icon {

    &_accent {
      color: map-get($accent, primary);
    }

    &_delete {
      color: map-get($mono, tint85);
    }

    &_alert {
      color: map-get($alert, primary);
    }

    &_black {
      color: map-get($theme, black);
    }

    &_checked {
      color: map-get($accent, shade45);
    }

    &_free {
      color: map-get($custom-icons, free);
    }

    &_gift-open-luminous {
      color: map-get($custom-icons, gift);

      .luminous-stroke {
        stroke: map-get($custom-icons, luminous);
      }
    }

    &_gray {
      color: map-get($mono, tint50);
    }

    &_green {
      color: map-get($success, primary);
    }

    &_logo {
      color: map-get($primary, shade25);

      .main-stop {
        stop-color: map-get($custom-icons, logo-accent-light);
      }

      .alt-stop {
        stop-color: map-get($custom-icons, logo-accent);
      }
    }

    &_mono {
      color: map-get($mono, tint70);
    }

    &_paid {
      color: map-get($custom-icons, paid);

      .background-fill {
        fill: map-get($custom, root-bg);
      }

      .background-stroke {
        stroke: map-get($custom, root-bg);
      }
    }

    &_play-muted {
      color: map-get($mono, tint50);
    }

    &_primary {
      color: map-get($primary, primary);
    }

    &_primary-dark {
      color: map-get($primary, shade25);
    }

    &_purple {
      color: map-get($primary, shade49);
    }

    &_star {
      color: map-get($custom-icons, star);
    }

    &_light-gray {
      color: map-get($mono, tint25);
    }

    &_success {
      color: map-get($success, primary);
    }

    &_white {
      color: $white;
    }
  }
}
