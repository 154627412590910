@mixin search-select-question-theme($theme) {
  $mono: map-get($theme, mono);

  .search-select-question {
    &__figure-cross {
      &:before, &:after {
        background: map-get($mono, tint40);
      }
    }
  }

}
