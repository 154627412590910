@import "../../styles/core/utils/breakpoints";

@mixin registration-result-theme($theme, $custom) {
  $white: map-get($theme, white);

  .registration-result {
    &_result {
      .iam-screen {
        background-color: $white;
      }
    }
  }

  @include mobile {
    .registration-result {
      &_result {
        .iam-screen {
          background: none;
        }
      }
    }
  }
}

@mixin registration-result-shadow($shadows) {
  .registration-result {
    &_result {
      .iam-screen {
        box-shadow: map-get($shadows, modal-light);
      }
    }
  }

  @include mobile {
    .registration-result {
      &_result {
        .iam-screen {
          box-shadow: none;
        }
      }
    }
  }
}
