@import "../../core/utils/breakpoints";

@mixin radio-theme($theme, $custom) {
  $accent: map-get($theme, accent);
  $primary: map-get($theme, primary);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);

  .radio {
    color: map-get($mono, primary);

    &__input {
      &:disabled {
        & + .radio__icon {
          &:after {
            background: map-get($mono, tint30);
          }
        }
      }
    }

    &__icon {
      &:before {
        border-color: map-get($mono, tint30);
        background: map-get($custom, radio);
      }

      &:after {
        background: map-get($mono, primary);
      }

      &_accent {
        &:after {
          background: map-get($accent, primary);
        }
      }

      &_success {
        &:after {
          background: map-get($success, primary);
        }
      }

      &_primary {
        &:after {
          background: map-get($primary, primary);
        }
      }
    }
  }
}

@mixin radio-typography($config) {
  $font-size: map-get($config, font-size);

  @include mobile {
    .radio {
      &__text {
        font-size: map-get($font-size, base);
      }
    }
  }
}
