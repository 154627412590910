@import "../../../../../styles/blocks/loader/loader_mixin";
@import "../../../../../styles/core/utils/breakpoints";

@mixin psy-questionary-container-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);

  .psy-questionary-container {
    background-color: map-get($theme, white);

    &__loader {
      @include loader-color(map-get($primary, primary));
    }

    &__error {
      color: map-get($alert, primary);
    }
  }

  @include mobile {
    .psy-questionary-container {
      background-color: unset;
    }
  }
}

@mixin psy-questionary-container-shadow($shadows) {
  .psy-questionary-container {
    box-shadow: map-get($shadows, modal-light);
  }
}
