@use 'sass:math';

@mixin chevron-left-figure($size, $color: currentColor, $width: 0.1429rem) {
  width: $size;
  height: $size;
  border-top: solid;
  border-left: solid;
  border-width: $width;
  border-color: $color;
  border-top-left-radius: 0.1429rem;
  transform: rotate(-45deg);
  vertical-align: middle;
}

@mixin chevron-right-figure($size, $width: 0.142857142857143rem) {
  width: $size;
  height: $size;
  border-top-style: solid;
  border-left-style: solid;
  border-width: $width;
  border-top-left-radius: 0.142857142857143rem;
  transform: rotate(135deg);
  transition: transform 0.1s;
  vertical-align: middle;
}

@mixin circle-figure($size, $color: currentColor) {
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $color;
  margin-right: 0.5rem;
}

@mixin checkmark-figure($length, $width, $line-width: 0.1429rem, $color: currentColor) {
  border-bottom: solid;
  border-color: $color;
  border-right: solid;
  border-width: $line-width;
  height: $length;
  min-width: $width;
  transform: rotate(45deg);
  vertical-align: middle;
  width: $width;
}

@mixin dash-figure($width, $height, $color: currentColor) {
  vertical-align: middle;
  width: $width;
  height: $height;
  background: $color;
}

@mixin checkmark-arris-figure($length, $width, $line-width: 0.1429rem, $border-radius: 0.1429rem, $color: currentColor) {
  width: $width;
  height: $length;
  transform: rotate(45deg) skew(10deg);
  vertical-align: middle;
  position: relative;
  margin-top: math.div(-$length,2);
  &:after, &:before {
    content: "";
    position: absolute;
    border-radius: $border-radius;
    background: $color;
  }
  &:after {
    width: 100%;
    height: $line-width;
    bottom: 0;
    left: 0;
  }
  &:before {
    width: $line-width;
    height: 100%;
    top: 0;
    right: 0;
  }
}

@mixin checkmark-arris-figure-double($length, $width, $line-width: 0.1429rem, $border-radius: 0.1429rem, $offset: 0.071428571428571rem, $color: currentColor) {
  margin-left: math.div(-$width,2);
  span {
    &:after, &:before {
      content: "";
      position: absolute;
      background: $color;
    }
    &:after {
      width: $width - 2 * $line-width - $offset;
      height: $line-width;
      bottom: $line-width + $offset;
      right: $line-width + $offset;
      border-radius: $border-radius 0 0 $border-radius;
    }
    &:before {
      width: $line-width;
      height: 100%;
      bottom: $line-width + $offset;
      left: 100%;
      margin-left: $offset;
      border-radius: $border-radius $border-radius $border-radius 0;
    }
  }
}

@mixin help-figure($size, $color: currentColor) {
  width: $size;
  height: $size;
  font-size: 0.9 * $size;
  border: 0.07143rem solid $color;
  border-radius: 50%;
  color: $color;
  text-align: center;
  font-weight: bold;

  &:before {
    content: "?";
  }
}

@mixin download-figure($size, $color: currentColor, $border-width-ratio: math.div(1, 14)) {
  $border: ($border-width-ratio * $size) solid;

  position: relative;
  width: 0.6 * $size;
  height: $size;
  border-bottom: $border;
  border-color: $color;
  overflow: visible;

  &:before {
    content: "";
    width: 0.6 * $size;
    height: 0.6 * $size;
    position: absolute;
    bottom: 10%;
    right: 46.429%;
    border-right: $border;
    border-bottom: $border;
    border-color: inherit;
    transform-origin: right bottom;
    transform: rotate(45deg);
  }
  &:after {
    content: "";
    width: 0.07143rem;
    height: 90%;
    position: absolute;
    bottom: 13%;
    left: 46.429%;
    border-left: $border;
    border-color: inherit;
  }
}

@mixin plus-figure() {
  width: 1.2143rem;
  height: 1.2143rem;
  border-radius: 0.1429rem;
  vertical-align: middle;
  position: relative;

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0.6429rem;
    position: absolute;
    top: 0.2857rem;
    left: 0.5714rem;
    border-left: thin solid;
  }

  &:after {
    content: '';
    display: block;
    height: 0;
    width: 0.6429rem;
    position: absolute;
    top: 0.5714rem;
    left: 0.2857rem;
    border-top: thin solid;
  }
}

@mixin arrow($size, $degree, $color: currentColor) {
  border: solid $color;
  border-width: 0 0.15 * $size 0.15 * $size 0;
  display:      inline-block;
  padding:      0.15 * $size;
  transform: rotate($degree);
  transition: transform ease .3s, top ease .3s;
}

@mixin minus-figure() {
  width: 1.2143rem;
  height: 1.2143rem;
  border-radius: 0.1429rem;
  vertical-align: middle;
  position: relative;

  &:after {
    content: '';
    display: block;
    height: 0;
    border-top: thin solid;
    width: 0.6429rem;
    position: absolute;
    top: 0.5714rem;
    left: 0.2857rem;
  }
}

@mixin add($size, $width, $color: currentColor) {
  background: $color;
  height: $size;
  width: $width;
  position: relative;
  left:  math.div($size - $width, 2);

  &:after {
    content: "";
    height: $width;
    width: $size;
    background: $color;
    position: absolute;
    left: math.div(-($size - $width), 2);
    top: math.div($size - $width, 2);
  }
}

@mixin more-figure($size, $color: currentColor) {
  position: relative;
  width: $size;
  height: $size;
  background-color: $color;
  border-radius: 50%;
  transform: rotate(-90deg);

  &:before,
  &:after {
    content: "";
    position: absolute;
    width: inherit;
    height: inherit;
    background-color: inherit;
    border-radius: inherit;
  }

  &:before {
    left: -150%;
  }

  &:after {
    right: -150%;
  }
}

@mixin lines-figure($width, $height,  $color: currentColor) {
  width: $width;
  height: $height;
  border-radius: 0.071428571428571rem;
  background-color: $color;
  position: relative;
  &:before, &:after {
    content: "";
    position: absolute;
    width: $width;
    height: $height;
    border-radius: 0.071428571428571rem;
    background-color: $color;
    left: 0;
  }
  &:before {
    top: -3*$height;
  }
  &:after {
    bottom: -3*$height;
  }
}

@mixin close-figure($size, $color: currentColor) {
  width: $size;
  height: $size;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 1.4142 * $size;
    height: 0.07143rem;
    top: 0;
  }

  &:before {
    left: 0;
    transform-origin: left center;
    transform: rotate(45deg);
  }

  &:after {
    right: 0;
    transform-origin: right center;
    transform: rotate(-45deg);
  }
}


@mixin triangle($size: 1rem, $direction: bottom, $color: currentColor, $koef: 1) {
  width: 0;
  height: 0;
  $perpendicular-borders: $size solid transparent;
  @if $direction == top or $direction == bottom {
    border-left:   $perpendicular-borders;
    border-right:  $perpendicular-borders;
  } @else if $direction == right or $direction == left {
    border-bottom: $perpendicular-borders;
    border-top:    $perpendicular-borders;
  }
  $direction-opposite: '';
  @if $direction == top {
    $direction-opposite: bottom;
  }
  @else if $direction == bottom {
    $direction-opposite: top;
  }
  @else if $direction == left {
    $direction-opposite: right;
  }
  @else if $direction == right {
    $direction-opposite: left;
  }
  border-#{$direction-opposite}: ($size * $koef) solid $color;
}

@mixin cross-figure($height, $width: 0.1429rem, $top: 1.1429rem, $left: 0.1429rem) {

  &:before, &:after {
    top: $top;
    left: $left;
    position: absolute;
    content: ' ';
    height: $height;
    width: $width;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@mixin back($width, $height, $color: currentColor) {
  position: relative;
  transform: rotate(-45deg);
  transform-origin: 0 0;
  width: $width;
  height: $height;
  top: math.div($height , 1.2);
  &:before, &:after {
    content: '';
    position: absolute;
    background: $color;
    border-radius: 0.142857142857143rem;
  }
  &:before {
    top: 0;
    left: 0;
    right: 0;
    height: 0.142857142857143rem;
  }
  &:after {
    top: 0;
    left: 0;
    bottom: 0;
    width: 0.142857142857143rem;
  }
}

