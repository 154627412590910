@import "accessible-version-variables";

.accessible-version {

  body {
    margin: 0 auto;
    max-width: $accessible-width;
  }
}

@media (max-width: 320px) {
  .accessible-version {
    font-size: 15px;
  }
}
