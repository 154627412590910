@import "../../../../styles/core/utils/breakpoints";

@mixin questionary-rewards-theme($theme){
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);

  .questionary-rewards {
    &__finish-link-label {
      color: map-get($primary, primary);
    }

    &__btn-self-control-icon {
      path, polygon, polyline {
        stroke: map-get($theme, white);
      }
    }
  }

  @include mobile {
    .questionary-rewards {
      background: $white;
    }
  }
}

@mixin questionary-rewards-shadow($shadows) {
  @include mobile {
    .questionary-rewards {
      box-shadow: map-get($shadows, modal-light);
    }
  }
}

@mixin questionary-rewards-typography($config) {
  $font-size: map-get($config, font-size);

  .questionary-rewards {
    &__feedback-thanks-text {
      font-size: map-get($font-size, points-mobile);
    }

    &__first-self-control-recomendation {
      font-size: map-get($font-size, base);
    }
  }

  @include mobile {
    .questionary-rewards {
      &__btn-text {
        font-size: map-get($font-size, base-small);
      }

      &__first-self-control-recomendation {
        font-size: map-get($font-size, h3);
      }
    }
  }
}
