@mixin edu-element-view-iom-theme($theme) {
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);

  .edu-element-iom-page {
    &__main-info {
      background: $white;
    }

    .iom-main-info__no-active-link {
      background-color: map-get($mono, tint20);
    }

    &__dropdown {
      background-color: $white;
    }
  }

}

@mixin edu-element-view-iom-shadow($shadows) {
  .iom {
    box-shadow: map-get($shadows, activity-selection);
  }
}
