@mixin tooltip-theme($theme){
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  $black: map-get($theme, black);

  .tooltip {
    background: map-get($primary, primary);
    color: $white;

    &:after {
      border-top-color: map-get($primary, primary) !important;
    }

    &_white {
      color: $black;
      background: $white;

      &:after {
        border-top-color: $white;
      }
    }
  }
}

@mixin tooltip-shadow($shadows) {
  .tooltip {
    &_white {
      box-shadow: map-get($shadows, popover);
    }
  }
}
