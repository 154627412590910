@import "../../../../../styles/blocks/loader/loader_mixin";
@import "../../../../../styles/core/utils/breakpoints";

@mixin prof-questionary-container-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);

  .questionary-container {
    background-color: map-get($theme, white);

    &__loader {
      @include loader-color(map-get($primary, primary));
    }

    &__error {
      color: map-get($alert, primary);
    }

    @include mobile {
      background-color: transparent;
    }

  }
}

@mixin prof-questionary-container-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .prof-questionary-question {

      &__title {
        font-size: map-get($font-size, base);
        font-weight: map-get($font-weight, bold);
      }
    }
  }

}

@mixin prof-questionary-container-shadow($shadows) {
  .questionary-container {
    box-shadow: map-get($shadows, modal-light);
  }
}
