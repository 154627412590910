@import "../../../../../../styles/core/utils/breakpoints";

@mixin questionary-results-theme($theme) {
  $white: map-get($theme, white);

  @include mobile {
    .questionary-results {
      &__body {
        background: $white;
      }
    }
  }
}

@mixin questionary-results-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .questionary-results {
      &__title {
        font-size: map-get($font-size, tiny);
        font-weight: map-get($font-weight, bold);
      }
    }
  }

}

@mixin questionary-results-shadow($shadows) {
  @include mobile {
    .questionary-results {
      &__body {
        box-shadow: map-get($shadows, modal-light);
      }
    }
  }
}
