@mixin xp-bar-theme($theme) {
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $white: map-get($theme, white);

  $background: map-get($mono, tint05);

  .xp-bar {
    background-color: $background;
  }

  .xp-gained {
    background-image: linear-gradient(to right, map-get($accent, tint50), map-get($accent, primary));
  }

  .level {
    background-color: $white;
    border: solid 0.2857142857142857rem $background;
  }
}
