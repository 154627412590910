@import "src/styles/core/utils/breakpoints";

.form-control {
  display: inline-block;
  width: 100%;
  padding: 0.3rem;

  &__label {
    display: inline-block;
    margin: 0 0 0.4rem 0.2143rem;

    &_not-shift{
      margin-left: 0;
    }
  }

  &_relative {
    position: relative;
  }

  &__help-icon {
    cursor: pointer;
    margin-left: 0.2rem;
  }

  &__help {
    position: relative;
    vertical-align: super;
  }

  &__left {
    text-align: left;
  }
}

.form-control-tooltip {
  padding: 0.5rem 1.5rem;
  width: 25rem;
  line-height: 1.2rem;
}

@include mobile {
  .form-control {

    &__label {
      &_mobile-hidden {
        display: none;
      }
    }

  }
}

@include accessible {
  .form-control-tooltip {
    border: 0.1rem solid;
    border-radius: 0.14814814814rem;
  }
}
