@import "../../../../styles/core/utils/breakpoints";

@mixin edu-selected-cycle-typography($config) {
  $font-size: map-get($config, font-size);

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-selected-cycle {

      &__above-col {
        font-size: map-get($font-size, small);
      }

      &__label {
        font-size: map-get($font-size, small);
      }

      &__link {
        font-size: map-get($font-size, small);
      }

    }
  }
}
