@import "../../../styles/core/utils/breakpoints";

@mixin dpp-pk-popup-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $mono: map-get($theme, mono);
  $field: map-get($custom, field);

  .dpp-pk-popup {
    &__add-new-work-place {
      color: map-get($primary, primary);
    }

    &__invalid-form-error {
      background-color: map-get($field, error);
    }

    &__section {
      &_separator {
        border-bottom-color: map-get($mono, tint16);
      }
    }

    &__loader-box {
      background: map-get($custom, loader);
    }
  }
  @include mobile {
    .dpp-pk-popup {
      &__zet {
        &-number {
          color: map-get($mono, primary);
        }

        &-unit {
          color: map-get($mono, primary);
        }
      }
      &__header-date-range {
        .text {
          color: map-get($mono, primary);
        }
      }
      &__section {
        border-bottom-color: map-get($mono, tint16);
      }
    }
  }
}

@mixin dpp-pk-popup-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .dpp-pk-popup {
    &__zet {
      &_number {
        font-size: map-get($font-size, points);
      }
      &_unit {
        font-size: map-get($font-size, $font-size-18);
      }
    }
  }

  @include mobile {
    .dpp-pk-popup {

      &__header-main-title {
        font-size: map-get($font-size, h1-mobile-smaller);
      }

      &__zet {
        &_number {
          font-size: map-get($font-size, large-mobile);
        }
      }
      &__header-date-range {
        .text {
          font-weight: map-get($font-weight, bold);
        }
      }

      &__header-main-title {
        font-weight: map-get($font-weight, bold);
      }

      &__cancel-btn {
        font-size: map-get($font-size, h1-mobile-smaller);;
      }
    }
  }
}
