@import '../../../app/header/_header-base';

.flex-grid {
    display: flex;
    align-items: stretch;
    justify-content: flex-start;
    &_center {
        align-items: center;
    }
    &_column {
      flex-direction: column;
    }
    &_top {
        align-items: flex-start;
    }
    &__item {
        flex: 1 1 auto;
        min-width: 0;
        &_fixed {
            flex: 0 0 auto;
        }
        &_center {
            align-self: center;
        }
        &_order_1{
            order: 1;
        }
        &_order_2{
            order: 2;
        }
    }

    &_main {
        min-height: calc(100vh - #{$header-height + $header-border-width});
    }
}

