@import "src/styles/core/utils/breakpoints";

@mixin chart-theme($theme, $custom) {
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);

  .chart-card {
    background-color: $white;
  }

  @include mobile {
    .chart-card {
      box-shadow: none;
      border-bottom: 1px solid map-get($mono, tint20);

      &:last-of-type {
        border-bottom: none;
      }
    }
  }
}

@mixin chart-shadow($shadows) {
  .chart-card {
    box-shadow: map-get($shadows, chart-card);

    &_my-plan {
      box-shadow: none;
    }
  }
}
