@import '../accordion/accordion-theme';
@import '../../../styles/core/utils/breakpoints';

@mixin edu-trajectory-helper-theme($theme) {
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .edu-trajectory-helper {

    &__beyond-topics-container {
      @include colorRibbon(map-get($mono, tint55), map-get($mono, tint08));
      background-color: $white;
    }
  }
}

@mixin edu-trajectory-helper-typography($config) {
  $font-size: map-get($config, font-size);

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-trajectory-helper__beyond-topics-container-label {
      font-size: map-get($font-size, base);
    }
  }
}

@mixin edu-trajectory-helper-shadow($shadows) {
  .edu-trajectory-helper {
    &__beyond-topics-container {
      box-shadow: map-get($shadows, accordion);
    }
  }
}
