@import "../../../styles/core/utils/breakpoints";

@mixin toggle-theme($theme) {
  $mono: map-get($theme, mono);

  .toggle {
    &__chevron {
      &:before, &:after {
        background: map-get($mono, tint60);
      }
    }
  }

  @include accessible {
    .toggle {
      &__chevron {
        &:before, &:after {
          background: map-get($theme, black);
        }
      }
    }
  }
}
