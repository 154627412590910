@import "src/styles/core/utils/breakpoints";

@mixin slider-button-theme($theme) {
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);

  .switch-button {
    &__label {
      &:before {
        background-color: map-get($mono, tint25);
        border-color: map-get($mono, tint25);
      }

      &:after {
        background-color: map-get($theme, white);
      }

    }

    &__input {
      &_checked + .switch-button__label:before {
        background-color: map-get($accent, primary);
        border-color: map-get($accent, primary);
      }

    }
  }

  @include mobile {
    .switch-button {

      &__input {
        &_checked + .switch-button__label:before {
          background-color: map-get($success, primary);
          border-color: map-get($success, primary);
        }
      }
    }
  }
}

@mixin slider-button-typography($config) {
  $font-size: map-get($config, font-size);

  .switch-button {
    &__text {
      font-size: map-get($font-size, big);
    }
  }
}
