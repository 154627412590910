@import "../../../styles/core/utils/breakpoints";

@mixin heading-theme($theme) {
  $mono: map-get($theme, mono);

  .heading {
    &__secondary {
      color: map-get($mono, tint50);
    }
  }
}

@mixin heading-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .heading {
    font-family: map-get($config, font-family);
    font-weight: map-get($font-weight, bold);

    &_1 {
      font-size: map-get($font-size, h1);
    }

    &_1_mobile {
      font-size: map-get($font-size, h1);
    }

    &_2 {
      font-size: map-get($font-size, h2);
    }

    &_3 {
      font-size: map-get($font-size, h3);
    }

    &_4 {
      font-size: map-get($font-size, big);
    }

    &_light {
      font-weight: map-get($font-weight, regular);
    }

    &_medium {
      font-weight: map-get($font-weight, medium);
    }

    &__secondary {
      font-weight: map-get($font-weight, regular);
    }
  }

 /* @include tablet {
    .heading {
      &_1 {
        font-size: map-get($font-size, h1-tablet);
      }
    }
  }*/

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .heading {

      &_1 {
        font-size: map-get($font-size, h1-mobile);
      }

      &_1-smaller-sm {
        font-size: map-get($font-size, h1-mobile-smaller);
      }

      &_1_mobile {
        font-size: map-get($font-size, h1-mobile);
      }

      &_2 {
        font-size: map-get($font-size, h2-mobile);
      }

      &_2-smaller-sm {
        font-size: map-get($font-size, h2-mobile-smaller);
      }

      &_bold-sm {
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
