@import "../../../styles/core/utils/breakpoints";

@mixin popover-theme($theme, $custom) {
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $black: map-get($theme, black);

  .popover {

    &__body {
      &_arrow {
        &_top:after {
          border-color: transparent transparent $white transparent;
        }
        &_bottom:after {
          border-color: $white transparent transparent transparent;
        }
      }
    }

    &__content {
      background: $white;
    }
  }

  @include mobile {
    .popover {

      &_theme {
        &_mobile-fullscreen {
          background: map-get($custom, overlay);
        }
      }
    }
  }
}

@mixin popover-shadow($shadows) {

  .popover {
    &__content {
      box-shadow: map-get($shadows, popover);
    }
    &_theme_dpppk,
    &_theme_user-ratings {
      .popover__content {
        box-shadow: map-get($shadows, popover-tight);
      }
    }
  }
}
