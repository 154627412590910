@import '../../core/utils/breakpoints';
@import './button-base';

@mixin button-colors ($base, $hover: $base) {
  $bg: map-get($base, background);
  $color: map-get($base, color);
  $border-color: map-get($base, border-color);

  background-color: if($bg != null, $bg, transparent);
  color: $color;
  border-color: if($border-color != null, $border-color, transparent);

  &:hover:not(.button_stroked):not(.button_text) {
    $bg: map-get($hover, background);
    $color: map-get($hover, color);
    $border-color: map-get($hover, border-color);

    background-color: if($bg != null, $bg, rgba(0, 0, 0, 0.1));
    color: $color;
    border-color: if($border-color != null, $border-color, transparent);
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    &:hover {
      background-color: if($bg != null, $bg, transparent);
      color: $color;
      border-color: if($border-color != null, $border-color, transparent);
    }
  }
}

@mixin button-text-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);

  .button {
    &_text {
      &, &:hover, &:disabled {
        background: none;
      }

      &.button_primary {
        color: map-get($primary, primary);
      }

      &.button_accent {
        color: map-get($success, primary);
      }

      &.button_alert {
        color: map-get($alert, primary);
      }

      &.button_warn {
        color: map-get($accent, primary);
      }

      &.button_success {
        color: map-get($success, primary);
      }

      &.button_basic {
        color: map-get($theme, black);
      }

      &.button_mono {
        color: map-get($mono, tint40);
      }

      &.button_contrast {
        color: map-get($theme, white);
      }

      &:disabled {
        color: map-get($mono, tint40);
        &:hover {
          color: map-get($mono, tint40);
        }
      }
    }

    &_iom-btn {
      width: 14.0714rem;
      height: 2.7857rem;
      padding: 0;
    }
  }
}

@mixin button-stroked-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);

  .button {
    &_stroked {
      border-width: $b-border-width;
      border-style: solid;
      border-radius: 0.2rem;
      background: none;
      &:hover {
        background: none;
      }
      &.button_primary {
        @include button-colors(
          (
            border-color: map-get($primary, primary),
            color: map-get($primary, primary)
          ),
          (
            border-color: map-get($primary, shade10),
            color: map-get($primary, shade10)
          )
        );
      }

      &.button_accent {
        @include button-colors(
          (
            border-color: map-get($success, primary),
            color: map-get($success, primary)
          ),
          (
            border-color: map-get($success, shade10),
            color: map-get($success, shade10)
          )
        );
      }

      &.button_alert {
        @include button-colors(
          (
            border-color: map-get($alert, primary),
            color: map-get($alert, primary)
          ),
          (
            border-color: map-get($alert, shade10),
            color: map-get($alert, shade10)
          )
        );
      }

      &.button_warn {
        @include button-colors(
          (
            border-color: map-get($accent, primary),
            color: map-get($accent, primary)
          ),
          (
            border-color: map-get($accent, shade10),
            color: map-get($accent, shade10)
          )
        );
      }

      &.button_mono {
        @include button-colors(
            (
              border-color: map-get($mono, tint40),
              color: map-get($mono, tint40)
            ),
            (
              border-color: map-get($mono, tint40),
              color: map-get($mono, tint40)
            )
        );
      }

      &.button_basic {
        @include button-colors(
          (
            border-color: map-get($theme, black),
            color: map-get($theme, black)
          )
        );
      }

      &.button_contrast {
        @include button-colors(
          (
            border-color: map-get($theme, white),
            color: map-get($theme, white)
          )
        );
      }

      &:disabled {
        @include button-colors(
          (
            border-color: map-get($mono, tint40),
            color: map-get($mono, tint40)
          )
        );
      }
    }
  }
}

@mixin button-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);

  .button {
    &_primary {
      @include button-colors(
        (
          background: map-get($primary, primary),
          border-color: map-get($primary, primary),
          color: map-get($theme, white)
        ),
        (
          background: map-get($primary, shade10),
          border-color: map-get($primary, shade10),
          color: map-get($theme, white)
        )
      );
    }

    &_accent {
      @include button-colors(
        (
          background: map-get($success, primary),
          border-color: map-get($success, primary),
          color: map-get($theme, white)
        ),
        (
          background: map-get($success, shade10),
          border-color: map-get($success, shade10),
          color: map-get($theme, white)
        )
      );
    }

    &_warn {
      @include button-colors(
        (
          background: map-get($accent, primary),
          border-color: map-get($accent, primary),
          color: map-get($theme, white)
        ),
        (
          background: map-get($accent, shade10),
          border-color: map-get($accent, shade10),
          color: map-get($theme, white)
        )
      );
    }

    &_warn-shade {
      @include button-colors(
        (
          background: map-get($accent, shade45),
          border-color: map-get($accent, shade45),
          color: map-get($theme, white)
        ),
        (
          background: map-get($accent, shade55),
          border-color: map-get($accent, shade55),
          color: map-get($theme, white)
        )
      );
    }

    &_alert {
      @include button-colors(
        (
          background: map-get($alert, primary),
          border-color: map-get($alert, primary),
          color: map-get($theme, white)
        ),
        (
          background: map-get($alert, shade10),
          border-color: map-get($alert, shade10),
          color: map-get($theme, white)
        )
      );
    }

    &_basic {
      @include button-colors((
        background: map-get($theme, black),
        border-color: map-get($theme, black),
        color: map-get($theme, white)
      ));
    }

    &_contrast {
      @include button-colors((
        background: map-get($theme, white),
        border-color: map-get($theme, white),
        color: map-get($theme, black)
      ));
    }

    &_gray {
      color: map-get($theme, white);
      background: map-get($mono, tint20);

      &:hover {
        background: map-get($mono, tint30);
      }
    }

    &:disabled {
      @include button-colors((
        background: map-get($mono, tint05),
        border-color: map-get($mono, tint05),
        color: map-get($mono, tint40)
      ));
    }

    &_flat {
      color: map-get($primary, primary);
    }
  }

  @include accessible {
    .button {
      &:focus {
        text-decoration: underline;
      }
    }
  }
}

@mixin button-arrow-theme($theme) {
  $primary: map-get($theme, primary);
  .button {
    &_arrow {
      &:after {
        border-left-color: map-get($primary, primary);
      }

      &:hover {
        &:after {
          border-left-color: map-get($primary, shade10);
        }
      }
    }
  }
}

@mixin button-typography ($config) {
  $font-family: map-get($config, font-family);
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .button {
    font-family: $font-family;
    font-weight: map-get($font-weight, regular);
    font-size: map-get($font-size, base);

    &_size-sm {
      font-size: map-get($font-size, small);
    }

    &_base-small {
      font-size: map-get($font-size, base-small);
    }

    /*@include tablet {
      &_base-tb {
        font-size: map-get($font-size, base);
      }

      &_small-tb {
        font-size: map-get($font-size, small);
      }
    }*/

    @include mobile {

      &_small-sm {
        font-size: map-get($font-size, small);
      }
      &_bold-sm {
        font-weight: map-get($font-weight, bold);
      }

    }
  }
}

@mixin button-shadow ($shadows) {

  .button {

    &_raised {
      box-shadow: map-get($shadows, shadow);

      &:hover {
        box-shadow: map-get($shadows, hover);
      }

      &:disabled {
        box-shadow: none;
      }
    }
  }
}
