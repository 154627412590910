@import "../../../styles/core/utils/breakpoints";

@mixin see-annotation-theme($theme) {
  $primary: map-get($theme, primary);
  $black: map-get($theme, black);

  .see-annotation {
    &__link-text {
      color: map-get($primary, 'primary');
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .see-annotation {
      &__link-text {
        color: $black;
      }
    }
  }
}

@mixin see-annotation-typography($config) {
  $font-size: map-get($config, font-size);

  .see-annotation {
    &__link {
      font-size: map-get($font-size, smaller);
    }
  }

  /*@include tablet {
    .see-annotation {
      &__link {
        font-size: map-get($font-size, base);
      }
    }
  }*/

  @include mobile {
    .see-annotation {
      &__link {
        font-size: map-get($font-size, small);
      }
    }
  }
}
