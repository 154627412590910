@mixin frmr-certificates-confirmation-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .frmr-certificates-confirmation-loader {
    @include loader-color(map-get($primary, primary));
    @include loader-text-color(map-get($mono, tint50));
  }
}

@mixin frmr-certificates-confirmation-typography($config) {
  $font-weight: map-get($config, font-weight);

  .frmr-certificates-confirmation {
    &__helper-msg-list-item {
      &._highlight {
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
