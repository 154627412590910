/*
 * PALETTE COLORS FOR NEW DESIGN
 * $<theme>-colors-<color_name>
 */
 $light-colors-carbon: (
  primary: #151515,
  tint90:  #2c2c2c,
  tint85:  #2e3235,
  tint80:  #434343,
  tint70:  #5b5b5b,
  tint60:  #727272,
  tint55:  #8d8d8d,
  tint50:  #8a8a8a,
  tint40:  #a1a1a1,
  tint30:  #b8b8b8,
  tint25:  #bec2ca,
  tint20:  #d0d0d0,
  tint16:  #d9dce4,
  tint15:  #dedee1,
  tint10:  #e7e7e7,
  tint08:  #eeeeee,
  tint05:  #f3f3f3,
  tint02:  #f7f7f7,
  tint01:  #fdfdfd
);

$light-colors-blue: (
  tint05:  #fafbfc,
  tint10:  #edf3fa,
  tint15:  #f2f4f7,
  tint20:  #e8eef5,
  tint25:  #e0e8f1,
  tint30:  #d1deec,
  tint35:  #ddf8fe,
  tint40:  #bacee2,
  tint50:  #a3bed9,
  tint60:  #8caecf,
  tint70:  #759ec6,
  tint80:  #5e8ebc,
  tint90:  #467eb3,
  tint95:  #3b71a4,
  tint100: #2f6ea9,
  primary: #195ea0,
  shade10: #165490,
  shade20: #144b80,
  shade25: #0a4d8d,
  shade30: #114170,
  shade40: #0f3860,
  shade49: #0c2f50,
  shade50: #0a2540,
  shade60: #071c30,
  shade70: #051220,
  shade80: #020910,
  shade90: #000505,
);

$light-colors-orange: (
  tint10:  #fef7ec,
  tint20:  #fdf0da,
  tint30:  #fce8c8,
  tint40:  #fbe1b6,
  tint50:  #fbd9a4,
  tint60:  #fad292,
  tint70:  #f9ca80,
  tint80:  #f8c36e,
  tint85:  #ffc460,
  tint90:  #f7bb5c,
  primary: #F7B44A,
  shade10: #dea242,
  shade20: #c5903b,
  shade30: #ac7d33,
  shade40: #946c2c,
  shade45: #ff7700,
  shade50: #7b5a25,
  shade55: #fc9d49,
  shade60: #62481d,
  shade70: #4a3616,
  shade80: #31240e,
  shade90: #181207
);

$light-colors-green: (
  tint10:  #e6f8ec,
  tint20:  #ccf1d9,
  tint25:  #d9f9ca,
  tint30:  #b3e9c6,
  tint40:  #99e2b3,
  tint50:  #80dba0,
  tint60:  #66d48c,
  tint70:  #4dcd79,
  tint75:  #4ec94e,
  tint80:  #33c566,
  tint90:  #1abe53,
  primary: #00B740,
  shade10: #00a53a,
  shade20: #009233,
  shade30: #00802d,
  shade40: #006e26,
  shade50: #005c20,
  shade60: #00491a,
  shade70: #003713,
  shade80: #00250d,
  shade90: #001206
);

$light-colors-pink: (
  tint10:  #fff0f3,
  tint15:  #ffe4cc,
  tint20:  #ffe2e8,
  tint30:  #ffd4dd,
  tint40:  #ffc6d1,
  tint50:  #ffb8c6,
  tint60:  #ffaabb,
  tint70:  #ff9caf,
  tint80:  #ff8ea4,
  tint90:  #ff8099,
  primary: #FF728E,
  shade10: #e5667f,
  shade20: #cc5b71,
  shade30: #b24f63,
  shade40: #994455,
  shade50: #7f3947,
  shade60: #662d38,
  shade70: #4c222a,
  shade80: #33161c,
  shade90: #190b0e,
  bright:  #fb3333
);

$light-colors-black: #000000;
$light-colors-white: #ffffff;

/* CUSTOM COLORS FOR THEME */
$light-colors-lavender: (
  tint10: #fcfdfe,
  tint20: #fafbfd,
  tint25: #f9fafd,
  tint30: #f8f9fc,
  tint40: #f6f8fb,
  tint50: #f4f6fb,
  tint55: #f2f4f9,
  tint60: #f1f4fa,
  tint70: #eff3f9,
  tint80: #edf1f8,
  tint90: #ebeff7,
  primary: #e9eef7
);
