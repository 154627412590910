@import '../figure/figure-base';
@import '../../core/utils/breakpoints';
@import '../../core/utils/z-indices';

.grid {
  $padding-hor: 1rem;
  border-spacing: 0;
  width: 100%;

  &__header-col {
    padding: 1.2rem $padding-hor;
    vertical-align: top;
    text-align: left;

    &_centered {
      text-align: center;
    }

    &_righted {
      text-align: right;
    }

    &_bottom {
      vertical-align: bottom;
    }

    &_middle {
      vertical-align: middle;
    }

    &_sortable {
      white-space: nowrap;
      cursor: pointer;
    }

    &_sorted_asc,
    &_sorted_desc {
      &:after {
        content: "";
        display: inline-block;
        vertical-align: top;
        margin-left: 0.8rem;
        margin-top: 0.2rem;
        @include triangle(0.35rem, bottom, currentColor);
        transition: transform ease .3s;
      }
    }

    &_sorted_asc {
      &:after {
        transform: rotate(-180deg);
      }
    }
  }

  &__row {
    &_no-bordered {
      .grid__column {
        border-top: none;
      }
    }
  }

  &__header-row {
    &_bordered {
      .grid__header-col {
        border-bottom: 0.07143rem solid;
      }
    }

    &_fixed {
      th {
        position: sticky;
        top: 0;
        z-index: $layer2-z-index;
      }
    }

    &_fixed-IE {
      position: fixed;
      top: 0;
      z-index: $layer2-z-index;

      &.grid__header-row_fixed_wizard {
        top: 3.6rem;
      }
    }

    &_fixed_wizard {
      th {
        top: 3.6rem;
      }
    }
  }

  &__header-row_statistics {
    text-align: left;
    &_photo {
      padding: 1rem 0 1rem 0rem;
      width: 3rem;
    }

    &_name {
      padding: 1rem 0 1rem 0rem;
      width: 5rem;
    }

    &_number {
      padding: 1rem 0 1rem 0.5rem;
      width: 2rem;
    }

    &_zet {
      padding: 1rem 0 1rem 0rem;
      width: 3rem;
    }
  }

  &__column {
    padding: 1.857rem $padding-hor;
    border-top: 0.07143rem solid;
    vertical-align: top;
    text-align: left;
    max-width: 20rem;
    line-height: 1.3;
    overflow-wrap: break-word;
    -moz-hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;

    &_hide {
      display: none;
    }

    &_centered {
      text-align: center;
    }

    &_righted {
      text-align: right;
    }

    &_bottom {
      vertical-align: bottom;
    }

    &_middle {
      vertical-align: middle;
    }

    &_no-pad-vert {
      padding-top: 0;
      padding-bottom: 0;
    }

    &_less {
      padding-right: 0.3rem;
      padding-left: 0.5rem;
    }
  }

  &_hide {
    display: none;
  }

  &__column-card {
    display: none;
  }

  /* search screen modifiers */
  &_theme_search {
    .grid__column {
      &_name {
        min-width: 3rem;
      }

      &_partition {
        min-width: 2rem;
      }

      &_organization {
        min-width: 3rem;
      }

      &_region {
        min-width: 2rem;
      }

      &_reviewerOrganistaionsName {
        min-width: 4rem;
      }

      &_form {
        min-width: 2rem;
      }

      &_eduTechnology {
        min-width: 2rem;
      }

      &_dateCycleRange {
        min-width: 2rem;
      }

      &_specialityName {
        min-width: 2rem;
      }

      &_chargeTypePaid,
      &_chargeTypeBudget {
        min-width: 2rem;
      }

      &_price {
        min-width: 2rem;
        white-space: nowrap;
      }

      &_zet {
        min-width: 2rem;
        text-align: left;
      }

      &_charge-type {
        min-width: 2rem;
      }

      &_type {
        min-width: 2rem;
      }

      &_view {
        min-width: 2rem;
      }
    }
  }
  /* end - search screen modifiers */
  /* cycles screen modifiers */

  /* end - cycles screen modifiers */
  /* portfolio screen modifiers */
  &_theme_portfolio {
    table-layout: fixed;

    .grid__header-col {
      &_date {
        width: 8%;
      }
      &_name,
      &_specialty,
      &_organization {
        width: 15%;
      }
      &_form {
        width: 7%;
      }
      &_type {
        width: 15%;
      }
      &_basis {
        width: 9%;
      }
      &_download {
        width: 5%;
      }
      &_zet {
        width: 4%;
      }
      &_feedback {
        width: 5%;
      }
      &_likes {
        width: 6%;
      }
      &_currentRating {
        width: 6%;
      }
    }
  }
  /* end - portfolio screen modifiers */
  /* included topic modifiers */
  &_theme_included_topic {
    height: 100%;
  }
  /* end - included topic modifiers */

  /* profile/statistics screen modifiers */

  /* end - profile/statistics screen modifiers */

}

$item-margin: 0.714285714285714rem;

@mixin grid-tablet-mobile {
    display: block;
    height: auto;

    tbody{
      display: block;
      margin: -$item-margin;
      text-align: center;
    }

    //items with card
    .grid__row {
      display: inline-block;
      vertical-align: top;
      padding: $item-margin;
      text-align: left;
      width: 50%;
    }

    //hide usual columns in card
    .grid__header-col_hide-in-card,
    .grid__column_hide-in-card {
      display: none;
    }
    //view for card column
    .grid__column-card {
      display: block;
      padding: 1.42857143rem;
      border-radius: 0.28571429rem;
    }
}

/*@include tablet {
  .grid {
    &_theme_search {
      .grid__header-col {
        display: none;
      }
      .grid__column {
        display: none;
      }
      .grid__column-card {
        display: block;
        padding: 1.42857143rem;
        border-radius: 0.28571429rem;
      }
      .grid__row {
        display: inline-block;
        vertical-align: top;
        padding: $item-margin;
        text-align: left;
        width: 50%;
      }
    }
  }
}*/

//TODO rename to 'mobile' when all tablet version will be done
@include mobile {
  .grid {

    &_mode_card {
      @include grid-tablet-mobile();

      .grid__row {
        width: 100%;
      }
    }

    &_theme_cycles {
      table-layout: fixed;
    }
  }
}

// TODO tablet version
/*
@include tablet {
  .grid {

    &_mode_card {
      @include grid-tablet-mobile();
    }
  }
}
*/

@include accessible {
  .grid {

    &_mode_card {

      tbody {
        margin: 0;
      }

      .grid__row {
        border: 0.1rem solid;
        border-radius: 0.14814814814rem;
        margin-bottom: 1.666rem;
      }
    }
  }
}
