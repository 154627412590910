@import '../../../styles/core/utils/breakpoints';

$bp-sm: $bp-max-mobile;
$bp-tb: $bp-max-tablet;

@mixin text-theme($theme) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);
  $black: map-get($theme, black);
  $white: map-get($theme, white);

  .text {
    color: $black;

    &_primary {
      color: map-get($primary, primary);
    }

    &_primary-mono {
      color: map-get($mono, primary);
    }

    &_primary-light {
      color: map-get($primary, shade10);
    }

    &_primary-dark {
      color: map-get($primary, shade40);
    }

    &_alert {
      color: map-get($alert, primary);
    }

    &_alert-dark {
      color: map-get($alert, shade10);
    }

    &_accent {
      color: map-get($accent, primary);
    }

    &_mono-dark {
      color: map-get($mono, tint70);
    }

    &_mono-light {
      color: map-get($mono, tint40);
    }

    &_contrast {
      color: $white;
    }

    &_muted-dark {
      color: map-get($mono, tint90);
    }

    &_muted {
      color: map-get($mono, tint70);
    }

    &_muted-light {
      color: map-get($mono, tint50);
    }

    &_muted-lighter {
      color: map-get($mono, tint40);
    }

    &_muted-lightest {
      color: map-get($mono, tint15);
    }

    &_baselight {
      color: map_get($mono, basic);
    }

    &_baselight-80 {
      color: map_get($mono, tint60);
    }

    &_success {
      color: map_get($success, primary);
    }
  }
}

@mixin text-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);
  $font-style: map-get($config, font-style);

  .text {
    font-family: map-get($config, font-family);
    font-size: 1rem;
    font-weight: map-get($font-weight, regular);

    &_h0 {
      font-size: map-get($font-size, h0);
    }

    &_h1 {
      font-size: map-get($font-size, h1);
    }

    &_h1-mobile {
      font-size: map-get($font-size, h1-mobile);
    }

    &_h2 {
      font-size: map-get($font-size, h2);
    }

    &_h2-mobile {
      font-size: map-get($font-size, h2-mobile);
    }

    &_h3 {
      font-size: map-get($font-size, h3);
    }

    &_bigger {
      font-size: map-get($font-size, bigger);
    }

    &_big {
      font-size: map-get($font-size, big);
    }

    &_base {
      font-size: map-get($font-size, base);
    }

    &_base-small {
      font-size: map-get($font-size, base-small);
    }

    &_small {
      font-size: map-get($font-size, small);
    }

    &_smaller {
      font-size: map-get($font-size, smaller);
    }

    &_tiny {
      font-size: map-get($font-size, tiny);
    }

    &_light {
      font-weight: map-get($font-weight, light);
    }

    &_regular {
      font-weight: map-get($font-weight, regular);
    }

    &_medium {
      font-weight: map-get($font-weight, medium);
    }

    &_bold {
      font-weight: map-get($font-weight, bold);
    }

    &_bolder {
      font-weight: map-get($font-weight, bolder);
    }

    &_italic {
      font-style: map-get($font-style, italic);
    }
  }

  /*@include tablet {
    .text {

      &_base-tb {
        font-size: map-get($font-size, base);
      }

      &_base-small-tb {
        font-size: map-get($font-size, base-small);
      }

      &_small-tb {
        font-size: map-get($font-size, small);
      }

      &_tiny-tb {
        font-size: map-get($font-size, tiny);
      }

      &_regular-tb {
        font-weight: map-get($font-weight, regular);
      }

    }
  }*/

  @include mobile {
    .text {

      &_base-sm {
        font-size: map-get($font-size, base);
      }

      &_small-sm {
        font-size: map-get($font-size, small);
      }

      &_tiny-sm {
        font-size: map-get($font-size, tiny);
      }

      &_regular-sm {
        font-weight: map-get($font-weight, regular);
      }

      &_bold-sm {
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}

.text {
  &_left {
    text-align: left;
  }
}
