@import "../../../styles/core/utils/breakpoints";
@mixin dpp-pk-cycles-theme($theme) {
  $white: map-get($theme, white);
  $primary: map-get($theme, primary);

  .dpp-pk-cycles {
    &__loader {
      background-color: $white;
    }
    &__star {
      &:after {
        color: map-get($primary, shade40);
      }
    }
  }
}

@mixin dpp-pk-cycles-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .dpp-pk-cycles {

    &__item-popover-content {
      font-size: map-get($font-size, small);
    }

    &__star {
      &:after {
        font-size: map-get($font-size, big);
      }
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .dpp-pk-cycles {

      &__warning {
        font-size: map-get($font-size, tiny);
      }

      &__item-popover-col:first-of-type {
        font-weight: map-get($font-weight, bolder);
      }

      &__star {
        font-size: map-get($font-size, small);
      }
    }

    .dpp-pk-popup-title {
      font-size: map-get($font-size, small);
    }
  }
}
