@import "../../../styles/core/utils/breakpoints";

@mixin dropdown-theme($theme, $custom) {
  $white: map-get($theme, white);

  .dropdown {
    &__content {
      background-color: $white;
    }
  }
}


@mixin dropdown-shadow($shadows) {
  $dropdown: map-get($shadows, dropdown);

  .dropdown {
    &__content {
      box-shadow: $dropdown;
    }
  }
}
