@import "../../../styles/core/utils/breakpoints";

@mixin edu-specifics-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  /*@include tablet {
    .edu-specifics {
      &__section-title {
        font-weight: map-get($font-weight, regular);
      }
    }
  }*/

  @include mobile {
    .edu-specifics {

      &__section-title {
        font-size: map-get($font-size, small);
      }

      &__section-subtitle {
        font-size: map-get($font-size, small);
      }

      &__description {
        font-size: map-get($font-size, small);
      }
    }
  }
}
