@import "../../core/utils/breakpoints";

.topics {
  margin: 0.15rem 0 0 2.7143rem;

  &_margin {
    margin-top: 2rem;
  }

  &__section-icon {
    top: -0.14286rem;
  }

  &__title {
    line-height: 1.2143rem;
    padding-top: 0.1786rem;
    margin-bottom: 2rem;
    position: relative;

    &:empty {
      display: none;
    }
  }

  &__element-text {
    width: 100%;
    display: table-cell;
    padding-top: 1.61286rem;
  }

  &__element-info-panel {
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-top: 1.61286rem;
    min-width: 23.5rem;
  }

  &__element-info-panel-cell {
    display: inline-block;
    position: relative;
    width: 6.4285rem;

    &_big {
      width: 8.5rem;
    }

    &_biger {
      width: 8.5rem;
    }

    &_small {
      width: 4.5rem;
    }

    &_smaller {
      width: 4rem;
    }
  }

  &__button {
    margin-top: 1.61286rem;
  }

  &__show-more {
    margin-top: 1.61286rem;
  }

  &__icon {
    position: absolute;
    transform: translateY(-10%) translateX(-120%);

    &_closer {
      transform: translateY(-10%) translateX(-101%);
    }
  }

  &__message {
    padding-top: 1.571428rem;
  }

  &__results {
    margin: 1.428571428571429rem 0 1rem 0.1714285714285714rem;
  }

  &__popover-button {
    vertical-align: baseline;
    text-align: left;
  }
}

/*@include tablet {
  .topics {
    margin: 0;

    &__title {
      &_plan {
        margin-left: 2.7143rem;
        margin-bottom: 1.7rem;
      }
    }

    &__show-more {
      margin-left: 2.714285714285714rem;
    }
  }
}*/

@include mobile {
  .topics {
    margin-left: 0;
    margin-top: 0;

    &__message {
      margin-right: 1rem;
      margin-left: 0;
    }

    &__title {
      padding-top: 0;
      margin-bottom: 1rem;
    }

    &__element-text {
      display: block;
    }

    &__element-info-panel {
      display: block;
      text-align: left;
      padding-top: 0.61286rem;
      min-width: 17.5rem;
    }

    &__show-more {
      margin-left: 1.142857142857143rem;
      margin-bottom: 1rem;
    }

    &__element-info-panel-cell {
      text-align: center;

      &_align-left {
        text-align: left;
      }

      &_biger {
        text-align: left;
        padding-left: 0.714rem;
        width: 10.9rem;
      }

      &_big {
        text-align: left;
        padding-left: 1rem;
        width: 4.5rem;
      }

      &_hide {
        display: none;
      }

      &_small {
        margin-left: -0.714rem;
      }

      &_sm_width_auto {
        width: auto;
      }

      &_sm_pad_small {
        margin-left: .2rem;
      }

      &_sm_pad_big {
        margin-left: 2rem;
      }
    }

    &__button-test {
      margin-left: -0.5714rem;
    }
  }
}
