@import "../../styles/core/utils/breakpoints";

@mixin registration-form-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $white: map-get($theme, white);

  .registration-wrapper {
    background-color: map-get($custom, root-bg);
  }

  .registration {
    &__form {
      background-color: $white;
    }
  }

  /*@include tablet {
     .registration {
       .iam-screen {
         background: $white;
       }
       &__form {
         background: none;
       }
     }
  }*/

  @include mobile {
    .registration {
      &__form {
        background: none;
      }
    }
  }

  .registration-form-loader {
    @include loader-color(map-get($primary, primary));
  }
}

@mixin registration-form-shadow($shadows) {
  .registration {
    &__form {
      box-shadow: map-get($shadows, modal-light);
    }
  }

  /*@include tablet {
     .registration {
       .iam-screen {
         box-shadow: map-get($shadows, modal-light);
       }
       &__form {
         box-shadow: none;
       }
     }
  }*/

  @include mobile {
    .registration {
      &__form {
        box-shadow: none;
      }
    }
  }
}
