@import "src/styles/core/utils/breakpoints";

@mixin documents-certificate-theme($theme, $custom) {
  $success: map-get($theme, success);
  $mono: map-get($theme, mono);
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);

  .user-account-profile-certificate {

    &_main {
      border-left-color: map-get($primary, primary) !important;
    }

    &_is-not-current-education-level {
      background-color: map-get($accent, tint30);
    }

    &_lock {
      background: map-get($mono, tint15);
    }

    &__frmr-confirmation-status {
      &_confirm {
        color: map-get($success, primary);
      }
    }
    &__my-confirmation {
      color: map-get($mono, tint85);

      @include mobile {
        color: map-get($primary, primary);
      }
    }

    &__figure-checkmark {
      color: map-get($success, primary);
    }

    &__figure-cross {
      &:after, &:before {
        background-color: map-get($primary, primary);
      }
    }

    &__icon-remove {
      &:after, &:before {
        background-color: map-get($mono, tint50);
      }

      &:hover {
        &:after, &:before {
          background-color: map-get($mono, tint85);
        }
      }
    }
  }

  @include accessible {
    .user-account-profile-certificate {
      &__icon-remove {
        &:focus {
          outline: 0.1rem solid currentColor;
        }
      }
    }
  }

  @include mobile {
    .user-account-profile-certificate {
      &__button {
        &:disabled {
          background: none;
        }
      }
    }
  }
}

@mixin documents-certificate-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .user-account-profile-certificate {
    &__value {
      font-size: map-get($font-size, big);
    }

    &__subtitle {
      font-size: map-get($font-size, base);
    }

    &__header {
      font-size: map-get($font-size, big);
    }

    @include mobile {
      &__value {
        font-weight: map-get($font-weight, light);
        font-size: map-get($font-size, base);
      }

      &__header {
        font-size: map-get($font-size, base);
      }
    }

  }
}
