@mixin figure-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $success: map-get($theme, success);
  $alert: map-get($theme, alert);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $black: map-get($theme, black);

  $edu-element-status: map-get($custom, edu-element);
  $no-access-status: map-get($edu-element-status, no-access-status);

  .figure {
    &_chevron-left {
      border-color: $white;
      &_primary {
        border-color: map-get($primary, primary);
      }
    }

    &_help {
      border-color: map-get($primary, primary);

      &:before {
        color: map-get($primary, primary);
      }
    }

    &_circle {
      border-color: map-get($accent, primary);
      background-color: map-get($accent, primary);

      &_green {
        border-color: map-get($success, primary);
        background-color: map-get($success, primary);
      }

      &_primary {
        border-color: map-get($primary, primary);
        background-color: map-get($primary, primary);
      }

      &_mono {
        border-color: map-get($mono, tint50);
        background-color: map-get($mono, tint50);
      }

      &_success {
        border-color: map-get($success, primary);
        background-color: map-get($success, primary);
      }

      &_accent {
        border-color: map-get($accent, primary);
        background-color: map-get($accent, primary);
      }

      &_no-access {
        border-color: $no-access-status;
        background-color: $no-access-status;
      }
    }

    &_checkmark {
      border-color: map-get($success, primary);
    }

    &_checkmark-small {
      border-color: map-get($success, primary);
    }

    &_dash {
      &_green {
        background: map-get($success, primary);
      }

      &_red {
        background: map-get($alert, primary);
      }
    }

    &_plus {
      &.figure_primary {
        background-color: map-get($primary, primary);
        &:before {
          border-color: $white;
        }
        &:after {
          border-color: $white;
        }
      }
      //it is necessary for usual and disabled button state
      &.figure_current_lines {
        &:before, &:after {
          border-color: currentColor;
        }
      }

      &.figure_contrast {
        background-color: $white;
        &:before {
          border-color: map-get($accent, primary);
        }
        &:after {
          border-color: map-get($accent, primary);
        }
      }
    }

    &_minus {
      &.figure_basic {
        background-color: $white;
        &:before {
          border-color: $black;
        }
        &:after {
          border-color: $black;
        }
      }
    }

    &_more {
      color: map-get($primary, primary);
    }

    &_close, &_close-sm, &_close-xsm {
      color: map-get($mono, tint50);

      &:hover {
        color: map-get($mono, tint80);
      }

      &:before,
      &:after {
        background-color: currentColor;
      }

      &_primary {
        &:before,
        &:after {
          background-color: map-get($primary, primary);
        }

        &:hover {
          &:before,
          &:after {
            background-color: map-get($primary, tint90);
          }
        }
      }

      &_contrast {
        color: $white;
      }
    }

    &_cross {
      &:before, &:after {
        background-color: map-get($mono, tint40);
      }
    }

    &_cross-big {
      &:before, &:after {
        background-color: map-get($mono, tint40);
      }

      &_alert {
        &:before, &:after {
          background-color: map-get($alert, primary);
        }
      }
    }

    &_primary {
      color: map-get($primary, primary);
    }
  }
}
