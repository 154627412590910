@mixin text-field-theme($theme) {
  $alert: map-get($theme, alert);
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .text-field {
    background: $white;
    color: map-get($mono, primary);
    border-color: map-get($mono, tint15);

    &::placeholder,
    &_placeholder {
      color: map-get($mono, tint30);
    }

    &:-ms-input-placeholder {
      color: map-get($mono, tint30);
    }

    &:focus, &__active {
      background: $white;
      border-color: map-get($primary, primary);
    }

    &.disabled, &[disabled] {
      &:not(.text-field_ignore-disabled) {
        border-color: map-get($mono, tint15);
        color: map-get($mono, tint50);
      }
    }

    &.ng-invalid.ng-dirty {
      background-color: map-get($alert, tint10);

      &::placeholder {
        color: map-get($mono, tint30);
      }
    }

    &_transparent {
      &, &:focus, &__active {
        background: none;
      }
    }
  }
}


@mixin text-field-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .text-field {
    font-family: map-get($config, font-family);
    font-size: map-get($font-size, big);
    font-weight: map-get($font-weight, normal);

    &__big-text {
      font-size: 1.9rem;
    }
  }
}

