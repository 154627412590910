@import "../../../styles/core/utils/breakpoints";

@mixin edu-element-view-theme($theme) {
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);
  $primary: map-get($theme, primary);

  .edu-element {
    background-color: $white;

    &__item {
      border-left-color: map-get($mono, tint20);
    }

    &__popup-header-title {
      color: map_get($mono, tint30);
    }

    &__popup-header-subtitle {
      color: map_get($mono, tint85);
    }

    &__go-back {
      color: map-get($primary, primary);
    }
  }

  .edu-element-plate-not-mobile {
    background: $white;
  }

  /*@include tablet {
    .edu-element {
      &__grid-item {
        &_order-2 {
          border-left-color: map-get($mono, tint20);
        }
      }
    }
  }*/

  @include mobile {
    .edu-element {
      background: none;

      &__background {
        background-color: $white;
      }
    }

    .edu-element-plate-not-mobile {
      background: none;
    }

    .edu-element-plate-mobile {
      background: $white;
    }
  }
}

@mixin edu-element-view-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .edu-element {
    &__go-back {
      font-weight: map_get($font-weight, bold);
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-element {

      &__go-back {
        font-size: map-get($font-size, small);
      }
    }
  }
}

@mixin edu-element-view-shadow($shadows) {
  .edu-element {
    box-shadow: map-get($shadows, popover-tight);
  }

  .edu-element-plate-not-mobile {
    box-shadow: map-get($shadows, popover-tight);
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {

    .edu-element {
      box-shadow: none;
    }

    .edu-element-plate-not-mobile {
      box-shadow: none;
    }

    .edu-element-plate-mobile {
      box-shadow: map-get($shadows, popover-tight);
    }
  }
}
