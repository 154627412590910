$light-colors-blue: (
  50:  #fafbfc,
  100:  #e8eef5,
  200:  #bacee2,
  300:  #759ec6,
  400:  #5e8ebc,
  500: #195ea0,
  600: #144b80,
  700: #114170,
  800: #071c30,
  900: #000505,
  A100 : #a3c1ff,
  A200 : #709fff,
  A400 : #3d7cff,
  A700 : #246bff,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-colors-orange: (
  100:  #fef7ec,
  150:  #fdf0da,
  200:  #fce8c8,
  250:  #fbe1b6,
  300:  #fbd9a4,
  350:  #fad292,
  400:  #f9ca80,
  450:  #f8c36e,
  460:  #ffc460,
  470:  #f7bb5c,
  500: #F7B44A,
  650: #dea242,
  700: #c5903b,
  750: #ac7d33,
  800: #946c2c,
  850: #ff7700,
  900: #7b5a25,
  950: #fc9d49,
  1000: #62481d,
  1050: #4a3616,
  1100: #31240e,
  1150: #181207,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-colors-green: (
  50:  #e6f8ec,
  100:  #ccf1d9,
  150:  #d9f9ca,
  200:  #b3e9c6,
  250:  #99e2b3,
  300:  #80dba0,
  350:  #66d48c,
  400:  #4dcd79,
  450:  #4ec94e,
  460:  #33c566,
  470:  #1abe53,
  500: #00B740,
  550: #00a53a,
  600: #009233,
  650: #00802d,
  700: #006e26,
  750: #005c20,
  800: #00491a,
  850: #003713,
  900: #00250d,
  950: #001206,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-colors-pink: (
  50:  #fff0f3,
  100:  #ffe4cc,
  150:  #ffe2e8,
  200:  #ffd4dd,
  250:  #ffc6d1,
  300:  #ffb8c6,
  350:  #ffaabb,
  400:  #ff9caf,
  450:  #ff8ea4,
  470:  #ff8099,
  500: #FF728E,
  550: #e5667f,
  600: #cc5b71,
  650: #b24f63,
  700: #994455,
  750: #7f3947,
  800: #662d38,
  850: #4c222a,
  900: #33161c,
  950: #190b0e,
  bright:  #fb3333,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #000000,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$light-colors-black: #000000;
$light-colors-white: #ffffff;

/* CUSTOM COLORS FOR THEME */
$light-colors-carbon:  (
  50 : #e0e0e0,
  100 : #b3b3b3,
  200 : #808080,
  300 : #4d4d4d,
  400 : #262626,
  500 : #000000,
  600 : #000000,
  700 : #000000,
  800 : #000000,
  900 : #000000,
  A100 : #a6a6a6,
  A200 : #8c8c8c,
  A400 : #737373,
  A700 : #666666,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);
