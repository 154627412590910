@import "../../../../styles/core/utils/breakpoints";

@mixin baseselect-theme($theme) {
  @include accessible {
    .select {
      &__filter-clear-button {
        &:focus {
          background: map-get($theme, black);

          .figure {
            color: map-get($theme, white);
          }
        }
      }
    }
  }
}
