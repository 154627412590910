@import "../../core/utils/breakpoints";
@import '../../core/utils/z-indices';

$border-width-arrow: 1.214285714285714rem;

div.hopscotch-bubble {
  border-radius: 0.6428571428571429rem;
  border-width: 0.3571rem;

  & .hopscotch-bubble-number {
    display: none;
  }

  & .hopscotch-bubble-container {
    border-radius: 0.2857142857142857rem;
    padding: 1.071428571428571rem;
    @media (max-width: 767px) {
      max-width: 14.5rem;
    }
    @media (min-width: 1820px) {
      min-width: 18rem;
    }
    @media (min-width: 2500px) {
      min-width: 20rem;
    }
  }

  & .hopscotch-content {
    line-height: 1.2;
    margin: -0.357142857142857rem 0 0.785714285714286rem;
    padding-top: 0.571428571428571rem;
  }

  & .hopscotch-bubble-number {
    background-image: none;
    border-radius: 1.2143rem;
    padding-right: 0;
    line-height: 2.214285714285714rem;
    width: 2.142857142857143rem;
    height: 2.142857142857143rem;
  }
  & .hopscotch-bubble-content{
    margin-left: 0;
  }

  .hopscotch-bubble-close {
    background: none;
    box-sizing: border-box;
    cursor:     pointer;
    margin:     0.5rem;
    padding:    0;

    height:     .714285714285714rem;
    width:      .714285714285714rem;

    &:before, &:after {
      content:    "";
      margin-top: -.035714285714286rem;
      height:     .071428571428571rem;

      position:   absolute;
      top:        50%;
      left:       0;
      right:      0;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }
  }


  & .hopscotch-bubble-arrow-container {
    width: 2.428571428571429rem;
    height: 2.428571428571429rem;
    &.down {
      bottom: -2.785714285714286rem;
      left: 0.714285714285714rem;
      & .hopscotch-bubble-arrow {
        border-top-width: $border-width-arrow;
        border-left-width: $border-width-arrow;
        border-right-width: $border-width-arrow;
        top: -1.714285714285714rem;
      }
      & .hopscotch-bubble-arrow-border {
        border-top-width: $border-width-arrow;
        border-left-width: $border-width-arrow;
        border-right-width: $border-width-arrow;
      }
    }
    &.up {
      top: -1.571428571428571rem;
      left: 0.714285714285714rem;
      & .hopscotch-bubble-arrow {
        border-bottom-width: $border-width-arrow;
        border-left-width: $border-width-arrow;
        border-right-width: $border-width-arrow;
        top: -0.714285714285714rem;
      }
      & .hopscotch-bubble-arrow-border {
        border-bottom-width: $border-width-arrow;
        border-left-width: $border-width-arrow;
        border-right-width: $border-width-arrow;
      }
    }
    &.left {
      top: 0.714285714285714rem;
      left: -1.571428571428571rem;
      & .hopscotch-bubble-arrow {
        border-bottom-width: $border-width-arrow;
        border-right-width: $border-width-arrow;
        border-top-width: $border-width-arrow;
        left: 0.5rem;
        top: -2.428571428571429rem;
      }
      .hopscotch-bubble-arrow-border {
        border-right-width: $border-width-arrow;
        border-bottom-width: $border-width-arrow;
        border-top-width: $border-width-arrow;
      }
    }
    &.right {
      top: 0.714285714285714rem;
      right: -2.785714285714286rem;
      & .hopscotch-bubble-arrow {
        border-bottom-width: $border-width-arrow;
        border-left-width: $border-width-arrow;
        border-top-width: $border-width-arrow;
        left: -0.5rem;
        top: -2.428571428571429rem;
      }
      & .hopscotch-bubble-arrow-border {
        border-left-width: $border-width-arrow;
        border-bottom-width: $border-width-arrow;
        border-top-width: $border-width-arrow;
      }
    }
  }

  & .hopscotch-actions {
    margin: 0.714285714285714rem 0 0;
  }

  & .hopscotch-nav-button {
    padding: 0 0.714285714285714rem;
    height: 1.857142857142857rem;
    line-height: 1.714285714285714rem;
    border-radius: 0.214285714285714rem;
    outline: none;

    &.prev {
      background-image: none;
      border-width: 0.07143rem;
      text-shadow: none;
      &:hover {
        background: none;
        box-shadow: none;
      }
    }
    &.next {
      background-image: none;
      border: none;
      text-shadow: none;
      margin-left: 0.714285714285714rem;

      &:hover {
        background-image: none;
        border: none;
        text-shadow: none;
        transition: all .3s ease;
      }
    }
  }
}

.virtual-tour-highlight {
  position: absolute;
  pointer-events: none;
  box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.5);
  border-radius: 0.2857142857142857rem;
  z-index: $virtual-tour-z-index;
  transition: all 0.2s ease;
}

@include mobile {
  div.hopscotch-bubble {
    .hopscotch-bubble-close {
      border-radius: 50%;
      width: 1.5rem;
      height: 1.5rem;

      &:before, &:after {
        left:  .35714286rem;
        right: .35714286rem;
      }
    }
  }
}

@include accessible {
  .hopscotch-bubble-close {
    &:focus {
      outline: 0.1rem solid currentColor;
    }
  }

  .hopscotch-content {
    padding-right: 0.5rem;
  }

  .hopscotch-nav-button {
    &:focus {
      text-decoration: underline !important;
    }
  }
}
