@import '../../../styles/core/utils/breakpoints';

@mixin select-edu-rule-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-el-add {

      &__header-subtitle {
        font-size: map-get($font-size, small);
      }

      &__confirmation-heading {
        font-size: map-get($font-size, big);
      }
    }
  }
}
