@mixin tag-color($name, $color) {
  .tag_#{$name} {
    border-color: $color;
    color: $color;
  }

  .tag_active {
    background: $color;
  }
}

@mixin tag-theme($theme) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);

  @include tag-color(primary, map-get($primary, primary));

  .tag {
    &_active {
      color: $white;
    }
  }

}

@mixin tag-typography($config) {
  $font-family: map-get($config, font-family);
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .tag {
    font-family: $font-family;
    font-size: map-get($font-size, small);
    font-weight: map-get($font-weight, light);
  }
}
