@mixin accessible-version-theme($theme) {
  .accessible-version {
    color: map-get($theme, black);
    background: map-get($theme, white);

    a:focus {
      outline: 0.1rem solid map-get($theme, black);
    }
  }
}
