// This file used not only in the component edu-trajectory-topic
@import "../../core/utils/breakpoints";

.edu-trajectory-topic {
  margin-top: 2rem;

  &__mobile {
    display: none;
  }

  &__column {
    padding-bottom: 1rem;
    padding-top: 1rem;

    &_source-container {
      padding: 1rem 0.5714285714285714rem 1rem 0.7857142857142857rem;
      text-align: center;
      width: 1%;
    }

    &_title-container {
      width: 40%;

      .text {
        max-width: 30rem;
      }
    }

    &_no-source-icon {
      padding-left: 2.5rem;
    }

    &_btn-add-container {
      padding: 0 1rem;
      width: 20%;
    }

    &_test-status-container {
      width: 29%;
    }

    &_rewards-container {
      text-align: center;
      width: 10%;
      white-space: nowrap;
    }
  }

  &__icon {
    bottom: 0.1429rem;
  }

  &__test-status-text {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  }

  &__button {
    text-align: left;
  }

  &__item-title {
    margin: 0 0.4rem 0 0;
  }

  .grid__column {
    border: none;
  }
}

@mixin edu-trajectory-topic-tablet-mobile {

  &__test-status-text {
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  }
}

/*@include tablet {
  .edu-trajectory-topic {
    @include edu-trajectory-topic-tablet-mobile();

    &__column {
      &_source-container {
        display: none;
      }

      &_title-container {
        padding-left: 2.714285714285714rem;
        width: 34%;
      }

      &_test-status-container {
        padding-right: 1.428571428571429rem;
      }

      &_rewards-container {
        width: 14%;
      }
    }
  }
}*/

@include mobile {
  .edu-trajectory-topic {
    @include edu-trajectory-topic-tablet-mobile();

    &__desktop-tablet {
      display: none;
    }

    &__mobile {
      display: block;
    }
  }
}



