/* Light theme shadows */
$shadows: (
  accordion: 0.05rem 0.1rem 0.3rem rgba(0, 0, 0, 0.1),
  assessment-card: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.15),
  award-tooltip: 0 0.15rem 0.3rem 0.15rem rgba(222, 222, 222, 0.5),
  chart-card: 0 0.14285714285714285rem 0.2857142857142857rem rgba(0, 0, 0, .3),
  container: 0 0.07143rem 0.2857rem 0.07143rem rgba(0,0,0,0.2),
  card-add-edu-element: 0 1rem 3rem 0 rgba(164, 195, 255, 0.5),
  dropdown: 0 0.07143rem 0.2143rem 0.07143rem rgba(0,0,0,.2),
  drop-shadow: 0 0.1429em 0.07143em rgba(0, 0, 0, .2),
  grid-hover: 0 0.5rem 1rem rgba(0, 0, 0, .3),
  popover: 0 0.07143rem 0.2143rem 0.07143rem rgba(0,0,0,.2),
  popover-tight: 0 0.1429rem 0.2857rem 0 rgba(165, 165, 165, 0.55),
  psy-test-card: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1),
  progress-bar: 0 0 0.07142857142rem 0.35714285714rem rgba(255, 119, 0, 0.4),
  modal-light: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.5),
  shadow: 0 0.7143rem 2.1429rem 0 rgb(164, 195, 255),
  sidebar: 0 0.57142857142rem 1.42857142857rem 0 rgba(223, 226, 233, 0.8),
  hover: 0 0.7143rem 2.1429rem 0 rgb(164, 195, 255),
  tooltip: 0 0.142857142857143rem 0.285714285714286rem 0 rgba(182, 182, 182, 0.51),
  tint1015: 0 10px 15px 0 rgb(223, 226, 233),
  read-more-shadow: 0 -7px 15px rgba(0, 0, 0, 0.4),
  portfolio-shadow: 0 0.143rem 0.2857rem 0 rgba(165, 165, 165, 0.51),
  activity-selection: 0 0.3571rem 0.7143rem rgba(121, 120, 120, 0.5),
  edu-section-card-wide: 0 2.857rem 4.357rem 0 rgba(223, 226, 233, 0.5),
  edu-section-card-wide-tablet: 0 0.714rem 1.071rem 0 #dfe2e9,
  edu-section-card-wide-mobile: 0 0.142857142857143rem 0.285714285714286rem 0 #dfe2e9,
  included-topic: 0 0.5rem 0.5rem rgba(0, 0, 0, .3),
  header-shadow: 0 0.142857142857143rem 0.285714285714286rem 0 rgba(178, 178, 178, 0.5),
  header-shadow-top: 0 0.142857142857143rem 0.285714285714286rem 0 rgba(178, 178, 178, 0.2),
);
