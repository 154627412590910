@import "figure-base";

.figure {
  display: inline-block;

  &_chevron-left {
    @include chevron-left-figure(1rem);
  }

  &_help {
    @include help-figure(1rem);
  }

  &_circle {
    @include circle-figure(0.6429rem);
  }

  &_circle-small {
    @include circle-figure(0.45rem);
  }

  &_download {
    @include download-figure(1rem);
  }

  &_checkmark {
    @include checkmark-figure(1.5rem, 0.857rem, 0.214rem);
  }

  &_checkmark-small {
    @include checkmark-figure(0.75rem, 0.5rem, 0.15rem);
  }

  &_dash {
    @include dash-figure(1.428571428571429rem, 0.285714285714286rem);
  }

  &_checkmark-arris {
    @include checkmark-arris-figure(0.7rem, 0.5rem, 0.1429rem, 0.1429rem);
  }

  &_checkmark-arris-double {
    @include checkmark-arris-figure(0.7rem, 0.5rem, 0.1429rem, 0.1429rem);
    @include checkmark-arris-figure-double(0.7rem, 0.5rem, 0.1429rem, 0.1429rem);
  }

  &_plus {
    @include plus-figure();
  }

  &_minus {
    @include minus-figure();
  }

  &_add {
    @include add(8px, 2px);
  }

  &_more {
    @include more-figure(0.4286rem);
  }

  &_arrow-bottom {
    @include arrow(1rem, 45deg);
  }

  &_arrow-top {
    @include arrow(1rem, -135deg);
  }

  &_lines {
    @include lines-figure(1.571428571428571rem, 0.214285714285714rem);
    transition: transform 0.1s;
    &_cross {
      transform: rotate(45deg);
      &:before {
        opacity: 0;
      }
      &:after {
        bottom: 0;
        transform: rotate(90deg);
      }
    }
  }

  &_close {
    @include close-figure(0.8571rem);
  }

  &_close-sm {
    @include close-figure(0.714285714285714rem);
  }

  &_close-xsm {
    @include close-figure(0.5rem);
  }

  &_cross {
    @include cross-figure(0.5rem);
  }

  &_cross-big {
    @include cross-figure(0.85rem);
  }

  &_back {
    @include back(0.857142857142857rem, 0.857142857142857rem);
  }
}
