@mixin popup-theme($theme) {
  $primary: map-get($theme, primary);

  .popup {

    &__container {

      &_accent {
        border-top-color: map-get($primary, primary);
      }
    }
  }
}
