@import '../../../styles/core/utils/breakpoints';

@mixin show-more-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .show-more-component  {
    &-heading {
      &__text {
        font-size: map-get($font-size, big);
        font-weight: map-get($font-weight, regular);
      }
    }
    @include mobile {
      &-heading {
        &__text {
          font-size: map-get($font-size, base);
          font-weight: map-get($font-weight, bold);
        }
      }
    }
  }
}
