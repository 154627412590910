@import './checkbox-base';
@import '../../core/utils/breakpoints';

.checkbox {
  align-items: center;
  display: inline-flex;
  justify-content: flex-start;
  line-height: 1.2;
  position: relative;

  &:focus {
    outline-width: 0.0714rem;
    outline-style: solid;
  }

  &-wrap {
    &_flex {
      display: flex;
      align-items: center;
    }
  }

  &_align_top {
    align-items: flex-start;
  }

  &_text-left {
    text-align: left;
  }

  &_allow-html {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__input-container {
    flex: 0 0 auto;
  }

  &__label {
    cursor: pointer;
    flex: 1 1 auto;
    padding-left: 1rem;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    &_disabled {
      cursor: default;
    }

    &_flex {
      display: flex;
    }

    &_smaller {
      font-size: 1rem;
    }
  }

  &__multiply {
    align-items: center;
    border-radius: 0.7rem;
    display: flex;
    height: 1.4rem;
    justify-content: center;
    margin-left: 0.1rem;
    max-width: 1.4rem;
    min-width: 1.4rem;
    overflow: hidden;
    position: relative;
    top: -1rem;
    flex: 0 0 auto;
  }

  &__input {
    cursor: pointer;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 0;

    &:checked {
      & + .checkbox__styled {
        &:after {
          visibility: visible;
        }
      }
    }

    &:disabled {
      & + .checkbox__styled {
        cursor: default;
      }
    }
  }

  &__styled {
    border: $checkbox-border-width solid transparent;
    border-radius: $checkbox-border-radius;
    cursor: pointer;
    display: inline-block;
    height: $checkbox-height;
    outline: none;
    padding: 0;
    position: relative;
    vertical-align: bottom;
    width: $checkbox-width;
    user-select: none;

    &:after {
      border-bottom: 0.142857142857143rem solid;
      border-left: 0.142857142857143rem solid;
      content: '';
      height: 0.5rem;
      left: 50%;
      margin-left: -0.4285714rem;
      margin-top: -0.4285714rem;
      position: absolute;
      top: 50%;
      transform: rotate(-45deg);
      visibility: hidden;
      width: 0.857142857142857rem;
    }

    &_smaller {
      height: $checkbox-smaller-height;
      width: $checkbox-smaller-width;
    }
  }
}

@include accessible {
  .checkbox {
    &:focus {
      outline: 0.1rem solid currentColor;
      outline-offset: 0.15rem;
    }
  }
}
