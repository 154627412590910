@import "../../../../../styles/blocks/loader/loader_mixin";

@mixin priority-organization-theme($theme) {
  $primary: map-get($theme, primary);
  $white : map-get($theme, white);

  .priority-org {
    &__tooltip {
      background: $white;
    }

    &__loader {
      @include loader-color(map-get($primary, primary));
    }
  }
}

@mixin priority-organization-shadow($shadows) {
  .priority-org {
    &__tooltip {
      box-shadow: map-get($shadows, popover-tight);
    }
  }
}
