@import "src/styles/core/utils/breakpoints";

.popup-choose-document {
  &__autocomplete {
    margin: 2rem 5% 4rem 5%;
    width: 90%;
  }

  &__btn-container {
    margin-top: 2rem;
    width: 100%;
    text-align: right;
  }

  &__btn-ok {
    margin-right: 1rem;
    padding-right: 3rem;
    padding-left: 3rem;
  }
}

.start-learning-popup {
  width: 100%;
  text-align: right;
  margin-top: 1rem;
}

.status-box {
  align-items: center;
  display: flex;
  justify-content: center;

  &__icon {
    border-radius: 100%;
    height: 0.57142857142rem;
    width: 0.57142857142rem;
  }

  &__text {
    margin-left: 0.5rem;
  }
}

.choose-document {
  display: block;
  position: relative;

  &__mobile {
    display: none;
  }

  &__item-subtitle {
    display: inline-block;
    margin: 0.6rem 0.2rem 0 0;
  }

  &__toggle {
    display: flex;
    justify-content: flex-end;
  }

  &__toggle-btn {
    width: 17rem;
    padding: 0.5rem 1.71428571rem;

    &_disabled {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
      padding: 0.7rem 1.71428571rem;
    }
  }

  &__no-documents {
    width: 20rem;
  }

  &__btn-container {
    margin-top: -3rem;
  }

  &__status-box {
    margin-bottom: 1rem;
  }

  &__toggle-mobile {
    display: none;
  }
}

@include mobile {

  .choose-document {
    &__desktop {
      display: none;
    }

    &__mobile {
      display: block;
    }

    &__item-subtitle-value {
      display: block;
    }

    &__item-subtitle {
      margin-bottom: 0.2rem;
    }

    &__toggle-btn {
      padding: 0.85714286rem 0.5rem;
      width: 100%;
    }

    &__status-box {
      justify-content: flex-start;
    }

    &__toggle-mobile {
      display: flex;
      align-items: center;
      margin-bottom: 1.64285714286rem;
      margin-top: 1.5rem;
    }

    &__no-documents {
      width: 100%;
      margin-bottom: 2rem
    }

    &__toggle-mobile-text {
      margin-right: auto;
    }

    &__documents-autocomplete-mobile {
      display: block;
    }
  }

  .cycle-info {
    align-items: stretch;
    flex-direction: column;

    &__item {
      margin-bottom: 0.6rem;
    }

    &__priority-organization {
      width: 100%;
    }

    &__box {
      order: 2;
    }
  }
}
