@import "../../../styles/core/utils/breakpoints";

@mixin psy-test-card-theme($theme) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);

  .psy-test-card {
    background-color: $white;
    border-bottom-color: map-get($mono, tint20);
    border-top-color: map-get($primary, primary);
  }

  .psy-test-results-container {

    &__item-container {
      &:nth-child(3n+2) {
        &:before {
          background-color: map-get($mono, tint20);
        }
      }

      &:nth-child(3n+3) {
        &:before {
          background-color: map-get($mono, tint20);
        }
      }
    }
  }

  @include accessible {
    .psy-test-card {
      border: 0.1rem solid;
      border-radius: 0.14814814814rem;
    }
  }
}

@mixin psy-test-card-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .psy-test-card {
      &__title {
        font-size: map-get($font-size, h2-mobile-smaller);
        font-weight: map-get($font-weight, bold);
      }

      &__description {
        font-size: map-get($font-size, small);
      }
    }

    .psy-test-results-container {
      &__title {
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}

@mixin psy-test-card-shadow($shadows) {
  .psy-test-card {
    box-shadow: map-get($shadows, psy-test-card);
  }
}
