$height: 0.428571428571429rem;

.range-slider {
  &__values {
    margin-bottom: 0.2rem;
  }

  &__value {
    display: inline-block;
    max-width: 45%;

    &:not(:last-child) {
      margin-right: 0.2rem;
    }

    .text-field__number {
      max-width: 70%;

      -moz-appearance: textfield;

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
    }
  }

  //overriding default styles
  & > .ngx-slider {
    height: $height;
    margin: 1rem 0;

    .ngx-slider-bar {
      height: $height;
    }

    & > .ngx-slider-pointer {
      width: 0.3rem;
      height: 1.5rem;
      border-radius: 0.285714285714286rem;
      top: 50%;
      margin-top: -0.75rem;
      outline: none;

      &:after {
        background-color: transparent;
      }

      &:hover, &.ngx-slider-active {
        &:after {
          background-color: transparent;
        }
      }
    }
  }
}
