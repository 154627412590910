@import "../../../styles/blocks/loader/loader_mixin";

@mixin add-document-theme($theme) {
  $primary: map-get($theme, primary);
  $white : map-get($theme, white);

  .add-doc {
    &__tooltip {
      background: $white;
    }

    &__loader {
      @include loader-color(map-get($primary, primary));
    }
  }
}

@mixin add-document-shadow($shadows) {
  .add-doc {
    &__tooltip {
      box-shadow: map-get($shadows, popover-tight);
    }
  }
}
