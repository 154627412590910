@import "../loader/loader_mixin";

@mixin styling-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $alert: map-get($theme, alert);
  $accent: map-get($theme, accent);

  .styling__loader {
    @include loader-color(map-get($primary, primary));
    @include loader-text-color(map-get($mono, tint50));
  }

  .styling__loader_red {
    @include loader-color(map-get($alert, primary));
    @include loader-text-color(map-get($accent, primary));
  }

  .styling__loader_muted {
    @include loader-color(map-get($mono, tint25));
    @include loader-text-color(map-get($mono, tint25));
  }
}
