@import "../../../styles/core/utils/breakpoints";

@mixin edu-plan($theme, $custom) {
  $success: map-get($theme, success);

  .edu-plan {
    &__multi-zet-helper {
      color: map-get($theme, white);
      background-color: map-get($success, primary);
    }

    &__loader-box {
      background: map-get($custom, loader);
    }
  }

  @include accessible {
    .edu-plan {

      &__button-with-icon {
        &:focus {
          background: map-get($theme, black);

          .icon {
            color: map-get($theme, white);
          }
        }
      }
    }
  }
}
