@import "../../../../../styles/core/utils/breakpoints";

@mixin prof-questionary-questions-container-theme($theme) {
  $white: map-get($theme, white);

  @include mobile {
    .prof-questionary-question {
      &__body {
        background: $white;
      }
    }
  }

}

@mixin prof-questionary-questions-container-shadow($shadows) {
  @include mobile {
    .prof-questionary-question {
      &__body {
        box-shadow: map-get($shadows, modal-light);
      }
    }
  }
}

@mixin prof-questionary-questions-container-typography($config) {
  $font-weight: map-get($config, font-weight);

  @include mobile {
    .prof-questionary-question {
      &__previous-btn {
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
