@import '../../../styles/core/utils/breakpoints';
@import "../../../styles/blocks/loader/loader_mixin";

@mixin selfcontrol-container-theme($theme, $custom) {
  $alert: map-get($theme, alert);
  $white: map-get($theme, white);
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);

  .self-assessment-results-container {

    &__loader {
      @include loader-color(map-get($alert, primary));
    }

    &__detailed-result {
      border-right: 0.0714285rem solid map-get($mono, tint10);
    }
  }

  @include mobile{
    .self-assessment-results-container {
      &__modal-window {
        background: map-get($custom, root-bg);
      }
      &__detailed-result {
        border-bottom: 0.0714285rem solid map-get($mono, tint10);
      }
    }
  }
}
