@mixin profile-titles-theme($theme){
  $mono: map-get($theme, mono);

  .profile-titles {
    color: map-get($mono, tint85);
  
    &__introduction {
      border-color: map-get($mono, tint20);
    }
  } 
}
