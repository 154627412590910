@import '../../../styles/core/utils/breakpoints';

@mixin outside-cycles-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);

  .outside-cycles {
    &__button {
      background: map-get($custom, customaziable-bg);
    }

    &__text {
      border-left-color: transparent;

      &_active {
        $active: map-get($primary, primary);

        border-left-color: $active;
        color: $active;
      }
    }
  }
}

@mixin outside-cycles-typography($config) {
  $font-size: map-get($config, font-size);

  /*@include tablet {
    .outside-cycles {
      &__text {
        font-size: map-get($font-size, base);
      }
    }
  }*/
}


