@import '../../../styles/core/utils/breakpoints';

@mixin timer-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  
  .timer {
    background-color: $white;
  
    &:before {
      background-color: $white;
    }
  
    &:after {
      background-color: $white;
    }
  
    &__point {
      background: map-get($primary, primary);
  
      &:after, &:before {
        background: map-get($primary, primary);
      }
    }
  }

  @include mobile {
    .timer {
      background-color: map-get($custom, root-bg);

      &:before {
        background-color: map-get($custom, root-bg);
      }

      &:after {
        background-color: map-get($custom, root-bg);
      }

    }
  }
}
