@import '../../core/utils/breakpoints';

@mixin grid-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .grid {

    &__header-col {
      &_sorted_asc,
      &_sorted_desc {
        color: map-get($primary, primary);
      }
    }

    &__column {
      color: map-get($mono, tint80);
      border-color: map-get($mono, tint10);
    }

    &__header-row-container {
      border: solid 0.1rem map-get($mono, tint25);
    }

    &__header-row {
      background: $white;

      &_bordered {
        .grid__header-col {
          border-color: map-get($mono, tint10);
        }
      }

      &_fixed {
        th {
          background: $white;
        }
      }

      &_statistics {
        border-color: map-get($mono, tint60);
        background-color: map-get($mono, tint05);
      }
    }

    &__row {
      &:nth-child(odd) {
        background: map-get($primary, tint15);
        //@include tablet {
        //  background: $white;
        //}
      }

      &:nth-child(even) {
        background: $white;
      }
    }

    &_flat__row {
      &:nth-child(odd), &:nth-child(even) {
        background: transparent;
      }
    }

    &_theme_search {
      &__column {
        color: map-get($mono, tint70);
      }

      .grid__row {
        &:hover {
          .grid__column,
          .grid__column-card {
            background: map-get($custom, search);
            cursor: pointer;
          }
        }

        &_cannot-be-opened {
          .grid__column,
          .markers-zet__text {
            color: map-get($mono, tint30);
          }
        }
      }

      &.grid_mode_card {
        @include mobile {
          .grid__column-card {
            background: $white;
          }
          .grid__row {
            background: none;
            &:hover {
              .grid__column, .grid__column-card{
                background: $white;
              }
            }
          }
        }
      }
    }

    &_theme_statistics {
      position: relative;
      .grid__column {
        text-align: left;
        &_photo {
          padding: 1rem 0 1rem 0rem;
          width: 3rem;
        }

        &_name {
          padding: 1rem 0 1rem 0rem;
          width: 5rem;
        }

        &_number {
          padding: 1rem 0 1rem 0.5rem;
          width: 2rem;
        }

        &_zet {
          padding: 1rem 0 1rem 0rem;
          width: 3rem;
        }
      }

      .user-ratings__user-photo {
        height: 100%;
        width: 100%;
        max-height: 100%;
        max-width: 100%;
        background-position: center;
        background-size: cover;
      }
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .grid {

      &__row {
        &:nth-child(odd) {
          background: $white;
        }
      }
    }
  }

  // TODO tablet version
  /*
  @include tablet {
    .grid {

      &__row {
        &:nth-child(odd) {
          background: $white;
        }
      }
    }
  }
  */
}

@mixin grid-shadow($shadows) {
  .grid {
    &__column-card {
      box-shadow: map-get($shadows, modal-light);
    }
  }
}

@mixin grid-typography($config) {
  $font-size: map-get($config, font-size);

  .grid {
    &__column-title {
      font-size: map-get($font-size, tiny);
    }

    &_theme_statistics {
      .grid__column {
        font-size: map-get($font-size, small);
      }
    }
  }
}
