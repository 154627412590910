@import "../../../styles/core/utils/breakpoints";

@mixin rule-attached-element-info-typography($config) {
  $font-size: map-get($config, font-size);

  @include mobile {
    .search-wizard {

      &__content-item {
        font-size: map-get($font-size, small);
      }
    }
  }
}
