@import '../../../styles/core/utils/breakpoints';

@mixin multi-line-text-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .multi-line-text {

    &_marked {
      font-weight: map-get($font-weight, bold);
    }
  }

  @include mobile {
    .multi-line-text {

      &_marked {
        font-size: map-get($font-size, base);
      }
    }
  }
}
