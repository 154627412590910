@import "../../../styles/core/utils/breakpoints";

@mixin dpp-pk-addresses($theme) {
  $white: map-get($theme, white);

  .dpp-pk-addresses {
    &__plate {
      background: $white;
    }
  }
}

@mixin dpp-pk-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  /*@include tablet {
    .dpp-pk-addresses {

      &__th {
        font-weight: map-get($font-weight, bold);
      }

      &__td, &__th {
        font-size: map-get($font-size, small);
      }
    }
  }*/

  @include mobile {
    .dpp-pk-addresses {

      &__th {
        font-weight: map-get($font-weight, bold);
      }

      &__td, &__th {
        font-size: map-get($font-size, small);
      }
    }
  }

}
