@import "../../../styles/core/utils/breakpoints";

@mixin toast-theme($theme, $custom) {
  $custom-toast: map-get($custom, toast);
  $mono: map-get($theme, mono);

  .toast {
    background-color: map-get($custom-toast, default);

    &-success {
      background-color: map-get($custom-toast, success);
    }

    &-error {
      background-color: map-get($custom-toast, error);
    }

    &-info {
      background-color: map-get($custom-toast, info);
    }

    &-warning {
      background-color: map-get($custom-toast, warning);
    }
  }

  .toast-container .toast {
    color: map-get($theme, white);
  }

  .toast-message a,
  .toast-message label {
    color: map-get($theme, white);
  }

  .toast-message a:hover {
    color: map-get($mono, tint20);
  }

  .toast-close-button {
    color: map-get($theme, white);

    &:hover, &:focus {
      color: map-get($theme, black);
    }
  }

  .toast-close-button {
    text-shadow: 0 0.0555rem 0 map-get($theme, white);
  }

  @include accessible {
    .toast-close-button {
      line-height: 1em;
      width: 1em;
      outline: none;

      &:hover, &:focus {
        background: map-get($theme, white);
        opacity: 1;
      }
    }
  }
}

@mixin toast-shadow($shadows) {
  .toast-container .toast {
    box-shadow: map-get($shadows, modal-light);

    &:hover {
      box-shadow: map-get($shadows, grid-hover);
    }
  }
}

@mixin toast-typography($config) {
  $font-size: map-get($config, font-size);

  .toast-close-button {
    font-size: map-get($font-size, bigger);
  }
}
