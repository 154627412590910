@import "../../styles/core/utils/breakpoints";

@mixin root-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .root {
    background: map-get($custom, radio);
    &_light {
      background-color: $white;
      color: map-get($mono, primary);
    }
  }

  /*@include tablet {
    .root {
      &_light {
        background: map-get($custom, radio);
      }
    }
  }*/

  @include mobile {
    .root {
      &_light {
        background: map-get($custom, radio);
      }
    }
  }
}
