@import "src/styles/core/utils/breakpoints";

@mixin edu-header-theme($theme) {
  $color-black: map-get($theme, color-black);
  $mono: map-get($theme, mono);

  .edu-header {
    &__title {
      color: map-get($mono, tint50);
    }
    &__heading {
      color: $color-black;
    }
  }
}

@mixin edu-header-typography($config) {
  $font-family: map-get($config, font-family);
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .edu-header {
    font-family: $font-family;
    font-size: map-get($font-size, base);
    font-weight: map-get($font-weight, regular);

    &__heading {
      font-weight: map-get($font-weight, regular);
    }
  }

  //TODO rename to 'mobile' when all tablet version will be done
  @include mobile {
    .edu-header {
      &__title {
        font-size: map-get($font-size, small);
      }

      &__heading {
        font-size: map-get($font-size, h2-mobile);
        font-weight: map-get($font-weight, bold);
      }
    }
  }
}
