@import "../../../styles/core/utils/breakpoints";

@mixin edu-view-dropdown-theme($theme) {
  $white: map-get($theme, white);

  @include mobile {
    .edu-element-dropdown {
      background: $white;
    }
  }
}

@mixin edu-view-dropdown-shadow($shadows) {
  @include mobile {
    .edu-element-dropdown {
      box-shadow: map-get($shadows, modal-light);
    }
  }
}
