@import "../../../styles/core/utils/breakpoints";

@mixin accordion-theme($theme) {
  $mono: map-get($theme, mono);
  $alert: map-get($theme, alert);
  $primary: map-get($theme, primary);
  $success: map-get($theme, success);
  $white: map-get($theme, white);

  .accordion {
    background-color: $white;
    border-color: map-get($mono, tint05);

    &__toggle {
      color: map-get($mono, tint50);
      background: $white;

      & > .figure {
        &:before,
        &:after {
          border-color: map-get($mono, tint50);
        }
      }
    }

    &__action {
      background: $white;
    }

    // &__toggle {
    //   border-color: map-get($mono, tint50);
    // }

    &_primary {
      @include colorRibbon(map-get($primary, tint95), map-get($primary, tint35));
    }
    &_primary-solid {
      border-color: map-get($primary, primary);
    }
    &_success {
      @include colorRibbon(map-get($success, tint75), map-get($success, tint25));
    }
    &_mono {
      @include colorRibbon(map-get($mono, tint55), map-get($mono, tint08));
    }
    &_alert {
      @include colorRibbon(map-get($alert, bright), map-get($alert, tint15));
    }
  }
}

@mixin colorRibbon($colorFrom, $colorTo) {
  -webkit-border-image:
    -webkit-gradient(linear, 100% 0, 0 0, from($colorFrom), to($colorTo)) 1 100%;
  -webkit-border-image:
    -webkit-linear-gradient(right, $colorFrom, $colorTo) 1 100%;
  -moz-border-image:
    -moz-linear-gradient(right, $colorFrom, $colorTo) 1 100%;
  -o-border-image:
    -o-linear-gradient(right, $colorFrom, $colorTo) 1 100%;
  border-image:
    linear-gradient(to right, $colorFrom, $colorTo) 1 100%;
}

@mixin accordion-shadow($shadows) {
  .accordion {
    box-shadow: map-get($shadows, accordion);
  }
}

@mixin topics-title-typography($config) {
  $font-size: map-get($config, font-size);

  .accordion {

    &__label {
      font-size: map-get($font-size, h3);

      .text {
        font-size: map-get($font-size, h3);
      }
    }
  }

  /*@include tablet {
    .topics-title {

      &__text {
        font-size: map-get($font-size, base);
      }
    }
  }*/

  @include mobile {
    .accordion {

      &__label {
        font-size: map-get($font-size, base);

        .text {
          font-size: map-get($font-size, base);
        }
      }
    }
  }
}
