.toggle {
  background: none;
  border: none;
  outline: none;
  display: block;
  padding: 1.05rem;
  cursor: pointer;

  &__chevron {
    float: right;
    display: block;
    width: 0.428571428571429rem;
    height: 0.428571428571429rem;
    transform: rotate(-45deg);
    transition: transform ease .3s, top ease .3s;
    &:before, &:after {
      content: "";
      position: absolute;
      border-radius: 0.142857142857143rem;
      background: #777;
    }
    &:before {
      top: 0;
      left: 0;
      height: 100%;
      width: 0.142857142857143rem;
    }
    &:after {
      bottom: 0;
      left: 0;
      width: 100%;
      height: 0.142857142857143rem;
    }

    &_rotate {
      transform: rotate(-225deg);
    }

    &_big {
      width: 0.7rem;
      height: 0.7rem;
    }
  }
}
