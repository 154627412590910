@import "../../../styles/core/utils/breakpoints";

@mixin edu-element-view-rating-theme($theme) {
  $primary: map-get($theme, primary);

  .edu-element-rating {
    &__leave-estimate-text {
      color: map-get($primary, shade60);
    }
  }
}

@mixin edu-element-view-rating-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .edu-element-rating {

    &__current-estimate {
      font-size: 5rem;
    }
  }

  /*@include tablet {
    .edu-element-rating {

      &__current-estimate {
        font-size: 4.285714285714286rem;
        font-weight: map-get($font-weight, regular);
      }
    }
  }*/

  @include mobile {
    .edu-element-rating {

      &__current-estimate {
        font-size: 2.85714285714rem;
      }

      &__max-estimate {
        font-size: map-get($font-size, tiny);
      }
    }
  }
}


