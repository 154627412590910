@mixin edu-program-info-theme($theme) {
  $primary: map-get($theme, primary);
  $success: map-get($theme, success);
  $white: map-get($theme, white);

  .edu-program-info {

    &_success {
      border-top-color: map-get($success, primary);
    }

    &_primary {
      border-top-color: map-get($primary, primary);
    }

    &__tooltip{
      background-color: $white;
    }
  }
}

@mixin edu-program-info-shadow($shadows) {
  .edu-program-info {

    &__tooltip {
      box-shadow: map-get($shadows, tooltip);
    }
  }
}
