@import "../../core/utils/breakpoints";

.edu-topic-mobile {
  margin-bottom: 0.5rem;
  padding: 0.92857142857rem 0.71428571428rem 0;

  &:last-of-type {
    margin-bottom: 0;
  }

  &__container {
    margin-left: 0.5rem;
  }

  &__title {
    line-height: 1.4;
    min-height: 3rem;
  }

  &__title-text {
    margin-left: 0.2rem;
  }

  &__button {
    width: 100%;
  }

  &__btn-add-container {
    margin-top: 1.25rem;
  }

  &__test-status-container {
    align-items: center;
    display: flex;
    margin: 0.5rem 0;
  }

  &__test-btn {
    padding: 0.5rem 0;
    display: flex;
    align-items: center;
  }

  &__test-status-text {
    margin: 0 0.2rem 0 0.4rem;
  }

  &__rewards-container {
    align-items: center;
    display: flex;
    margin-left: auto;
  }

  &__separator {
    border-top-style: solid;
    border-top-width: 1px;
    margin-top: 1.42857142857rem;
  }

  &__icon, svg-icon.edu-topic-mobile__icon {
    display: flex;
  }

}
