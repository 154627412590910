@import "breakpoints";

@mixin centered-block {
  position: relative;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

@mixin horizontal-centered-block {
  position: relative;
  left: 50%;
  transform: translateX(-50%);


  @include mobile {
    position: static;
    left: auto;
    transform: none;
  }
}

@mixin vertical-centered-block {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
