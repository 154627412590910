@mixin loader-color($color) {
  .loader {
    &__stop {
      stop-color: $color;
    }
  }
}

@mixin loader-text-color($color) {
  .loader {
    &__text {
      fill: $color;
    }

    &__label {
      color: $color;
    }
  }
}
