@import "src/styles/core/utils/breakpoints";

@mixin choose-document-theme($theme) {
  $mono: map-get($theme, mono);
  $success: map-get($theme, success);
  $alert: map-get($theme, alert);

  .status-box {
    &__icon {
      background:  map-get($alert, primary);

      &_progress {
        background:  map-get($success, primary);
      }
    }
  }
}

@mixin choose-document-typography($config) {
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .choose-document {

    &__item-subtitle,
    &__item-subtitle-value {
      font-size: map-get($font-size, big);
    }
  }

  @include mobile {
    .choose-document {
      &__item-subtitle,
      &__item-subtitle-value {
        font-size: map-get($font-size, base);
      }
    }
  }
}
