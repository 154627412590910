@import '../../blocks/accessible-version/accessible-version-theme';
@import '../../blocks/normalize/normalize-theme';
@import '../../blocks/button/button-theme';
@import '../../blocks/virtual-tour-override/virtual-tour-override-theme';
@import '../../blocks/help-override/help-override-theme';
@import '../../blocks/border-separator/border-separator-theme';
@import '../../blocks/link/link-theme';
@import '../../blocks/tooltip/tooltip-theme';
@import '../../blocks/content/content-theme';
@import '../../blocks/sidebar/sidebar-theme';
@import '../../blocks/range-slider/range-slider-theme';
@import '../../blocks/checkbox/checkbox-theme';
@import '../../blocks/chart/chart-theme';
@import '../../blocks/radio/radio-theme';
@import '../../blocks/text-field/text-field-theme';
@import '../../blocks/error-message/error-message-theme';
@import '../../blocks/form-control/form-control-theme';
@import '../../blocks/toggle/toggle-theme';
@import '../../blocks/collapse/collapse-theme';
@import '../../blocks/datepicker/datepicker-theme';
@import '../../blocks/icon/icon-theme';
@import '../../blocks/figure/figure-theme';
@import '../../blocks/flex-grid/flex-grid-theme';
@import '../../blocks/slider-button/slider-button-theme';
@import '../../blocks/text/text-theme';
@import '../../blocks/heading/heading-theme';
@import '../../blocks/styling/styling-theme';
@import '../../blocks/topics/topics-theme';
@import '../../blocks/grid/grid-theme';
@import '../../blocks/tags/tag-theme';
@import '../../blocks/dpp-pk-cycles/dpp-pk-cycles-theme';
@import '../../blocks/iam-screen/iam-screen-theme';
@import '../../blocks/edu-header/edu-header-theme';
@import '../../blocks/edu-trajectory-topic/edu-trajectory-topic-theme';
@import '../../blocks/edu-topic-mobile/edu-topic-mobile-theme';
@import '../../blocks/edu-section/edu-section-theme';
@import '../../blocks/notification-colors/notification-color-theme';
@import '../../blocks/cdk-overlay/cdk-overlay-theme';
@import '../../blocks/toast/toast-theme';
@import '../../blocks/container/container-theme';
@import "../../../app/app-commons/material/multiselect2/multiselect2";
@import '../../../app/app-commons/select/baseselect/baseselect-theme';
@import '../../../app/app-commons/timer/timer-theme';
@import '../../../app/app-commons/autocomplete/autocomplete-theme';
@import '../../../app/user-account/zet/zet-count-theme';
@import '../../../app/root/root-theme';
@import '../../../app/header/header-theme';
@import '../../../app/submenu/submenu-theme';
@import '../../../app/registration/registration-theme';
@import '../../../app/user-init/activity-selection/activity-selection-theme';
@import '../../../app/login/login-theme';
@import '../../../app/esia-error/esia-error-theme';
@import '../../../app/registration-result/registration-result-theme';
@import '../../../app/password-recovery-result/password-recovery-result-theme';
@import '../../../app/recovery/recovery-theme';
@import '../../../app/password-recovery/password-recovery-theme';
@import '../../../app/tabs/tab-group/tab-group-theme';
@import '../../../app/card/card-theme';
@import '../../../app/app-commons/progress-bar/progress-bar-theme';
@import '../../../app/app-commons/popover/popover-theme';
@import '../../../app/app-commons/popup/popup-theme';
@import '../../../app/app-commons/notification-bubble/notification-bubble-theme';
@import '../../../app/app-commons/password/password-theme';
@import '../../../app/app-commons/like-dislike/like-dislike-theme';
@import '../../../app/app-commons/error-notification/component/error-notification-theme';
@import '../../../app/app-commons/rating/rating-theme';
@import '../../../app/checkbox-group/checkbox-group-theme';
@import '../../../app/app-commons/edu-view-dropdown/edu-view-dropdown-theme';
@import '../../../app/app-commons/select/multiselect/multiselect-theme';
@import '../../../app/app-commons/questionary/questionary-theme';
@import '../../../app/app-commons/questionary/questionary-progress-bar/questionary-progress-bar-theme';
@import '../../../app/app-commons/questionary/prof-questionary/prof-questionary-container/prof-questionary-container-theme';
@import '../../../app/app-commons/questionary/psy-questionary/psy-questionary-container/psy-questionary-container-theme';
@import '../../../app/app-commons/questionary/psy-questionary/result/questionary-results-item/questionary-results-item-theme';
@import '../../../app/app-commons/questionary/questionary-rewards/questionary-rewards-theme';
@import '../../../app/app-commons/dropdown/dropdown-theme';
@import '../../../app/app-commons/dropdown-tree/dropdown-tree-theme';
@import '../../../app/user-account/accordion/accordion-theme';
@import '../../../app/user-account/achievements/xp-bar/xp-bar-theme';
@import '../../../app/user-account/profile/profile-documents/documents-theme';
@import '../../../app/user-account/profile/profile-documents/choose-document/_choose-document-theme.component';
@import '../../../app/user-account/profile/profile-titles/profile-titles-theme';
@import '../../../app/user-account/portfolio/portfolio-theme';
@import '../../../app/user-account/profile/profile-theme';
@import '../../../app/user-account/profile/profile-documents/profile-document-certificate/profile-document-certificate-theme';
@import '../../../app/user-account/profile/profile-titles/profile-title-item/profile-title-item-theme';
@import '../../../app/user-account/toggle-button/toggle-button-theme.component';
@import '../../../app/user-account/edu-trajectory-helper/edu-trajectory-helper-theme';
@import '../../../app/user-account/psy-test-card/psy-test-card-theme';
@import '../../../app/app-commons/questionary/prof-questionary/prof-questionary-questions-container/prof-questionary-questions-container-theme';
@import '../../../app/app-commons/questionary/psy-questionary/psy-questionary-questions-container/psy-questionary-questions-container-theme';
@import '../../../app/app-commons/questionary/psy-questionary/result/questionary-results-item/questionary-results-item-theme';
@import '../../../app/app-commons/questionary/psy-questionary/result/questionary-results/questionary-results-theme';
@import '../../../app/app-commons/questionary/question-options/search-select-question/search-select-question-theme';
@import '../../../app/app-commons/questionary/questionary-rewards/questionary-rewards-theme';
@import '../../../app/app-commons/questionary/questionary-theme';
@import '../../../app/app-commons/cycle-management/cycle-management-theme';
@import '../../../app/app-commons/outside-cycles/outside-cycles-theme';
@import '../../../app/app-commons/questionary/psy-questionary/questionary-instructions/questionary-instructions-theme';
@import '../../../app/app-commons/multi-zet/multi-zet-theme';
@import '../../../app/app-commons/markers-zet/markers-zet-theme';
@import '../../../app/app-commons/add-document/add-document-theme';
@import '../../../app/user-account/schedule/schedule-theme';
@import '../../../app/user-account/card-edu-element/card-edu-element-theme';
@import '../../../app/user-account/assessment-card/assessment-card-theme';
@import '../../../app/user-account/notifications-list/notifications-list-theme';
@import '../../../app/search/search-theme';
@import '../../../app/search/search-wizard/search-wizard-theme';
@import '../../../app/user-account/dpp-pk-popup/dpp-pk-popup-theme';
@import '../../../app/user-account/dpp-pk-addresses/dpp-pk-addresses-theme.scss';
@import '../../../app/user-account/dpp-pk-my-cycles/dpp-pk-my-cycles-theme.scss';
@import '../../../app/notification/count/notification-count-theme';
@import '../../../app/user-account/om-addresses/om-addresses-theme.scss';
@import '../../../app/user-account/edu-element-view-dpp-pk/edu-element-view-dpp-pk-theme';
@import '../../../app/user-account/edu-element-view-om/edu-element-view-om-theme';
@import '../../../app/user-account/edu-element-view/edu-element-view-theme';
@import '../../../app/user-account/edu-element-view-om/om-extra-info/om-extra-info-theme';
@import '../../../app/user-account/edu-element-view-title/edu-element-view-title-theme';
@import '../../../app/user-account/edu-element-view-rating/edu-element-view-rating-theme';
@import '../../../app/user-account/topics/included-topic/included-topic-theme';
@import '../../../app/user-account/edu-element-view-about/edu-element-view-about-theme';
@import '../../../app/user-init/frmr-certificates-confirmation/frmr-certificates-confirmation-theme';
@import '../../../app/user-init/tac-confirmation/tac-confirmation-theme';
@import '../../../app/user-init/edu-program-info/edu-program-info-theme';
@import '../../../app/user-init/access-doc-loader/access-doc-loader-theme';
@import '../../../app/user-account/profile/profile-settings/profile-settings-theme';
@import '../../../app/user-account/edu-plan/edu-plan-theme';
@import '../../../app/user-account/selfcontrol-result/selfcontrol-result-theme';
@import '../../../app/user-account/selfcontrol-result-container/selfcontrol-result-container-theme';
@import '../../../app/user-account/cycle-select/cycle-select-theme';
@import '../../../app/user-account/edu-element-view-iom/edu-element-view-iom-theme';
@import '../../../app/user-account/edu-element-view-iom/iom-main-info/iom-main-info-theme';
@import '../../../app/user-account/full-trajectory/full-trajectory-theme';
@import '../../../app/user-account/read-more/read-more-theme';
@import '../../../app/user-account/edu-element-view-om/om-main-info/om-main-info-theme';
@import '../../../app/user-account/profile-awards/profile-awards-theme';
@import '../../../app/app-commons/select/select/select-theme';
@import '../../../app/user-account/see-annotation/see-annotation-theme';
@import '../../../app/user-account/profile/wrap-navigation/wrap-navigation-item/wrap-navigation-item-theme';
@import '../../../app/user-account/profile/profile-documents/priority-organization/priority-organization-theme';
@import '../../../app/grid-module/pagination/pagination-theme';

/*
 * @param $theme - map with abstract colors
 * @param $custom - map with custom colors for components
 */
@mixin ng-portal-theme($theme, $custom) {
  @include add-document-theme($theme);
  @include accessible-version-theme($theme);
  @include baseselect-theme($theme);
  @include normalize-theme($theme);
  @include virtual-tour-override-theme($theme, $custom);
  @include help-override-theme($theme, $custom);
  @include content-theme($theme);
  @include dpp-pk-cycles-theme($theme);
  @include container-theme($theme);
  @include header-theme($theme, $custom);
  @include popover-theme($theme, $custom);
  @include popup-theme($theme);
  @include tooltip-theme($theme);
  @include progress-bar-theme($theme, $custom);
  @include border-separator-theme($theme);
  @include like-dislike-theme($theme);
  @include error-notification-theme($theme);
  @include edu-element-view-title-theme($theme, $custom);
  @include range-slider-theme($theme);
  @include text-theme($theme);
  @include heading-theme($theme);
  @include text-field-theme($theme);
  @include error-message-theme($theme, $custom);
  @include form-control-theme($theme);
  @include toggle-theme($theme);
  @include collapse-theme($theme);
  @include autocomplete-theme($theme);
  @include checkbox-theme($theme, $custom);
  @include chart-theme($theme, $custom);
  @include radio-theme($theme, $custom);
  @include button-theme($theme);
  @include button-stroked-theme($theme);
  @include button-arrow-theme($theme);
  @include button-text-theme($theme);
  @include icon-theme($theme, $custom);
  @include figure-theme($theme, $custom);
  @include flex-grid-theme($theme);
  @include root-theme($theme, $custom);
  @include profile-theme($theme, $custom);
  @include slider-button-theme($theme);
  @include om-extra-info-theme($theme);
  @include sidebar-theme($theme, $custom);
  @include styling-theme($theme);
  @include accordion-theme($theme);
  @include xp-bar-theme($theme);
  @include documents-theme($theme);
  @include documents-certificate-theme($theme, $custom);
  @include profile-titles-theme($theme);
  @include toggle-button-theme($theme);
  @include profile-title-item-theme($theme);
  @include link-theme($theme);
  @include submenu-theme($theme, $custom);
  @include edu-element-view-theme($theme);
  @include datepicker-theme($theme);
  @include multi-zet-theme($theme);
  @include notifications-list-theme($theme);
  @include notifications-color-theme($theme);
  @include markers-zet-theme($theme);
  @include tab-group-theme($theme, $custom);
  @include card-theme($theme);
  @include cycle-management-theme($theme, $custom);
  @include outside-cycles-theme($theme, $custom);
  @include questionary-progress-bar-theme($theme);
  @include prof-questionary-container-theme($theme);
  @include psy-questionary-container-theme($theme);
  @include topics-theme($theme);
  @include edu-trajectory-topic-theme($theme, $custom);
  @include tag-theme($theme);
  @include grid-theme($theme, $custom);
  @include search-theme($theme, $custom);
  @include search-wizard-theme($theme, $custom);
  @include notification-bubble-theme($theme, $custom);
  @include password-theme($theme, $custom);
  @include schedule-theme($theme);
  @include card-edu-element-theme($theme, $custom);
  @include questionary-theme($theme);
  @include notification-count-theme($theme);
  @include questionary-results-item-theme($theme);
  @include questionary-rewards-theme($theme);
  @include registration-form-theme($theme, $custom);
  @include dpp-pk-addresses($theme);
  @include om-addresses($theme);
  @include dpp-pk-popup-theme($theme, $custom);
  @include edu-element-view-dpp-pk-theme($theme, $custom);
  @include edu-element-view-om-theme($theme);
  @include included-topic-theme($theme, $custom);
  @include edu-element-view-rating-theme($theme);
  @include access-doc-loader-theme($theme, $custom);
  @include portfolio-theme($theme);
  @include assessment-card-light-theme($theme);
  @include frmr-certificates-confirmation-theme($theme);
  @include tac-confirmation-theme($theme);
  @include edu-element-view-about-theme($theme);
  @include zet-count-theme($theme);
  @include profile-settings-theme($theme, $custom);
  @include dpp-pk-my-cycles-theme($theme, $custom);
  @include edu-plan($theme, $custom);
  @include progress-circle-theme($theme, $custom);
  @include selfcontrol-container-theme($theme, $custom);
  @include cycle-select-theme($theme);
  @include login-theme($theme, $custom);
  @include password-recovery-theme($theme, $custom);
  @include esia-error-theme($theme, $custom);
  @include registration-result-theme($theme, $custom);
  @include password-recovery-result-theme($theme, $custom);
  @include recovery-theme($theme, $custom);
  @include iam-screen-theme($theme, $custom);
  @include activity-selection-theme($theme);
  @include psy-test-card-theme($theme);
  @include edu-program-info-theme($theme);
  @include edu-element-view-iom-theme($theme);
  @include iom-main-info-theme($theme);
  @include edu-trajectory-helper-theme($theme);
  @include select-theme($theme);
  @include edu-header-theme($theme);
  @include edu-section-theme($theme);
  @include rating-theme($theme);
  @include multiselect-theme($theme);
  @include multiselect2-theme($theme, $custom);
  @include full-trajectory-helper-theme($theme);
  @include read-more-theme($theme, $custom);
  @include edu-topic-mobile-theme($theme, $custom);
  @include timer-theme($theme, $custom);
  @include edu-view-dropdown-theme($theme);
  @include om-main-info-theme($theme);
  @include prof-questionary-questions-container-theme($theme);
  @include psy-questionary-questions-container-theme($theme);
  @include questionary-results-theme($theme);
  @include search-select-question-theme($theme);
  @include questionary-instructions-theme($theme);
  @include see-annotation-theme($theme);
  @include profile-awards-theme($theme);
  @include cdk-overlay-theme($theme, $custom);
  @include wrap-navigation-item-theme($theme);
  @include dropdown-theme($theme, $custom);
  @include dropdown-tree-theme($theme, $custom);
  @include checkbox-group-theme($theme);
  @include toast-theme($theme, $custom);
  @include priority-organization-theme($theme);
  @include pagination-theme($theme);
  @include choose-document-theme($theme);
}
