@import '../../../styles/core/utils/breakpoints';

@mixin search-wizard-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  $accent: map-get($theme, accent);
  $mono: map-get($theme, mono);

  .search-wizard {
    &__header {
      background: map-get($primary, shade10);
    }

    &__back {
      color: $white;
    }

    &__menu-button {
      color: map-get($primary, tint60);
      border-bottom: 0.214285714285714rem solid transparent;

      &_active {
        border-bottom-color: $white;
      }


      &_preset {
        color: map-get($accent, primary);
      }

      &_filled {
        color: $white;
      }
    }

    &__icon {
      svg path {
        fill: map-get($primary, shade10);
      }
    }

    &__body {
      background: map-get($custom, search);
    }

    &__title {
      color: map-get($mono, tint50)
    }

    &__subtitle {
      color: map-get($mono, primary)
    }
  }

  @include accessible {
    .search-wizard {
      &__header {
        background: map-get($theme, white);
      }

      &__back {
        color: map-get($theme, black);
      }

      &__menu-button {
        color: map-get($theme, black);
        border: 0.09rem solid map-get($theme, black);
        border-radius: 0.2rem;
        margin: 0.1rem;
        padding: 0 1rem;


        &_active {
          background: map-get($theme, black);
          color: map-get($theme, white);
        }
      }
    }
  }
}


@mixin search-wizard-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .search-wizard {
    &__title {
      font-size: map-get($font-size, base);
      font-weight: map-get($font-weight, regular);

      @include mobile {
        font-size: map-get($font-size, tiny);
      }
    }

    &__subtitle {
      font-weight: map-get($font-weight, medium);

      @include mobile {
        font-size: map-get($font-size, base);
      }
    }

    &__content-name {
      font-weight: map-get($font-weight, medium);
    }
  }
}


