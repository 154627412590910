@use 'sass:math';
@import '../../../styles/core/utils/trig';


@mixin progress-circle-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $alert: map-get($theme, alert);
  $success: map-get($theme, success);
  $accent: map-get($theme, accent);
  $white: map-get($theme, white);

  $circle: 8.571428571428571rem;
  $thickness: 0.142857142857143rem;
  $point: 0.357142857142857rem;

  .progress-circle {

    background-color: $white;
    &:before {
      background-color: $white;
    }
    &:after {
      background-color: $white;
    }

    &_secondary {
      background-color: map-get($custom, root-bg);
      &:before {
        background-color: map-get($custom, root-bg);
      }
      &:after {
        background-color: map-get($custom, root-bg);
      }
    }

    &__point {

      position: absolute;
      width: $point;
      height: $point;
      border-radius: 50%;
      top: 0;
      margin-top: math.div(-$point,2);
      margin-left: math.div(-$point,2);
      left: math.div($circle,2);

      &_success {
        background: map-get($success, primary);
        &:after, &:before {
          background: map-get($success, primary);
        }
      }

      &_alert {
        background: map-get($alert, primary);
        &:after, &:before {
          background: map-get($alert, primary);
        }
      }

      &_accent {
        background: map-get($accent, primary);
        &:after, &:before {
          background: map-get($accent, primary);
        }
      }
    }
  }

  $radius: math.div($circle,2);
  $step: 1;
  $loops: round(math.div(100, $step));
  $increment: math.div(360, $loops);

  @for $i from 0 through $loops {
    .progress-circle__#{$i*$step} {
      .progress-circle__point {
        top: $radius - ($radius - math.div($thickness,2))*sin(90deg - $increment * $i);
        left: $radius + ($radius - math.div($thickness,2))*cos(90deg - $increment * $i);
      }
      .progress-circle__value {
        transform: translate(cos(90deg - $increment * $i)*50% - 50%, -1*sin(90deg - $increment * $i)*50% - 50%);
      }
    }
  }
}
