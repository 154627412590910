@import "../../../styles/core/utils/breakpoints";

@mixin password-theme($theme, $custom) {
  $white: map-get($theme, white);
  $primary: map-get($theme, primary);

  .password-group__btn {
    &_toggled {
      svg {
        g {
          fill: $white;
          stroke: map-get($primary, primary);
        }
      }
    }
  }

  @include accessible {
    .password-group {

      &__btn {

        &:focus {
          background: map-get($theme, black);

          .icon {
            color: map-get($theme, white);
          }
        }
      }
    }
  }
}
