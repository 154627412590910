@import './sidebar-base';
@import '../accessible-version/accessible-version-variables';
@import '../../core/utils/breakpoints';
@import '../../core/utils/positions';
@import '../../core/utils/z-indices';

.sidebar {
  position: relative;
  width: $sidebar-width;
  padding: 0;
  transition: width 0.3s ease;
  z-index: $sidebar-z-index;

  &_toggled {
    padding-top: 3rem;
  }
  &_collapsed {
    padding-left: 2.142857142857143em;
    padding-right: 2.142857142857143em;
    overflow: hidden;
  }
  &__content {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.3s 0.15s, visibility 0.3s 0.15s;
    width: $sidebar-width;
    &_hidden {
      opacity: 0;
      height: 0;
      visibility: hidden;
      transition: opacity 0.2s, visibility 0.2s;
    }
  }
  &__name {
    &_show-popup-mobile {
      display: none;
    }
  }

  &__toggle {
    background: none;
    border: none;
    display: block;
    position: absolute;
    outline: none;
    cursor: pointer;
    padding: 1rem;
    z-index: $layer2-z-index;
    top: 1rem;
    right: $sidebar-padding-hor - 1rem;

    &_to_open {
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      text-align: center;
      padding: 2.5rem 0 0;
    }
  }

  &__toggle-wrapper {
    display: block;
    width: 100%;
    height: 100%;
  }

  &__toggle-arrow-icon {
    margin-right: 0.2rem;
    vertical-align: middle;
    margin-top: -0.2rem;
    width: 0.785714285714286rem;
    height: 0.785714285714286rem;
  }

  &__item {
    position: relative;

    //noinspection CssOptimizeSimilarProperties
    &_bordered {
      border-top-width: 0.1429rem;
      border-top-style: solid;
      padding-top: 1.5rem;
    }
  }

  &__section {
    margin: 2.36rem;
    &_mobile {
      display: none;
    }
  }

  &__button {
    width: 100%;
  }

  &__tooltip {
    margin-bottom: 1rem;
    margin-left: -2.142857142857143rem;
    max-width: 12.142857142857143rem;
  }
}



//case with sidebar opening as popup in mobile size
@include mobile {
  .sidebar {

    &_popup-mobile {
      bottom: 0;
      left: 0;
      min-height: 0;
      opacity: 1;
      overflow: auto;
      padding: $sidebar-padding-vert-xs $sidebar-padding-hor-xs;
      @include top($sidebar-padding-vert-xs, padding-top);
      position: fixed;
      right: 0;
      top: 0;
      transition: opacity 0.3s ease, visibility 0.3s ease;
      visibility: visible;
      width: auto;
      z-index: $popover-z-index;
      outline: none;

      &.sidebar_collapsed {
        opacity: 0;
        padding-left: $sidebar-padding-hor-xs;
        padding-right: $sidebar-padding-hor-xs;
        transition: none;
        visibility: hidden;
        width: auto !important;
      }

      &.sidebar_toggled {
        @include top(1.85rem, padding-top);
      }

      .sidebar__content {
        padding: 2rem $sidebar-padding-hor-xs;
        transition: none;
        width: 100%;
      }

      .sidebar__toggle {
        right: $sidebar-padding-hor-xs - 1rem;
        @include top(1.2rem);

        &_to_open {
          display: none;
        }
      }

      .sidebar__button {
        display: block;
        width: 100%;
      }

      .sidebar__name {
        margin-bottom: 0.357142857142857rem;
        &_show-popup-mobile {
          display: block;
        }
      }
    }

    &__tooltip {
      left: auto;
      right: 0;
    }

    &__section {
      margin: 0;

      + .sidebar__section {
        margin-top: 2.5rem;
      }

      &_mobile {
        display: block;
      }
    }

  }
}

/*
// TODO tablet version (check later)

@include tablet {
  .sidebar {
    //case with sidebar over content in tablet size
    &__content {
      width: auto;
    }

    &_over-tb {
      box-shadow: 0 8px 20px 0 rgba(223, 226, 233, 0.8);
      box-sizing: border-box;
      min-height: 100%;
      padding: 2.5rem 2.85714286rem;
      position: absolute;
      width: 50%;

      &.sidebar_toggled {
        padding-top: 3rem;
      }

      &.sidebar_collapsed {
        padding-left: 0;
        padding-right: 0;
      }

      .sidebar__toggle {
        right: 0.5rem;
        top: 0.5rem;

        &_to_open {
          display: none;
        }
      }
    }
  }
}
*/

@include accessible {
  .sidebar {

    &_popup-mobile {
      width: $accessible-width;
      max-width: 100vw;
      left: calc(50% - #{$accessible-width} / 2);

      &:focus {
        outline: 0.1rem solid currentColor;
      }

      @media (max-width: #{$accessible-width}) {
        left: 0;
      }

      @media (max-width: $bp-max-mobile) {
        left: 0;
        width: 100vw;
      }
    }

    &__toggle {
      &:focus {
        border: 0.1rem solid currentColor;
      }
    }
  }
}
