@mixin profile-awards-theme($theme) {
  $white: map-get($theme, white);

  .profile-awards {
    &__filled-icon-tooltip {
      background-color: $white;

      &:after {
        border-color: $white transparent transparent transparent;
      }
    }
  }
}

@mixin profile-awards-typography($config) {
  $font-size: map-get($config, font-size);

  .profile-awards {

    &__show-all-btn, &__hide-all-btn {
      font-size: map-get($font-size, small);
    }
  }
}

@mixin profile-awards-shadow($shadows) {
  .profile-awards {

    &__filled-icon-tooltip {
      box-shadow: map-get($shadows, award-tooltip);

      &:after {
        filter: drop-shadow(map-get($shadows, drop-shadow));
      }
    }
  }
}
