@import "src/styles/core/utils/breakpoints";

@mixin card-edu-element-theme($theme, $custom) {
  $mono: map-get($theme, mono);
  $accent: map-get($theme, accent);
  $alert: map-get($theme, alert);
  $primary: map-get($theme, primary);
  $success: map-get($theme, success);
  $custom-icons: map-get($custom ,icons);

  $edu-element-status: map-get($custom, edu-element);
  $no-access-status: map-get($edu-element-status, no-access-status);

  .card-edu-element {
    background-color: map-get($theme, white);
    color: map-get($mono, tint85);

    &__recommendation-separator {
      background: linear-gradient(to bottom, map-get($mono, tint60) 20%, map-get($mono, tint20) 80%);

      &_required {
        background: linear-gradient(to bottom, map-get($alert, primary) 20%, map-get($alert, tint20) 80%);
      }

      &_recommended {
        background: linear-gradient(to bottom, map-get($success, primary) 20%, map-get($success, tint20) 80%);
      }

      &_optional {
        background: linear-gradient(to bottom, map-get($primary, primary) 20%, map-get($primary, tint20) 80%);
      }
    }

    &__background {
      color: map-get($custom-icons, edu-bg);
    }

    &__status {
      &:before {
        color: map-get($mono, tint50);
      }
      &_primary {
        &:before {
          color: map-get($primary, primary);
        }
      }
      &_success {
        &:before {
          color: map-get($success, primary);
        }
      }
      &_accent {
        &:before {
          color: map-get($accent, primary);
        }
      }
      &_no-access {
        &:before {
          color: $no-access-status;
        }
      }
    }

    &__iom-link_disabled {
      color: map-get($mono, tint20);
    }
  }

  @include accessible {
    .card-edu-element {
      &__recommendation-separator {
        display: none;
      }
    }
  }
}

@mixin card-edu-element-shadow($shadows) {
  .card-edu-element {
    box-shadow: map-get($shadows, chart-card);
  }
}
