@mixin container-theme($theme) {
  .container {
    background: map-get($theme, white);
    color: map-get($theme, black);
  }
}

@mixin container-shadow($shadows) {
  .container {
    &_raised {
      box-shadow: map-get($shadows, container);
    }
  }
}
