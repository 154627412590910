// use 0px instead 0, calc(0 + constant / env) not working
@mixin top($offset, $style: 'top') {
  #{$style}: $offset;
  // fix for IphoneX
  #{$style}: calc(#{$offset} + constant(safe-area-inset-top));
  #{$style}: calc(#{$offset} + env(safe-area-inset-top));
}

@mixin bottom($offset, $style: 'bottom') {
  #{$style}: $offset;
  // fix for IphoneX
  #{$style}: calc(#{$offset} + constant(safe-area-inset-bottom));
  #{$style}: calc(#{$offset} + env(safe-area-inset-bottom));
}

@mixin offset-top($style) {
  #{$style}: constant(safe-area-inset-top);
  #{$style}: env(safe-area-inset-top);
}
