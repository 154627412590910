@import '../../../styles/core/utils/breakpoints';

@mixin assessment-card-light-theme($theme) {
  $primary: map-get($theme, primary);
  $mono: map-get($theme, mono);
  $white: map-get($theme, white);

  .assessment-card {
    background: $white;

    &__zet-label {
      color: map-get($primary, primary);

      &_disabled {
        color: map-get($mono, tint60);
      }
    }

    &__zet-icon {
      color: map-get($primary, primary);
      &_disabled {
        color: map-get($mono, tint60);
      }
    }

    &__title-icon {
      color: map-get($primary, primary);
      &_disabled {
        color: map-get($mono, tint60);
      }
    }

    &__title-text {
      &_disabled {
        color: map-get($mono, tint60);
      }
    }

    &__try-counter-icon {
      &_mono {
        circle {
          fill: map-get($mono, tint30);
        }
      }
    }

    &__tooltip {
      background-color: $white;

      &_top:after {
        border-color: $white transparent transparent transparent;
      }
    }

    &__watch-result {
      background-color: $white;
    }

    /*@include tablet {
      &__try-counter-text-tablet {
        color: map-get($mono, tint40);
      }

      &__zet-label {
        color: map-get($mono, primary);
      }

      &__zet-icon {
        color: map-get($mono, primary);
      }
    }*/

    @include mobile {
      &__zet-label {
        color: map-get($mono, primary);
        &_disabled {
          color: map-get($mono, tint60);
        }
      }

      &__zet-icon {
        color: map-get($mono, primary);
        &_disabled {
          color: map-get($mono, tint60);
        }
      }
    }
  }
}

@mixin assessment-card-typography($config) {
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .assessment-card {
    &__zet-label {
      font-weight: map-get($font-weight, bold);
    }
  }

 /* @include tablet {
    .assessment-card {
      &__result-label {
        font-size: map-get($font-size, base);
      }
      &__zet-label {
        font-size: map-get($font-size, base);
        font-weight: map-get($font-weight, regular);
      }

      &__link {
        font-size: map-get($font-size, small);
      }

      &__try-counter-text-tablet {
        font-size: map-get($font-size, small);
      }
    }
  }*/

  @include mobile {
    .assessment-card {
      &__zet-label {
        font-weight: map-get($font-weight, medium);
      }
    }
  }
}

@mixin assessment-card-shadow($shadows) {
  .assessment-card {
    box-shadow: map-get($shadows, assessment-card);

    &__tooltip {
      box-shadow: map-get($shadows, popover);

      &_top {
        &:after {
          filter: drop-shadow(map-get($shadows, drop-shadow));
        }
      }
    }

    &__watch-result {
      box-shadow: map-get($shadows, assessment-card);
    }
  }
}
