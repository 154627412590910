@mixin safari {
  @media not all and (min-resolution: .001dpcm) {
    @supports (-webkit-appearance:none) and (stroke-color:transparent) {
      @content;
    }
  }
}

@mixin ie {
  @media all and (-ms-high-contrast:none){
    @content;
  }
}
