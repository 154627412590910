@import './text-field-base';

.text-field {
  outline: none;
  height: 100%;
  width: 100%;
  border-radius: $border-radius;
  letter-spacing: normal;
  text-transform: none;
  text-align: left;
  border-width: $border-width;
  border-style: solid;
  padding: map-get($text-field-padding, vertical) map-get($text-field-padding, horizontal);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &::-ms-clear, &::-ms-reveal {
    display: none;
    width: 0;
    height: 0;
  }

  // fix placeholder in safari
  &::-webkit-input-placeholder {
    line-height: normal;
  }

  &__icon {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0.785rem;
  }

  &__svg {
    svg {
      height: 1rem;
      width: 1rem;
    }
  }

  &__no-border {
    border: none;
    padding-left: 0;
  }

  &_capitalize {
    text-transform: capitalize;
  }

  &_uppercase {
    text-transform: uppercase;

    &::placeholder {
      text-transform: capitalize;
    }
  }

  &__number {
    height: 100%;
    width: auto;
  }

  &__pointer-events-disabled{
    pointer-events: auto;
  }

}
