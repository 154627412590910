@mixin form-control-theme ($theme) {
  $black: map-get($theme, black);
  $primary: map-get($theme, primary);

  .form-control {
    color: $black;
    &__help {
      color: map_get($primary, primary);
    }

    &__required-mark {
      color: map-get($primary, primary);
    }
  }

  .form-control-tooltip {
    background: map-get($theme, white);
  }
}

@mixin form-control-typography ($config) {
  $font-family: map-get($config, font-family);
  $font-size: map-get($config, font-size);
  $font-weight: map-get($config, font-weight);

  .form-control {
    font-family: $font-family;
    font-size: 1rem;
    font-weight: map-get($font-weight, regular);
  }
}

@mixin form-control-shadow($shadows) {
  .form-control-tooltip {
    box-shadow: map-get($shadows, popover);
  }
}
