@import '../../../styles/core/utils/breakpoints';

@mixin tab-group-theme($theme, $custom) {
  $primary: map-get($theme, primary);
  $white: map-get($theme, white);
  $mono: map-get($theme, mono);

  .tab-group {

    &__tab {
      background: none;
    }

    &_theme_search {
      background: $white;

      .tab-group {

        &__header {
          background: map-get($custom, radio);
        }

        &__tab {
          &_active,
          &_active-on-hover {
            background: $white;
            color: map-get($primary, primary);
            box-shadow: 0 0 0.5rem map-get($mono, tint30);

            &:after {
              background: $white;
            }
          }
        }

        &__body {
          background: $white;
        }
      }
    }

    &_theme_search-tags {
      .tab-group {

        &__tab-label {
          color: map-get($mono, tint_50);
        }

        &__tab {
          &_active {
            color: map-get($primary, primary);
            border-bottom-color: map-get($primary, primary);
          }
        }
      }
    }
  }

  @include mobile {
    .tab-group {
      &_theme_search {
        .tab-group__header {
          background: map-get($primary, primary);
        }

        .tab-group__tab {
          background: map-get($primary, primary);
          color: $white;

          &_active {
            background: $white;
            color: map-get($primary, primary);
          }
        }
      }
    }
  }

  @include accessible {
    .tab-group {
      &_theme_search {

        .tab-group__tab{
          background: map-get($theme, white);
          color: map-get($theme, black);

          &_active {
            color: map-get($theme, white);
            background: map-get($theme, black);
          }
        }
      }
    }
  }
}

@mixin tab-group-typography($config) {
  $font-family: map-get($config, font-family);
  $font-weight: map-get($config, font-weight);
  $font-size: map-get($config, font-size);

  .tab-group {
    font-family: $font-family;

    &_theme_search-tags {
      .tab-group {
        &__tab {
          font-family: $font-family;
          font-size: map-get($font-size, small);
        }
      }
    }

    &_theme_search {
      .tab-group {
        &__header {
          font-weight: map-get($font-weight, medium);
        }

        &__tab {
          font-size: map-get($font-size, medium);
        }
      }
    }
  }
}
